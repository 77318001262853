/*------------------------------------------------------------------
Pages v1.1.0
Theme : Corporate
Generated From SCSS
[Table of contents]

1. Layouts
2. View Ports
3. Chat
4. Panels
5. Typography
6. Buttons
7. Alerts
8. Notifications
9. Progress Indicators
10. Modals
11. Tabs & Accordians
12. Sliders
13. Treeview
14. Nestables
15. Form Elements
16. Tables & Datatables
17. Charts
18. Lists
19. Social App
20. Email App
21. Calendar App
22. Login
23. Lockscreen
24. Timeline
25. Gallery
26. Vector Map
27. Widgets
28. Misc
29. Print
30. Demo
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[1. Layouts]
*/
html {
  height: 100%; }

.flex{
    display: flex;
}

.flex-col{
    flex-direction:column;
}

.justify-centered{
    justify-items:center;
}

.align-centered{
    align-items:center;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

/* Body Triggers for layout options 
------------------------------------------------------------------
*/
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eef0f1; }
  body.fixed-header .header {
    position: fixed;
    left: 0;
    top: 0; }
  body.mobile .sidebar-menu {
    overflow: scroll;
    -webkit-overflow-scrolling: touch; }
  body.mobile .sidebar-menu > ul {
    height: auto !important;
    overflow: visible !important;
    -webkit-overflow-scrolling: touch !important; }
  body.mobile .page-sidebar .sidebar-menu .menu-items li:hover a {
    color: white; }
  body.mobile .page-sidebar .sidebar-menu .menu-items li:hover .icon-thumbnail {
    color: white !important; }
  body.mobile .page-sidebar .sidebar-menu .menu-items li.active > a, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > a {
    color: #fff; }
  body.mobile .page-sidebar .sidebar-menu .menu-items li.active > .icon-thumbnail, body.mobile .page-sidebar .sidebar-menu .menu-items li.open > .icon-thumbnail {
    color: #fff; }
  body.mobile .drager {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  body.sidebar-visible .page-sidebar .scroll-element {
    visibility: visible; }
  body.sidebar-visible .page-sidebar .menu-items .icon-thumbnail {
    -webkit-transform: translate3d(-14px, 0, 0);
    transform: translate3d(-14px, 0, 0);
    -ms-transform: translate(-14px, 0); }
  body.sidebar-visible .page-sidebar .sidebar-header .sidebar-header-controls {
    -webkit-transform: translate3d(48px, 0, 0);
    transform: translate3d(48px, 0, 0);
    -ms-transform: translate(48px, 0); }
  body.no-header .page-container .page-content-wrapper .content {
    padding-top: 0px; }
  body.no-header .header {
    border-bottom-color: transparent; }
  body.dashboard {
    background: #eef0f1; }
    body.dashboard .page-container {
      background: #eef0f1; }

/* Page Loader
------------------------------------
*/
.pace .pace-progress {
  background: #88c843;
  height: 0.4em; }

.pace .pace-progress-inner {
  box-shadow: none; }

.pace .pace-activity {
  -webkit-animation: none;
  animation: none;
  top: 73px;

  background-size: 100% auto;
  margin: 0 auto;
  border-width: 0;
  border-radius: 0;
  width: 28px;

  right: 19px; }

/* Header 
------------------------------------
*/
.header {
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  padding: 0 20px 0 0;
  z-index: 800;
  background-color: #fff;
  border-bottom: 1px solid #dee1e3; }
  .header a {
    color: #3b4751; }
    .header a.btn {
      color: #8b91a0; }
    .header a.btn-warning {
      color: #eaeef1; }
  .header .header-inner {
    height: 60px;
    width: 100%;
    vertical-align: middle;
    display: table-cell; }
    .header .header-inner .toggle-sidebar {
      display: none; }
  .header.transparent {
    background-color: transparent !important; }
  .header .brand {
    vertical-align: middle;
    width: 280px;
    text-align: center; }
  .header .bubble {
    border-radius: 100%;
    height: 14px;
    width: 14px;
    background-color: rgba(226, 32, 91, 0.77);
    color: #fff;
    position: relative;
    top: -6px;
    float: right;
    right: -5px; }
  .header .notification-list {
    display: inline-block; }
  .header .search-link {
    display: inline-block;
    margin-left: 15px;
    color: #455A64;
    opacity: .7;
    font-size: 16px;
    font-family: Roboto, wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif; }
    .header .search-link i {
      margin-right: 15px;
      font-size: 16px; }
    .header .search-link:hover {
      opacity: 1; }

/* Bootstrap navbar 
------------------------------------
*/
.navbar {
  top: -1px; }

.navbar-nav > li > a {
  padding-top: 20px;
  padding-bottom: 20px; }

.navbar-default {
  background-color: #fff; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent; }
  .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-default .navbar-nav > .active > a:focus {
    background-color: transparent; }

.navbar-toggle {
  border-radius: 0;
  background-color: transparent !important; }

/* Main Menu Sidebar 
------------------------------------
*/
.page-sidebar {
    width: 280px;
    background-color: #778799;
    bottom: 0;
    z-index: 1000;
    left: -210px;
    position: fixed;
    top: 0;
    right: auto;
    overflow: hidden;
    -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -moz-transition: -moz-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -o-transition: -o-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
}
  .page-sidebar a, .page-sidebar button {
    color: white; }
    .page-sidebar a:hover, .page-sidebar a:active, .page-sidebar button:hover, .page-sidebar button:active {
      color: #fff; }
    .page-sidebar a:visited, .page-sidebar a:focus, .page-sidebar button:visited, .page-sidebar button:focus {
      color: white; }
  .page-sidebar .scroll-element {
    visibility: hidden; }
    .page-sidebar .sidebar-header {
        /* Side-bar header */
        display: block;
        height: 60px;
        line-height: 60px;
        background-color: #272727;
        border-bottom: 1px solid #637180;
        color: #fff;
        width: 100%;
        padding: 0 20px;
        padding-left: 30px;
        clear: both;
        z-index: 10;
        position: relative;
    }
    .page-sidebar .sidebar-header .sidebar-header-controls {
      display: inline-block;
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
      transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
      -webkit-backface-visibility: hidden; }
    .page-sidebar .sidebar-header .sidebar-slide-toggle i {
      -webkit-transition: all 0.12s ease;
      transition: all 0.12s ease; }
    .page-sidebar .sidebar-header .sidebar-slide-toggle.active i {
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg); }
  .page-sidebar .close-sidebar {
    position: absolute;
    right: 19px;
    top: 14px;
    padding: 9px;
    z-index: 1; }
    .page-sidebar .close-sidebar > i {
      color: rgba(255, 255, 255, 0.57); }
  .page-sidebar .sidebar-overlay-slide {
    /* Side-bar Top Slider */
    width: 100%;
    height: 100%;
    background-color: #205aa2;
    display: block;
    z-index: 9;
    padding: 80px 20px 20px 20px; }
    .page-sidebar .sidebar-overlay-slide.from-top {
      top: -100%;
      position: absolute;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease; }
      .page-sidebar .sidebar-overlay-slide.from-top.show {
        -webkit-transform: translate(0, 100%);
        -ms-transform: translate(0, 100%);
        transform: translate(0, 100%); }
  .page-sidebar .sidebar-menu {
    /* Side-bar Menu */
    height: calc(100% - 50px);
    position: relative;
    width: 100%; }
    .page-sidebar .sidebar-menu .outer-tab-nav-section {
      display: inline-block;
      width: 45px;
      position: absolute;
      height: 100%;
      background-color: #0aa699 !important; }
    .page-sidebar .sidebar-menu .menu-items {
      /* Side-bar Menut Items */
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 10px);
      width: 100%; }
      .page-sidebar .sidebar-menu .menu-items li:hover > .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.open > .icon-thumbnail, .page-sidebar .sidebar-menu .menu-items li.active > .icon-thumbnail {
        color: #fff; }
      .page-sidebar .sidebar-menu .menu-items li:hover > a, .page-sidebar .sidebar-menu .menu-items li.open > a, .page-sidebar .sidebar-menu .menu-items li.active > a {
        color: #fff; }
      .page-sidebar .sidebar-menu .menu-items li > a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 65%; }
      .page-sidebar .sidebar-menu .menu-items > li {
        display: block;
        padding: 0;
        clear: right; }
        .page-sidebar .sidebar-menu .menu-items > li:after, .page-sidebar .sidebar-menu .menu-items > li:before {
          display: table;
          content: " ";
          clear: both; }
        .page-sidebar .sidebar-menu .menu-items > li > a {
          font-family: Arial, sans-serif;
          display: inline-block;
          padding: 0 3px;
          padding-left: 32px;
          min-height: 40px;
          line-height: 40px;
          font-size: 14px;
          clear: both; }
          .page-sidebar .sidebar-menu .menu-items > li > a.open {
            background: #313131; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .arrow {
            float: right; }
            .page-sidebar .sidebar-menu .menu-items > li > a > .arrow:before {
              float: right;
              display: inline;
              font-size: 16px;
              font-family: FontAwesome;
              height: auto;
              content: "\f104";
              font-weight: 300;
              text-shadow: none;
              -webkit-transition: all 0.12s ease;
              transition: all 0.12s ease; }
            .page-sidebar .sidebar-menu .menu-items > li > a > .arrow.open:before {
              -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
              transform: rotate(-90deg); }
          .page-sidebar .sidebar-menu .menu-items > li > a > .badge {
            margin-top: 12px; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .title {
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 65%; }
          .page-sidebar .sidebar-menu .menu-items > li > a > .details {
            font-size: 12px;
            opacity: 0.4;
            display: block;
            clear: both; }
          .page-sidebar .sidebar-menu .menu-items > li > a.detailed > .title {
            line-height: 28px; }
          .page-sidebar .sidebar-menu .menu-items > li > a.detailed > .details {
            line-height: 16px; }
        .page-sidebar .sidebar-menu .menu-items > li.active > ul.sub-menu {
          display: block; }
        .page-sidebar .sidebar-menu .menu-items > li > .icon-thumbnail:first-letter {
          text-transform: uppercase; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu li {
          padding-left: 10px;
          padding-right: 3px; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu > li ul.sub-menu .icon-thumbnail {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 10px; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow:before {
          float: right;
          margin-top: 1px;
          margin-right: 20px;
          display: inline;
          font-size: 16px;
          font-family: FontAwesome;
          height: auto;
          content: "\f104";
          font-weight: 300;
          text-shadow: none;
          -webkit-transition: all 0.12s ease;
          transition: all 0.12s ease; }
        .page-sidebar .sidebar-menu .menu-items > li > ul.sub-menu li > a > .arrow.open:before {
          float: right;
          margin-top: 1px;
          margin-right: 18px;
          display: inline;
          font-family: FontAwesome;
          height: auto;
          font-size: 16px;
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          font-weight: 300;
          text-shadow: none; }
        .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu {
            display: none;
            list-style: none;
            clear: both;
            margin: 0 0 10px 0;
            background-color: #637180;
            padding: 18px 0 10px 0;
        }
          .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
            background: none;
            padding: 0px 20px 0 40px;
            margin-top: 1px; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li:hover > .icon-thumbnail {
              color: #fff; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li > a {
              display: inline-block;
              padding: 5px 0px;
              font-size: 13px;
              font-family: Arial, sans-serif;
              white-space: normal; }
            .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li .icon-thumbnail {
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin: 0;
                background-color: #778799;
                font-size: 14px;
            }
    .page-sidebar .sidebar-menu .muted {
      color: #576775;
      opacity: .45; }
  .page-sidebar .icon-thumbnail [class^="bg-"], .page-sidebar [class*="bg-"] {
    color: #fff; }

[data-toggle-pin="sidebar"] > i:before {
  content: "\f10c"; }

/* Sidebar icon holder 
------------------------------------
*/
.icon-thumbnail {
    display: inline-block;
    background: #637180;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    left: 0;
    float: right;
    margin-right: 14px;
    color: white;
    font-size: 16px;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    font-family: "Segoe UI","Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-weight: bold;
}
  .icon-thumbnail > i {
    font-size: 14px; }

.nav-collapse.collapse {
  height: 100%  !important; }

/* Secondary App Menu */
.toggle-secondary-sidebar {
  display: none; }

.secondary-sidebar {
  background: #cccccc;
  width: 250px;
  float: left;
  padding-left: 47px;
  height: 100%;
  position: fixed; }
  .secondary-sidebar.not-fixed {
    position: inherit; }
  .secondary-sidebar .btn-compose {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.02em;
    text-transform: uppercase; }
  .secondary-sidebar .menu-title {
    color: rgba(255, 255, 255, 0.5);
    opacity: .5;
    font-size: 10.8px;
    font-family: 'Montserrat';
    font-weight: normal;
    letter-spacing: 0.03em; }
  .secondary-sidebar .main-menu {
    padding-left: 0; }
    .secondary-sidebar .main-menu > li {
      list-style: none; }
      .secondary-sidebar .main-menu > li.active > a {
        color: #4b7ec1; }
        .secondary-sidebar .main-menu > li.active > a:hover {
          color: #4b7ec1; }
        .secondary-sidebar .main-menu > li.active > a > .title {
          position: relative; }
          .secondary-sidebar .main-menu > li.active > a > .title:after {
            background: #4b7ec1;
            border-radius: 50%;
            content: "";
            height: 7px;
            position: absolute;
            right: -14px;
            top: 6.5px;
            width: 7px; }
      .secondary-sidebar .main-menu > li a {
        font-size: 14px;
        color: white;
        line-height: 37px; }
        .secondary-sidebar .main-menu > li a:hover {
          color: #fff; }
        .secondary-sidebar .main-menu > li a > .title i {
          margin-right: 6px;
          opacity: .9; }
  .secondary-sidebar .sub-menu {
    margin-left: 23px; }
    .secondary-sidebar .sub-menu li {
      list-style: none;
      padding: 0; }
      .secondary-sidebar .sub-menu li.active a {
        color: #fff !important; }
      .secondary-sidebar .sub-menu li a {
        color: rgba(255, 255, 255, 0.5);
        line-height: 25px; }
        .secondary-sidebar .sub-menu li a:hover {
          color: #fff; }
  .secondary-sidebar .sub-menu li a .badge, .secondary-sidebar .main-menu li a .badge {
    background: transparent;
    font-size: 13px;
    color: white;
    line-height: 25px; }

.split-view {
  position: relative;
  height: 100%; }
  .split-view .split-list {
    float: left;
    width: 360px;
    background: #fff;
    height: 100%;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    border-right: 1px solid #dee1e3;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
    .split-view .split-list.slideLeft {
      -webkit-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
      transform: translate(-100%, 0); }
    .split-view .split-list .list-refresh {
      position: absolute;
      right: 18px;
      top: 5px;
      color: #455A64;
      opacity: .34;
      z-index: 101; }
    .split-view .split-list .list-view-fake-header, .split-view .split-list .list-view-group-header {
      background: #e5e8e9;
      height: 30px;
      color: rgba(69, 90, 100, 0.7);
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 10.8px;
      padding-left: 13px;
      padding-top: 6px;
      letter-spacing: 0.04em;
      width: 100%; }
    .split-view .split-list .item {
      height: 117px;
      list-style: none;
      position: relative;
      border-bottom: 1px solid rgba(222, 225, 227, 0.7);
      cursor: pointer; }
      .split-view .split-list .item .inline {
        width: 230px; }
        .split-view .split-list .item .inline > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: arial; }
      .split-view .split-list .item .recipients {
        letter-spacing: 0.01em; }
      .split-view .split-list .item .checkbox {
        float: left;
        clear: left;
        display: none; }
      .split-view .split-list .item .subject {
        font-family: 'Helvetica';
        font-size: 14.33px;
        color: #616161;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 36px;
        display: -webkit-box;
        white-space: normal;
        line-height: 18px; }
      .split-view .split-list .item .body {
        font-size: 12.6px;
        opacity: .52;
        height: 22px; }
      .split-view .split-list .item .datetime {
        color: #0e1214;
        font-family: arial;
        font-size: 11.1px;
        position: absolute;
        right: 20px;
        top: 15px; }
  .split-view .split-details {
    position: relative;
    overflow: auto;
    height: 100%; }
    .split-view .split-details .no-result {
      bottom: 0;
      left: 0;
      margin-top: -34px;
      opacity: 0.5;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%; }
    .split-view .split-details .actions {
      height: 50px;
      float: left; }
      .split-view .split-details .actions li {
        list-style: none;
        position: relative; }
        .split-view .split-details .actions li:last-child:after {
          display: none; }
        .split-view .split-details .actions li:after {
          content: "";
          height: 14px;
          position: absolute;
          right: -4px;
          top: 18px;
          width: 1px;
          background: rgba(0, 0, 0, 0.07); }
        .split-view .split-details .actions li a {
          font-size: 13.1px;
          color: #455A64;
          font-weight: 600;
          padding: 0 13px;
          line-height: 50px;
          white-space: nowrap; }

.inner-content {
  margin-top: 0px;
  padding: 0px;
  overflow: auto;
  min-height: 100%;
  margin-left: 250px; }

/* Quick View 
------------------------------------
*/
.quickview-wrapper {
  position: fixed;
  right: -285px;
  top: 0;
  width: 285px;
  background: #fff;
  bottom: 0;
  z-index: 1000;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  -webkit-transition: -webkit-transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-perspective: 1000; }
  .quickview-wrapper.open {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0); }
  .quickview-wrapper .quickview-toggle {
    position: absolute;
    right: 12px;
    top: 2px;
    color: white;
    padding: 6px;
    opacity: 0.4; }
    .quickview-wrapper .quickview-toggle:hover {
      opacity: 1; }
  .quickview-wrapper .nav-tabs {
    /* Quickview Tabs */
    background-color: #2464b4;
    position: relative;
    padding: 0 43px; }
    .quickview-wrapper .nav-tabs > li > a {
      padding: 11px;
      color: white;
      opacity: 0.6;
      border: 0;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
      min-width: 62px; }
      .quickview-wrapper .nav-tabs > li > a:hover {
        color: white;
        opacity: 1; }
    .quickview-wrapper .nav-tabs > li.active > a, .quickview-wrapper .nav-tabs > li.active > a:hover, .quickview-wrapper .nav-tabs > li.active > a:focus {
      background-color: transparent;
      border: 0;
      color: white;
      opacity: 1; }
    .quickview-wrapper .nav-tabs ~ .tab-content {
      position: absolute;
      padding: 0;
      left: 0;
      right: 0;
      top: 38px;
      bottom: 0;
      height: auto; }
      .quickview-wrapper .nav-tabs ~ .tab-content > div {
        height: 100%; }
      .quickview-wrapper .nav-tabs ~ .tab-content ul {
        margin: 0;
        padding: 0; }
        .quickview-wrapper .nav-tabs ~ .tab-content ul li {
          list-style: none; }
      .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes {
        /* Quickview Notes */
        background: #fbf9e3; }
        .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes a, .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes button {
          color: #968974; }
        .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list {
          position: relative; }
          .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar {
            height: 50px;
            padding: 0 25px; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul {
              margin-top: 10px; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li {
                display: inline-block;
                height: auto; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a {
                  height: 22px;
                  line-height: 22px;
                  display: block;
                  padding: 0 5px; }
                  .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a:hover, .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar ul > li a.selected {
                    background: #968974;
                    color: #FBF9E3; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list .toolbar .btn-remove-notes {
              background: transparent;
              bottom: 8px;
              display: block;
              left: 50%;
              margin-left: -40%;
              position: absolute;
              width: 83%;
              border: 1px solid #968974; }
          .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul {
            padding: 0; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li {
              cursor: pointer;
              height: 42px;
              padding: 0 25px;
              display: block;
              clear: both; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left {
                float: left;
                width: 65%;
                height: 100%;
                padding-top: 9px; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .checkbox {
                  display: none;
                  float: left; }
                  .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left .checkbox label {
                    margin-right: 0;
                    vertical-align: text-top; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .left p {
                  margin: 0;
                  font-size: 13px;
                  font-weight: bold;
                  width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  display: inline-block; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right {
                font-size: 10.5px;
                text-align: right;
                width: 35%;
                line-height: 41px; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li .right .date {
                  margin-right: 10px; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .list > ul li:hover {
                background: #f4ecd1; }
        .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note {
          /* Quickview Note */
          background-image: url("../img/notes_lines.png");
          background-repeat: repeat-y;
          background-position: 27px top; }
          .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note > div {
            display: table;
            height: 100%;
            width: 100%; }
          .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar {
            background: #fcfcfa;
            height: 55px;
            width: 100%;
            display: table-row;
            box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.33); }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li {
              display: inline-block;
              border-right: 1px solid #EDECEC;
              float: left;
              line-height: 55px;
              padding: 0;
              text-align: center;
              width: 55px;
              height: auto; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a {
                color: #a5a5a5;
                display: block; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a:hover {
                  background: #fffaf6; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar > li > a.active {
                  color: #333;
                  background: #f9f1ea; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .toolbar:after {
              position: absolute;
              content: "";
              width: 100%; }
          .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body {
            display: table-row;
            height: 100%; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body > div {
              display: table;
              height: 100%;
              width: 100%;
              padding: 0 20px 0 45px;
              white-space: normal; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top {
              height: 50px;
              display: table-row;
              clear: both;
              line-height: 50px;
              text-align: center; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > a {
                float: left;
                color: #b0b0a8;
                margin-left: 10px; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .top > span {
                font-style: italic;
                color: #b0b0a8;
                font-size: 11px; }
            .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content {
              display: table-row;
              height: 100%; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content form {
                height: 100%; }
              .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor {
                font-size: 12px; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor:focus {
                  outline: none; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::-moz-selection {
                  background: #fef8ae; }
                .quickview-wrapper .nav-tabs ~ .tab-content .quickview-notes .note .body .content .quick-note-editor::selection {
                  background: #fef8ae; }

/* Page Container 
------------------------------------
*/
.page-container {
  width: 100%;
  height: 100%;
  padding-left: 70px; }
  .page-container .page-content-wrapper {
    min-height: 100%;
    position: relative; }
    .page-container .page-content-wrapper .content {
      /* Content holder */
      z-index: 10;
      padding-top: 60px;
      padding-bottom: 72px;
      min-height: 100%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
      .page-container .page-content-wrapper .content.full-width {
        width: 100%; }
      .page-container .page-content-wrapper .content .content-inner {
        display: inline-block;
        vertical-align: top;
        height: 100%;
        padding-left: 30px;
        position: relative; }
      .page-container .page-content-wrapper .content:only-child {
        padding-bottom: 0px; }
      .page-container .page-content-wrapper .content.overlay-footer {
        padding-bottom: 0px; }
    .page-container .page-content-wrapper .footer {
      /* Footer */
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: left 0.3s ease;
      transition: left 0.3s ease; }
      .page-container .page-content-wrapper .footer.fixed {
        position: fixed; }

.page-title {
  margin-top: 0px; }

/* Breadcrumbs
------------------------------------
*/
.breadcrumb {
  font-family: 'Montserrat';
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 0;
  margin: 0;
  border: none;
  text-transform: uppercase;
  display: block; }
  .breadcrumb a {
    margin-left: 5px;
    margin-right: 5px;
    font-family: 'Montserrat';
    font-size: 11px !important;
    font-weight: 400;
    color: #7b7d82; }
    .breadcrumb a.active {
        font-weight: 600;
        color: #778799;
    }
  .breadcrumb li {
    padding-left: 0px; }
  .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #515050;
    font-family: FontAwesome;
    content: "\f105";
    font-weight: bold; }

.breadcrumb a,
.breadcrumb i,
.breadcrumb span, .breadcrumb li {
  color: #7b7d82;
  font-weight: 300;
  text-shadow: none; }

/* Overlay Search 
------------------------------------
*/
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1010;
  padding-left: 64px;
  overflow: auto; }
  .overlay .inline-block {
    display: inline-block; }
  .overlay .overlay-brand {
    margin-left: 7px; }
  .overlay > div {
    width: 100%;
    height: 260px;
    display: block;
    overflow: hidden; }
  .overlay .has-results {
    overflow: visible; }
  .overlay .overlay-search {
    font-weight: 700;
    font-size: 77px;
    height: 100px;
    letter-spacing: -1.925px;
    line-height: 100px;
    width: 100%;
    padding-left: 0 !important; }
  .overlay .overlay-close {
    position: absolute;
    right: 20px;
    top: 25px; }

.group-container {
  white-space: nowrap !important; }
  .group-container .single-group {
    vertical-align: top;
    display: inline-block;
    white-space: normal; }

.jumbotron {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0;
  background-color: #e5e8e9; }
  .jumbotron .inner {
    position: relative;
    z-index: 2; }
  .jumbotron.lg {
    height: 600px; }
  .jumbotron .cover-photo {
    width: 100%;
    height: inherit;
    overflow: hidden;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    background-size: cover;
    background-color: #88c843; }
    .jumbotron .cover-photo img {
      display: none; }
  .jumbotron h1 {
    font-size: 44px; }

/* Pages Image Icon Sent - Pixel Perfect 
------------------------------------
*/
.icon-set {
  background: url("../img/icons/top_tray.png") no-repeat;
  position: relative;
  display: block; }
  .icon-set.globe-fill {
    background-position: -1px -1px;
    width: 17px;
    height: 17px;
    top: 2px; }
  .icon-set.clip {
    background-position: -20px -2px;
    width: 16px;
    height: 16px; }
  .icon-set.grid-box {
    background-position: -41px -2px;
    width: 14px;
    height: 14px; }
  .icon-set.menu-hambuger {
    background-position: -58px -3px;
    width: 15px;
    height: 13px; }
  .icon-set.menu-hambuger-plus {
    background-position: -77px -1px;
    width: 18px;
    height: 15px; }

.dropzone {
  overflow: hidden; }
  .dropzone .dz-default.dz-message {
    width: 100%; }

.scroll {
  position: relative;
  overflow: auto; }

/* Pages Scroll bar
------------------------------------
*/
.scroll-wrapper > .scroll-element, .scroll-wrapper > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scroll-wrapper > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scroll-wrapper > .scroll-element.scroll-x {
  bottom: 2px;
  height: 7px;
  left: 0;
  min-width: 100%;
  width: 100%; }

.scroll-wrapper > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 2px;
  top: 0;
  width: 4px; }

.scroll-wrapper > .scroll-element .scroll-element_outer {
  opacity: 0.3; }

.scroll-wrapper > .scroll-element .scroll-element_size {
  background-color: rgba(0, 0, 0, 0.07);
  opacity: 0; }

.scroll-wrapper > .scroll-element .scroll-bar {
  background-color: #697686; }

.scroll-wrapper > .scroll-element.scroll-x .scroll-bar {
  bottom: 0;
  height: 4px;
  min-width: 24px;
  top: auto; }

.scroll-wrapper > .scroll-element.scroll-x .scroll-element_outer {
  bottom: 0;
  top: auto;
  left: 2px;
  -webkit-transition: height 0.2s;
  transition: height 0.2s; }

.scroll-wrapper > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scroll-wrapper > .scroll-element.scroll-y .scroll-bar {
  left: auto;
  min-height: 24px;
  right: 0;
  width: 4px; }

.scroll-wrapper > .scroll-element.scroll-y .scroll-element_outer {
  left: auto;
  right: 0;
  top: 2px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.scroll-wrapper > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

.scroll-wrapper.auto-hide > .scroll-element .scroll-element_track {
  display: none; }

.scroll-wrapper > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px; }

.scroll-wrapper > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px; }

/* hover & drag */
.scroll-wrapper > .scroll-element:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer {
  overflow: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7; }

.scroll-wrapper > .scroll-element:hover .scroll-element_outer .scroll-element_size,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer .scroll-element_size {
  opacity: 1; }

.scroll-wrapper > .scroll-element:hover .scroll-element_outer .scroll-bar,
.scroll-wrapper > .scroll-element.scroll-draggable .scroll-element_outer .scroll-bar {
  height: 100%;
  width: 100%; }

.scroll-wrapper > .scroll-element.scroll-x:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-x.scroll-draggable .scroll-element_outer {
  height: 10px;
  min-height: 7px; }

.scroll-wrapper > .scroll-element.scroll-y:hover .scroll-element_outer,
.scroll-wrapper > .scroll-element.scroll-y.scroll-draggable .scroll-element_outer {
  min-width: 7px;
  width: 7px; }

/* Other overides */
.container-fluid {
  padding-left: 30px;
  padding-right: 30px; }

.copyright {
  padding: 25px 0;
  border-top: 1px solid #dee1e3; }

.navbar-center, .navbar-center > li {
  float: none;
  display: inline-block;
  display: inline;
  /* ie7 fix */
  zoom: 1;
  /* hasLayout ie7 trigger */
  vertical-align: top; }

.navbar-nav li a {
  min-width: 50px; }

.pager {
  margin: 0; }

/* Horizontal Menu */
.horizontal-menu [data-pages="sidebar"],
.horizontal-menu [data-toggle="sidebar"] {
  display: none; }

.horizontal-menu #horizontal-menu-toggle {
  display: block !important; }

.horizontal-menu .page-container {
  padding-left: 0; }

.horizontal-menu .header .brand {
  padding-left: 35px;
  text-align: left; }

.horizontal-menu .header-seperation {
  display: none; }

.horizontal-menu .bar {
  width: 100%;
  background-color: #fff;
  position: fixed;
  display: table;
  z-index: 50; }

.horizontal-menu .bar-inner {
  display: table-cell;
  width: 100%; }
  .horizontal-menu .bar-inner > ul {
    margin: 0;
    padding: 0;
    padding-left: 16px; }
    .horizontal-menu .bar-inner > ul > li {
      font-family: "Segoe UI","Helvetica Neue", Helvetica, Arial, sans-serif;
      display: inline-block;
      padding: 10px 15px;
      vertical-align: middle; }
      .horizontal-menu .bar-inner > ul > li.classic {
        position: relative; }
      .horizontal-menu .bar-inner > ul > li.open > a {
        opacity: 1; }
      .horizontal-menu .bar-inner > ul > li.mega.open, .horizontal-menu .bar-inner > ul > li.horizontal.open {
        position: inherit; }
      .horizontal-menu .bar-inner > ul > li > a {
        color: #000; }
        .horizontal-menu .bar-inner > ul > li > a > .arrow {
          display: inline-block; }
          .horizontal-menu .bar-inner > ul > li > a > .arrow:before {
            display: inline;
            font-size: 16px;
            font-family: FontAwesome;
            height: auto;
            content: "\f107";
            font-weight: 300;
            text-shadow: none;
            margin-left: 8px;
            opacity: 0.5;
            position: relative;
            vertical-align: middle; }
      .horizontal-menu .bar-inner > ul > li a {
        opacity: 0.7; }
        .horizontal-menu .bar-inner > ul > li a .description {
          opacity: 0.7;
          transition: opacity 0.1s linear 0s; }
        .horizontal-menu .bar-inner > ul > li a:hover {
          opacity: 1; }
          .horizontal-menu .bar-inner > ul > li a:hover .description {
            opacity: 1; }
      .horizontal-menu .bar-inner > ul > li.open {
        background: #fff; }
        .horizontal-menu .bar-inner > ul > li.open > .classic {
          max-height: 999px; }
          .horizontal-menu .bar-inner > ul > li.open > .classic > li {
            opacity: 1; }
        .horizontal-menu .bar-inner > ul > li.open > .mega, .horizontal-menu .bar-inner > ul > li.open > .horizontal {
          display: block; }
      .horizontal-menu .bar-inner > ul > li > .classic {
        margin: 0;
        padding: 0;
        position: absolute;
        background-color: #fff;
        list-style: none;
        left: 0;
        right: 0;
        top: 40px;
        min-width: 220px;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: all 0.3s linear 0s;
        transition: all 0.3s linear 0s; }
        .horizontal-menu .bar-inner > ul > li > .classic > li {
          margin: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e5e9ec;
          color: #22262e;
          opacity: 0;
          -webkit-transition: all 0.1s linear 0.1s;
          transition: all 0.1s linear 0.1s; }
          .horizontal-menu .bar-inner > ul > li > .classic > li a {
            color: #000; }
            .horizontal-menu .bar-inner > ul > li > .classic > li a .description {
              display: block;
              font-size: 12px;
              color: #232d32; }
          .horizontal-menu .bar-inner > ul > li > .classic > li:last-child {
            border-bottom: 0; }
      .horizontal-menu .bar-inner > ul > li > .horizontal {
        margin: 0;
        top: 40px;
        padding: 0;
        position: absolute;
        background-color: #fff;
        list-style: none;
        display: none;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 1px solid #e5e9ec; }
        .horizontal-menu .bar-inner > ul > li > .horizontal li {
          margin: 15px;
          color: #000;
          display: inline-block; }
          .horizontal-menu .bar-inner > ul > li > .horizontal li a {
            color: #000; }
            .horizontal-menu .bar-inner > ul > li > .horizontal li a .description {
              display: block;
              font-size: 12px;
              color: #232d32; }
      .horizontal-menu .bar-inner > ul > li > .mega {
        margin: 0;
        top: 40px;
        padding: 0;
        position: absolute;
        background-color: #fff;
        list-style: none;
        display: none;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e9ec; }
        .horizontal-menu .bar-inner > ul > li > .mega > li {
          margin: 15px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e5e9ec;
          color: #000; }
          .horizontal-menu .bar-inner > ul > li > .mega > li a {
            color: #000; }
        .horizontal-menu .bar-inner > ul > li > .mega .sub-menu-heading {
          font-size: 14px;
          color: #000;
          margin-bottom: 10px;
          margin-top: 20px; }
        .horizontal-menu .bar-inner > ul > li > .mega .sub-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-bottom: 10px; }
          .horizontal-menu .bar-inner > ul > li > .mega .sub-menu > li {
            padding-left: 0;
            padding-bottom: 5px; }
            .horizontal-menu .bar-inner > ul > li > .mega .sub-menu > li > a {
              color: #000; }

@media (min-width: 980px) {
  .horizontal-menu .page-content {
    margin-left: 0; }
    .horizontal-menu .page-content .content {
      padding-top: 123px; } }

/*------------------------------------------------------------------
[2. View Ports]
*/
.view-port {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden; }
  .view-port .navbar {
    border-radius: 0;
    padding-left: 0;
    margin-bottom: 0;
    border-left: 0;
    display: table;
    width: 100%;
    top: 0;
    border-top: 0; }
    .view-port .navbar .navbar-inner {
      display: table-cell;
      height: 50px;
      vertical-align: middle; }
    .view-port .navbar .action {
      position: absolute;
      top: 0;
      line-height: 50px;
      z-index: 1; }
      .view-port .navbar .action.pull-right {
        right: 0; }
    .view-port .navbar .view-heading {
      font-size: 15px;
      text-align: center; }
    .view-port .navbar > p {
      line-height: 12px;
      font-size: 12px;
      margin: 0; }
    .view-port .navbar.navbar-sm {
      min-height: 35px; }
      .view-port .navbar.navbar-sm .navbar-inner {
        height: 35px; }
      .view-port .navbar.navbar-sm .action {
        line-height: 35px; }
  .view-port .view {
    display: inline-block;
    width: 100%;
    height: 100%;
    vertical-align: top;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .view-port .view:first-child:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      -webkit-transition: opacity 0.2s linear;
      transition: opacity 0.2s linear;
      z-index: -1; }
    .view-port .view:last-child {
      margin-left: -4px; }
    .view-port .view:only-child {
      margin: 0; }
  .view-port.from-top > .view:last-child {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0); }
  .view-port.push > .view:first-child {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0); }
  .view-port.push > .view:last-child {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0); }
  .view-port.push-parrallax > .view:first-child {
    -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
    transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
    -ms-transform: translate(-25%, 0); }
    .view-port.push-parrallax > .view:first-child:before {
      opacity: 0;
      z-index: 100; }
  .view-port.push-parrallax > .view:last-child {
    -webkit-transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
    transition: all 400ms cubic-bezier(0.1, 0.7, 0.1, 1);
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0);
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.36); }

/*------------------------------------------------------------------
[3. Chat]
*/
/* Chat Components
--------------------------------------------------
*/
.chat-view .chat-inner {
  padding: 15px;
  overflow-x: hidden;
  height: calc(100% - 103px); }

.chat-view .message {
  margin-bottom: 10px;
  line-height: 30px; }

.chat-view .profile-img-wrapper {
  height: 28px;
  width: 28px; }

.chat-view .chat-bubble {
  position: relative;
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0px 0 5px;
  max-width: 60%;
  white-space: normal; }
  .chat-view .chat-bubble > p {
    margin: 0; }
  .chat-view .chat-bubble.from-me {
    background: #dbe5f3;
    color: #232d32;
    opacity: 0.8;
    float: right;
    border: 1px solid rgba(0, 0, 0, 0.07); }
  .chat-view .chat-bubble.from-them {
    color: #232d32;
    opacity: 0.8;
    background: #e5e8e9;
    position: relative;
    opacity: 0.8;
    float: left;
    border: 1px solid #f0f2f5; }

.chat-view .chat-input {
  border: 0;
  height: 45px; }
  .chat-view .chat-input:focus, .chat-view .chat-input:active {
    background-color: #fff; }

.chat-view .user-controls {
  padding-top: 12px; }

/* Chat User List
--------------------------------------------------
*/
.chat-user-list > a {
  height: 60px; }

/* Alert List
--------------------------------------------------
*/
.alert-list > a {
  height: 45px; }

/*------------------------------------------------------------------
[4. Panels]
*/
.panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative; }
  .panel .panel-heading {
    background: transparent;
    border-radius: 0px;
    border-bottom: 0px;
    padding: 20px 20px 7px 20px;
    position: relative;
    z-index: 3;
    min-height: 48px; }
    .panel .panel-heading.separator:after {
      content: "";
      height: 1px;
      background: rgba(0, 0, 0, 0.08);
      left: 16px;
      right: 16px;
      position: absolute;
      bottom: 0; }
    .panel .panel-heading + .panel-body {
      padding-top: 0;
      height: calc(100% - 50px); }
    .panel .panel-heading .panel-title {
      font-family: 'Montserrat';
      text-transform: uppercase;
      display: inline-block;
      letter-spacing: 0.02em;
      font-size: 12px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-text-stroke: 0px;
      filter: alpha(opacity=40);
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .panel .panel-heading .panel-controls {
      float: right;
      margin-top: -3px; }
      .panel .panel-heading .panel-controls ul {
        margin: 0;
        padding: 0; }
        .panel .panel-heading .panel-controls ul li {
          display: inline-block;
          list-style: none;
          line-height: 0; }
      .panel .panel-heading .panel-controls:after {
        content: "";
        display: table;
        clear: both; }
    .panel .panel-heading.panel-heading-hover .panel-title {
      opacity: .4; }
    .panel .panel-heading.panel-heading-hover:hover .panel-title {
      opacity: 1; }
  .panel.panel-default {
    border: 1px solid rgba(230, 230, 230, 0.7); }
  .panel.panel-bordered {
    border: 1px solid rgba(222, 225, 227, 0.7); }
  .panel.panel-condensed .panel-heading {
    padding: 13px 13px 0 13px;
    min-height: 30px; }
    .panel.panel-condensed .panel-heading .panel-title {
      opacity: .4; }
  .panel.panel-condensed .panel-body {
    padding: 13px; }
  .panel.panel-hover .panel-heading .panel-title {
    opacity: .4; }
  .panel.panel-hover:hover .panel-heading .panel-title {
    opacity: 1; }
  .panel.panel-transparent {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .panel.panel-transparent .panel-body {
      background: transparent; }
  .panel.full-height {
    height: 100%; }
    .panel.full-height .panel-body {
      height: auto;
      width: 100%;
      height: 100%; }
  .panel.panel-featured {
    -webkit-box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
    box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
    width: calc(100% - 50px);
    float: right; }
    .panel.panel-featured .panel-title h4 {
      font-family: 'Montserrat';
      font-size: 16px;
      text-transform: uppercase;
      color: #e5e8e9; }
    .panel.panel-featured .panel-body h3 {
      line-height: 34px;
      font-size: 26px; }
    .panel.panel-featured .footer .username {
      line-height: 8px;
      padding-top: 10px;
      font-size: 16px; }
    .panel.panel-featured .footer .buttons li {
      display: inline;
      list-style: none;
      font-weight: bold;
      margin-left: 20px; }
      .panel.panel-featured .footer .buttons li:first-child {
        margin-left: 0; }
    .panel.panel-featured .footer .buttons .heart {
      color: #f44336; }
    .panel.panel-featured .footer .buttons .comment {
      color: #455A64; }
    .panel.panel-featured .ribbon {
      width: 38px;
      height: 38px;
      margin-left: -39px;
      float: left;
      -webkit-box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, 0.14);
      box-shadow: inset -3px 0px 3px 0px rgba(0, 0, 0, 0.14); }
      .panel.panel-featured .ribbon.green {
        background: #4b7ec1; }
      .panel.panel-featured .ribbon.blue {
        background: #88c843; }
  .panel.hover-fill:hover {
    background: #e5e8e9; }
  .panel.hover-stroke:hover {
    border: 1px solid #dee1e3; }
  .panel .panel-body {
    padding: 20px;
    height: 100%;
    width: 100%; }
    .panel .panel-body.no-padding .row {
      margin-left: 0;
      margin-right: 0; }
    .panel .panel-body.no-bottom-padding {
      padding-bottom: 0; }
    .panel .panel-body.no-top-padding {
      padding-top: 0; }
    .panel .panel-body .title {
      margin-top: 0px; }
    .panel .panel-body.scrollable {
      margin-bottom: 20px; }
  .panel .panel-footer {
    background: transparent;
    border-top: 0px; }

/* Portlets
------------------------------------
*/
.portlet-progress {
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  left: 0;
  position: absolute !important;
  right: 0;
  top: 0;
  display: none;
  z-index: 2; }

.portlet-progress > .progress,
.portlet-progress > .progress.progress-small {
  height: 3px; }

.portlet-progress > .progress-circle-indeterminate,
.portlet-progress > .portlet-bar-indeterminate {
  display: block;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
  position: absolute;
  top: 50%; }

.portlet-progress > .progress-circle-indeterminate {
  width: 35px;
  height: 35px; }

.panel-maximized {
  position: fixed !important;
  left: 70px;
  top: 59px;
  bottom: 0;
  right: 0;
  z-index: 600;
  margin: 0; }

/* Pages notification holder */
.panel .pgn-wrapper {
  position: absolute;
  z-index: 602; }

.panel-heading a:not(.btn) {
  color: #455A64 !important;
  opacity: .4; }
  .panel-heading a:not(.btn).portlet-refresh {
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease; }
    .panel-heading a:not(.btn).portlet-refresh.refreshing {
      opacity: 1; }

.panel-heading a[data-toggle]:hover {
  opacity: 1; }

.portlet-icon {
  -moz-osx-font-smoothing: grayscale;
  font-family: "pages-icon";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none; }

.portlet-icon-close:before {
  content: "\e60a"; }

.portlet-icon-maximize:before {
  content: "\e634"; }

.portlet-icon-refresh:before {
  content: "\e600"; }

.portlet-icon-collapse:before {
  content: "\e62c"; }

.portlet-icon-settings:before {
  content: "\e655"; }

.portlet-icon-refresh-lg-master,
.portlet-icon-refresh-lg-white {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .portlet-icon-refresh-lg-master.fade,
  .portlet-icon-refresh-lg-white.fade {
    opacity: 0.1; }

.portlet-icon-refresh-lg-master {
    /*background-image: url("../img/progress/progress-circle-lg-master-static.svg");*/
}

.portlet-icon-refresh-lg-white {
    /*background-image: url("../img/progress/progress-circle-lg-white-static.svg");*/
}

.portlet-icon-refresh-lg-master-animated,
.portlet-icon-refresh-lg-white-animated {
  width: 15px;
  height: 15px;
  display: block;
  background-size: cover;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .portlet-icon-refresh-lg-master-animated.active,
  .portlet-icon-refresh-lg-white-animated.active {
    opacity: 1; }

.portlet-icon-refresh-lg-master-animated {
    /*background-image: url("../img/progress/progress-circle-lg-master.svg");*/
}

.portlet-icon-refresh-lg-white-animated {
    /*background-image: url("../img/progress/progress-circle-lg-white.svg");*/
}

/* For demo purpose only */
.panel-scroll {
  height: 100px; }

.sortable .panel-heading {
  cursor: move; }

/* To prevent lag while dragging */
.ui-sortable-handle {
  transition: max-height 0.3s ease 0s; }

.sortable .grid .grid-title {
  cursor: move; }

.ui-sortable {
  min-height: 0px !important; }

.ui-sortable-placeholder {
  border: 1px dotted black;
  visibility: visible !important;
  height: 100% !important; }
  .ui-sortable-placeholder * {
    visibility: hidden; }

.sortable-box-placeholder {
  background-color: #e5e8e9;
  border: 1px dashed #dee1e3;
  display: block;
  margin-top: 0px !important;
  margin-bottom: 24px !important; }
  .sortable-box-placeholder * {
    visibility: hidden; }

.sortable .panel {
  transition: none; }

.sortable-column {
  padding-bottom: 100px; }

.demo-portlet-scrollable {
  height: 158px; }

/*------------------------------------------------------------------
[5. Typography]
*/
/* Standard elements
--------------------------------------------------
*/
/* To Load Hinted Fonts for Windows */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYAzyDMXhdD8sAj6OAJTFsBI.woff2) format("woff2"), url(https://fonts.gstatic.com/s/montserrat/v5/zhcz-_WihjSQC0oHJ9TCYL3hpw3pgy2gAi-Ip7WPMi0.woff) format("woff"), url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat-Bold"), url(https://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcmaVI6zN22yiurzcBKxPjFE.woff2) format("woff2"), url(https://fonts.gstatic.com/s/montserrat/v5/IQHow_FEYlDC4Gzy_m8fcnbFhgvWbfSbdVg11QabG8w.woff) format("woff"), url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype"); }

/*@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Regular'), local('Roboto-Regular'), url(http://themes.googleusercontent.com/static/fonts/roboto/v11/2UX7WLTfW3W8TclTUvlFyQ.woff) format('woff'),url('../fonts/roboto/roboto-regular_[allfont.net].ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(http://themes.googleusercontent.com/static/fonts/roboto/v11/d-6IYplOFocCacKzxwXSOD8E0i7KZn-EPnyo3HZu7kw.woff) format('woff'),url('../fonts/roboto/roboto-bold_[allfont.net].ttf') format('truetype');
}*/
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  color: #455A64;
  font-family: 'Roboto',"Segoe UI", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1; }

/* Headings 
------------------------------------
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-family: 'Montserrat',"Segoe UI","Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
  color: #232d32; }

h1 {
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -0.08px; }

h2 {
  font-size: 31px;
  line-height: 40px; }

h3 {
  font-size: 27px;
  line-height: 35px; }

h4 {
  font-size: 22px;
  line-height: 31.88px; }

h5 {
  font-size: 18px;
  line-height: 25.88px; }

h3 small,
h4 small,
h5 small {
  font-weight: 300; }

h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-bottom: 10px; }

/* Lins and Others
------------------------------------
*/
a {
  text-shadow: none !important;
  color: #3d669c;
  transition: color 0.1s linear 0s,background-color 0.1s linear 0s,opacity 0.2s linear 0s !important; }

a:focus, a:hover, a:active {
  color: #4b7ec1; }

a, a:focus, a:hover, a:active {
  outline: 0 !important;
  text-decoration: none; }

a[ng-click] {
  cursor: pointer; }

br {
  line-height: normal;
  clear: both; }

code {
  color: #c6362c;
  background-color: #e5e8e9; }
  code:hover {
    background-color: #fdd9d7; }

p {
  display: block;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal; }

small,
.small {
  line-height: 18px; }

label.inline {
  display: inline-block;
  position: relative;
  top: 0px;
  font-size: 13px; }

ul > li, ol > li {
  padding-left: 3px;
  line-height: 24px; }

ul.lg-icon > li, ol.lg-icon > li {
  font-size: 21px; }
  ul.lg-icon > li span, ol.lg-icon > li span {
    font-size: 14px; }

ul.no-style, ol.no-style {
  list-style: none;
  padding-left: 5px; }

address {
  margin-bottom: 0px; }
  address a {
    color: #455A64; }

blockquote {
  padding: 0 0 0 18px;
  border-left: 0; }
  blockquote:before {
    content: '';
    font-family: FontAwesome;
    content: "\f10d";
    margin-right: 13px;
    float: left; }
  blockquote p {
    font-size: 16px; }
  blockquote small {
    line-height: 29px;
    color: #8b91a0;
    padding-left: 30px; }
    blockquote small:before {
      content: ""; }
  blockquote.pull-right {
    border-right: 0; }
    blockquote.pull-right:before {
      float: right;
      content: '';
      font-family: FontAwesome;
      content: "\f10d";
      margin-left: 13px;
      margin-right: 0; }
    blockquote.pull-right small {
      padding-right: 30px; }
      blockquote.pull-right small:after {
        content: ""; }

hr {
  border-color: #dee1e3; }
  hr.double {
    border-width: 2px; }
  hr.dotted {
    border-style: dotted none none; }

/* Font Sizes 
------------------------------------
*/
.small-text {
  font-size: 12px !important; }

.normal-text {
  font-size: 13px !important; }

.large-text {
  font-size: 15px !important; }

/* Font Weights
------------------------------------
 */
.normal {
  font-weight: normal; }

.semi-bold {
  font-weight: 400 !important; }

.bold {
  font-weight: bold !important; }

.light {
  font-weight: 300 !important; }

/* Misc 
------------------------------------
*/
.logo {
  margin: 18px 14px; }

.all-caps {
  text-transform: uppercase; }

.muted {
  color: #ccd7dc; }

.hint-text {
  opacity: .7; }

.no-decoration {
  text-decoration: none !important; }

/* Monochrome Colors
------------------------------------
 */
.bg-master {
  background-color: #455A64; }

.bg-master-light {
  background-color: #dee1e3; }

.bg-master-lighter {
  background-color: #e5e8e9; }

.bg-master-lightest {
  background-color: #eef0f1; }

.bg-master-dark {
  background-color: #232d32; }

.bg-master-darker {
  background-color: #13191c; }

.bg-master-darkest {
  background-color: #0e1214; }

/* Contextual Colors
------------------------------------
*/
/* Primary
------------------------------------
*/
.bg-primary {
  background-color: #2464b4; }

.bg-primary-dark {
  background-color: #1d5192; }

.bg-primary-darker {
  background-color: #163c6c; }

.bg-primary-light {
  background-color: #5083c3; }

.bg-primary-lighter {
  background-color: #d3e0f0; }

/* Complete 
------------------------------------
*/
.bg-complete {
  background-color: #4b7ec1; }

.bg-complete-dark {
  background-color: #3d669c; }

.bg-complete-darker {
  background-color: #2d4c74; }

.bg-complete-light {
  background-color: #6f98cd; }

.bg-complete-lighter {
  background-color: #dbe5f3; }

/* Success 
------------------------------------
*/
.bg-success {
  background-color: #88c843; }

.bg-success-dark {
  background-color: #6ea236; }

.bg-success-darker {
  background-color: #527828; }

.bg-success-light {
  background-color: #a0d369; }

.bg-success-lighter {
  background-color: #e7f4d9; }

/* Info
------------------------------------
*/
.bg-info {
  background-color: #616161; }

.bg-info-dark {
  background-color: #4f4f4f; }

.bg-info-darker {
  background-color: #3a3a3a; }

.bg-info-light {
  background-color: #818181; }

.bg-info-lighter {
  background-color: #dfdfdf; }

/* Danger 
------------------------------------
*/
.bg-danger {
  background-color: #f44336; }

.bg-danger-dark {
  background-color: #c6362c; }

.bg-danger-darker {
  background-color: #922820; }

.bg-danger-light {
  background-color: #f6695e; }

.bg-danger-lighter {
  background-color: #fdd9d7; }

/* Warning
------------------------------------
 */
.bg-warning {
  background-color: #FDD835; }

.bg-warning-dark {
  background-color: #cdaf2b; }

.bg-warning-darker {
  background-color: #988220; }

.bg-warning-light {
  background-color: #fde05d; }

.bg-warning-lighter {
  background-color: #fff7d7; }

/* More Color Options
------------------------------------
*/
/* Menu 
------------------------------------
*/
.bg-menu-dark {
  background-color: #1c4d8b; }

.bg-menu {
  background-color: #2464b4; }

.bg-menu-light {
  background-color: white; }

/* Gradients
------------------------------------
*/
.gradient-grey {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%); }

.gradient-black {
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 75%); }

/* Other Colors
------------------------------------
*/
.bg-white {
  background-color: #fff; }

.bg-transparent {
  background-color: transparent !important; }

/* Text Colors */
.link {
  opacity: .7; }
  .link:hover {
    opacity: 1; }

.text-master {
  color: #455A64 !important; }

.text-master-light {
  color: #dee1e3 !important; }

.text-black {
  color: #232d32 !important; }

.text-white {
  color: #fff !important; }

.text-complete {
  color: #4b7ec1 !important; }

.text-success {
  color: #88c843 !important; }

.text-info {
  color: #616161 !important; }

.text-warning {
  color: #FDD835 !important; }

.text-warning-dark {
  color: #cdaf2b !important; }

.text-danger {
  color: #f44336 !important; }

.text-primary {
  color: #2464b4 !important; }

/* Text Aligngments
------------------------------------
*/
.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

/* Labels
------------------------------------
*/
.label {
  padding: 3px 9px;
  font-size: 11px;
  text-shadow: none;
  background-color: #dee1e3;
  font-weight: 600;
  color: #455A64; }

.label-success {
  background-color: #88c843;
  color: #fff; }

.label-warning {
  background-color: #FDD835;
  color: #fff; }

.label-important, .label-danger {
  background-color: #f44336;
  color: #fff; }

.label-info {
  background-color: #4b7ec1;
  color: #fff; }

.label-inverse {
  background-color: #3d669c;
  color: #fff; }

.label-white {
  background-color: #fff;
  color: #455A64; }

/* Font Sizes
------------------------------------
*/
.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

/* Line-heights
------------------------------------
*/
.lh-normal {
  line-height: normal; }

.lh-10 {
  line-height: 10px; }

.lh-11 {
  line-height: 11px; }

.lh-12 {
  line-height: 12px; }

.lh-13 {
  line-height: 13px; }

.lh-14 {
  line-height: 14px; }

.lh-15 {
  line-height: 15px; }

.lh-16 {
  line-height: 16px; }

/* Font Faces
------------------------------------
*/
.font-arial {
  font-family: Arial, sans-serif !important; }

.font-montserrat {
  font-family: 'Montserrat' !important; }

.font-georgia {
  font-family: Georgia !important; }

.font-heading {
  font-family: "Segoe UI","Helvetica Neue", Helvetica, Arial, sans-serif; }

/* Wells
------------------------------------
*/
.well {
  background-color: #dee1e3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  background-image: none; }
  .well.well-large {
    padding: 24px;
    width: auto; }
  .well.well-small {
    padding: 13px;
    width: auto; }
  .well.green {
    background-color: #4b7ec1;
    color: #fff;
    border: none; }

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.jumbotron p {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: inherit; }
  .jumbotron p.small {
    font-size: 85%; }

/* Responsive Handlers : Typo
------------------------------------
*/
@media (max-width: 1400px) {
  body, p {
    font-size: 13px;
    line-height: 20px; }
  h1 {
    font-size: 33px;
    line-height: 44px;
    letter-spacing: -0.08px; }
  h2 {
    font-size: 28px;
    line-height: 40px; }
  h3 {
    font-size: 24px;
    line-height: 35.88px; }
  h4 {
    font-size: 18px;
    line-height: 33.88px; }
  h5 {
    font-size: 16px;
    line-height: 25.88px; }
  small, .small {
    font-size: 89%;
    line-height: 17px; } }

/* For Windows : Fixes 
------------------------------------
*/
.windows body,
.windows p {
  font-size: 13px;
  letter-spacing: normal; }

.windows h1 {
  font-size: 33px;
  line-height: 49px; }

.windows h2 {
  font-size: 29px;
  line-height: 40px; }

.windows h3 {
  font-size: 29px;
  line-height: 33px; }

.windows h4 {
  font-size: 23px;
  line-height: 32px; }

.windows h5 {
  font-size: 19px;
  line-height: 28px;
  font-weight: normal; }

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  font-weight: 300;
  letter-spacing: normal; }

.windows .jumbotron p {
  font-size: 13px; }
  .windows .jumbotron p.small {
    font-size: 88%; }

.windows small,
.windows .small {
  font-size: 89%; }

/*------------------------------------------------------------------
[6. Buttons]
*/
/*
[Buttons Base Styles]
*/
.btn {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  margin-bottom: 0;
  border: 1px solid #e5e8e9;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background-image: none !important;
  color: #455A64;
  background-color: #fff;
  text-shadow: none;
  box-shadow: none;
  line-height: 21px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  transition: color 0.1s linear 0s,background-color 0.1s linear 0s,opacity 0.2s linear 0s !important; }
  .btn:hover {
    background-color: #fafafa;
    border: 1px solid rgba(69, 90, 100, 0.27); }
  .btn.active {
    border-color: #dee1e3;
    background: #fff; }
  .btn:focus, .btn:active:focus, .btn.active:focus {
    outline: none !important;
    outline-style: none; }
  .btn .caret {
    margin-left: 3px; }
    .btn .caret.single {
      margin-left: 0px; }
  .btn:hover, .btn:focus, .btn:active, .btn.active, .btn.disabled, .btn[disabled] {
    box-shadow: none; }

button:focus {
  outline: none !important; }

/*
Alternate buttons
--------------------------------------------------
*/
.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #272727;
    border-color: #272727;
}

    .btn-primary.active, .btn-primary:active, .btn-primary.active:focus, .btn-primary:active:focus, .btn-primary:active:hover,
    .btn-primary .open .dropdown-toggle.btn-primary {
        background-color: #525252;
        border-color: #525252;
        color: #fff;
    }

    .btn-primary.hover, .btn-primary:hover,
    .btn-primary .open .dropdown-toggle.btn-primary {
        background-color: #525252;
        border-color: #525252;
        color: #fff;
    }

.btn-primary.active:hover {
  background: #1f569b;
  border-color: #1f569b; }

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #2464b4;
  border-color: #2464b4; }

.btn-primary .badge {
  color: #2464b4;
  background-color: #fff; }

.btn-success, .btn-success:focus {
  color: #fff;
  background-color: #88c843;
  border-color: #88c843; }

.btn-success.active, .btn-success:active, .btn-success.active:focus, .btn-success:active:focus, .btn-success:active:hover,
.btn-success .open .dropdown-toggle.btn-success {
  background-color: #6ea236;
  border-color: #6ea236;
  color: #fff; }

.btn-success.hover, .btn-success:hover,
.btn-success .open .dropdown-toggle.btn-success {
  background-color: #a0d369;
  border-color: #a0d369;
  color: #fff; }

.btn-success.active:hover {
  background: #75ac3a;
  border-color: #75ac3a; }

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #88c843;
  border-color: #88c843; }

.btn-success .badge {
  color: #88c843;
  background-color: #fff; }

.btn-complete, .btn-complete:focus {
  color: #fff;
  background-color: #4b7ec1;
  border-color: #4b7ec1; }

.btn-complete.active, .btn-complete:active, .btn-complete.active:focus, .btn-complete:active:focus, .btn-complete:active:hover,
.btn-complete .open .dropdown-toggle.btn-complete {
  background-color: #3d669c;
  border-color: #3d669c;
  color: #fff; }

.btn-complete.hover, .btn-complete:hover,
.btn-complete .open .dropdown-toggle.btn-complete {
  background-color: #6f98cd;
  border-color: #6f98cd;
  color: #fff; }

.btn-complete.active:hover {
  background: #416ca6;
  border-color: #416ca6; }

.btn-complete.disabled, .btn-complete.disabled:hover, .btn-complete.disabled:focus, .btn-complete.disabled:active, .btn-complete.disabled.active, .btn-complete[disabled], .btn-complete[disabled]:hover, .btn-complete[disabled]:focus, .btn-complete[disabled]:active, .btn-complete[disabled].active,
fieldset[disabled] .btn-complete,
fieldset[disabled] .btn-complete:hover,
fieldset[disabled] .btn-complete:focus,
fieldset[disabled] .btn-complete:active,
fieldset[disabled] .btn-complete.active {
  background-color: #4b7ec1;
  border-color: #4b7ec1; }

.btn-complete .badge {
  color: #4b7ec1;
  background-color: #fff; }

.btn-info, .btn-info:focus {
  color: #fff;
  background-color: #616161;
  border-color: #616161; }

.btn-info.active, .btn-info:active, .btn-info.active:focus, .btn-info:active:focus, .btn-info:active:hover,
.btn-info .open .dropdown-toggle.btn-info {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: #fff; }

.btn-info.hover, .btn-info:hover,
.btn-info .open .dropdown-toggle.btn-info {
  background-color: #818181;
  border-color: #818181;
  color: #fff; }

.btn-info.active:hover {
  background: #535353;
  border-color: #535353; }

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #616161;
  border-color: #616161; }

.btn-info .badge {
  color: #616161;
  background-color: #fff; }

.btn-warning, .btn-warning:focus {
  color: #fff;
  background-color: #FDD835;
  border-color: #FDD835; }

.btn-warning.active, .btn-warning:active, .btn-warning.active:focus, .btn-warning:active:focus, .btn-warning:active:hover,
.btn-warning .open .dropdown-toggle.btn-warning {
  background-color: #cdaf2b;
  border-color: #cdaf2b;
  color: #fff; }

.btn-warning.hover, .btn-warning:hover,
.btn-warning .open .dropdown-toggle.btn-warning {
  background-color: #fde05d;
  border-color: #fde05d;
  color: #fff; }

.btn-warning.active:hover {
  background: #daba2e;
  border-color: #daba2e; }

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #FDD835;
  border-color: #FDD835; }

.btn-warning .badge {
  color: #FDD835;
  background-color: #fff; }

.btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336; }

.btn-danger.active, .btn-danger:active, .btn-danger.active:focus, .btn-danger:active:focus, .btn-danger:active:hover,
.btn-danger .open .dropdown-toggle.btn-danger {
  background-color: #c6362c;
  border-color: #c6362c;
  color: #fff; }

.btn-danger.hover, .btn-danger:hover,
.btn-danger .open .dropdown-toggle.btn-danger {
  background-color: #f6695e;
  border-color: #f6695e;
  color: #fff; }

.btn-danger.active:hover {
  background: #d23a2e;
  border-color: #d23a2e; }

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #f44336;
  border-color: #f44336; }

.btn-danger .badge {
  color: #f44336;
  background-color: #fff; }

.btn-default, .btn-default:focus {
  color: #5e5e5e;
  background-color: #fff;
  border-color: #e5e8e9; }

.btn-default.active, .btn-default:active, .btn-default.active:focus, .btn-default:active:focus, .btn-default:active:hover {
  background-color: #e5e8e9;
  border-color: #dee1e3;
  color: #232d32; }

.btn-default.hover, .btn-default:hover {
  background-color: #eef0f1;
  border-color: rgba(69, 90, 100, 0.27);
  color: #13191c; }

.btn-default.active:hover {
  background: #f0f0f0; }

.btn-link {
  color: #5e5e5e;
  background-color: transparent;
  border: none; }
  .btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.disabled, .btn-link[disabled] {
    background-color: transparent;
    border: none;
    text-decoration: none;
    outline: none; }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  background: red;
  cursor: inherit;
  display: block; }

/*
Button Sizes
--------------------------------------------------
*/
.btn-lg, .btn-group-lg > .btn {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 23px; }

.btn-sm, .btn-group-sm > .btn {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 11.9px;
  line-height: 20px; }

.btn-xs, .btn-group-xs > .btn {
  padding: 2px 9px;
  font-size: 10.5px; }

.btn-cons {
  margin-right: 5px;
  min-width: 120px; }

/*
Rounded buttons
--------------------------------------------------
*/
.btn-rounded {
  border-radius: 100px; }

/*
 Dropdown menus
--------------------------------------------------
*/
.open .btn-group.open .dropdown-toggle,
.open .dropdown-toggle,
.open.dropdown-default .dropdown-toggle {
  box-shadow: none; }

/* Pages default dropdown */
.dropdown-default {
  display: inline-block; }
  .dropdown-default.open > .btn.dropdown-toggle {
    border-color: transparent !important;
    background: transparent !important;
    z-index: 791 !important; }
  .dropdown-default.open .dropdown-menu {
    opacity: 1;
    transform: scale(1, 1);
    z-index: 700; }
    .dropdown-default.open .dropdown-menu li {
      visibility: visible; }
  .dropdown-default.open.dropup > .btn-primary + .dropdown-menu:after,
  .dropdown-default.open.dropup > .btn-success + .dropdown-menu:after,
  .dropdown-default.open.dropup > .btn-complete + .dropdown-menu:after,
  .dropdown-default.open.dropup > .btn-warning + .dropdown-menu:after,
  .dropdown-default.open.dropup > .btn-danger + .dropdown-menu:after,
  .dropdown-default.open.dropup > .btn-info + .dropdown-menu:after {
    top: auto;
    bottom: 0; }
  .dropdown-default.open > .btn-primary + .dropdown-menu:after,
  .dropdown-default.open > .btn-success + .dropdown-menu:after,
  .dropdown-default.open > .btn-complete + .dropdown-menu:after,
  .dropdown-default.open > .btn-warning + .dropdown-menu:after,
  .dropdown-default.open > .btn-danger + .dropdown-menu:after,
  .dropdown-default.open > .btn-info + .dropdown-menu:after {
    top: 0;
    height: 42px; }
  .dropdown-default.open > .btn-primary + .dropdown-menu:after {
    background-color: #2464b4; }
  .dropdown-default.open > .btn-success + .dropdown-menu:after {
    background-color: #88c843; }
  .dropdown-default.open > .btn-complete + .dropdown-menu:after {
    background-color: #4b7ec1; }
  .dropdown-default.open > .btn-warning + .dropdown-menu:after {
    background-color: #FDD835; }
  .dropdown-default.open > .btn-danger + .dropdown-menu:after {
    background-color: #f44336; }
  .dropdown-default.open > .btn-info + .dropdown-menu:after {
    background-color: #616161; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu {
    margin-bottom: -47px;
    padding-bottom: 49px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
      bottom: 49px; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu {
    margin-bottom: -34px;
    padding-bottom: 36px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
      bottom: 36px; }
  .dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu {
    margin-bottom: -29px;
    padding-bottom: 31px; }
    .dropdown-default.dropup .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
      bottom: 31px; }
  .dropdown-default.dropup .dropdown-menu {
    margin-bottom: -40px;
    padding: 0 3px 44px 0 !important;
    transform-origin: center bottom 0; }
    .dropdown-default.dropup .dropdown-menu:after {
      bottom: 43px;
      top: auto !important; }
  .dropdown-default .btn.dropdown-toggle {
    text-align: left;
    padding-right: 27px; }
    .dropdown-default .btn.dropdown-toggle.btn-lg {
      padding-right: 42px; }
      .dropdown-default .btn.dropdown-toggle.btn-lg .caret {
        right: 26px; }
      .dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu {
        margin-top: -47px;
        padding-top: 49px; }
        .dropdown-default .btn.dropdown-toggle.btn-lg + .dropdown-menu:after {
          top: 49px; }
    .dropdown-default .btn.dropdown-toggle.btn-sm {
      padding-right: 26px; }
      .dropdown-default .btn.dropdown-toggle.btn-sm .caret {
        right: 16px; }
      .dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu {
        margin-top: -34px;
        padding-top: 36px; }
        .dropdown-default .btn.dropdown-toggle.btn-sm + .dropdown-menu:after {
          top: 36px; }
    .dropdown-default .btn.dropdown-toggle.btn-xs {
      padding-right: 21px; }
      .dropdown-default .btn.dropdown-toggle.btn-xs .caret {
        right: 8px; }
      .dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu {
        margin-top: -29px;
        padding-top: 31px; }
        .dropdown-default .btn.dropdown-toggle.btn-xs + .dropdown-menu:after {
          top: 31px; }
    .dropdown-default .btn.dropdown-toggle .caret {
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -2px; }
  .dropdown-default .btn-rounded {
    padding-left: 17px;
    padding-right: 17px; }
  .dropdown-default .btn-rounded + .dropdown-menu {
    border-radius: 17px; }
  .dropdown-default .dropdown-menu {
    margin-top: -40px;
    padding-top: 42px;
    overflow: hidden;
    backface-visibility: hidden;
    display: block;
    opacity: 0;
    transform: scale(1, 0);
    transform-origin: center top 0;
    -webkit-transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
    transition: all 170ms cubic-bezier(0.05, 0.74, 0.27, 0.99) 0s;
    z-index: -1; }
    .dropdown-default .dropdown-menu:after {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      top: 42px;
      background: #dee1e3;
      width: 100%; }
    .dropdown-default .dropdown-menu li {
      visibility: hidden; }

.dropdown-menu {
  position: absolute;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  -webkit-box-shadow: 0px 0px 5px rgba(69, 90, 100, 0.2);
  box-shadow: 0px 0px 5px rgba(69, 90, 100, 0.2);
  border: none;
  border-radius: 3px;
  font-size: 13px;
  margin: 0;
  background: #eef0f1;
  min-width: 50px;
  z-index: 700 !important; }
  .dropdown-menu .divider {
    background-color: #dee1e3;
    height: 1px;
    margin: 3px 0;
    border-bottom: 0px; }
  .dropdown-menu > li {
    padding-left: 0px; }
    .dropdown-menu > li:first-child {
      padding-top: 9px; }
    .dropdown-menu > li:last-child {
      padding-bottom: 9px; }
    .dropdown-menu > li.dropdown-header {
      padding: 3px 20px; }
    .dropdown-menu > li.active > a, .dropdown-menu > li.active > a:hover, .dropdown-menu > li.active > a:focus {
      color: #13191c;
      text-decoration: none;
      background-color: #e5e8e9;
      background-image: none; }
    .dropdown-menu > li.disabled > a, .dropdown-menu > li.disabled > a:hover, .dropdown-menu > li.disabled > a:focus {
      color: #728f9d; }
    .dropdown-menu > li.disabled > a:hover, .dropdown-menu > li.disabled > a:focus {
      text-decoration: none;
      cursor: default;
      background-color: transparent;
      background-image: none; }
    .dropdown-menu > li > a {
      line-height: 35px;
      color: #455A64;
      padding: 0 20px;
      border-radius: 3px;
      text-align: left; }
      .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
        color: #13191c;
        text-decoration: none;
        background-color: transparent;
        background-image: none; }

.dropdown-backdrop {
  z-index: 600; }

/*
Animated buttons
--------------------------------------------------
*/
.btn-animated {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d; }
  .btn-animated > span {
    display: inline-block;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d; }
  .btn-animated:before {
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 2.5;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .btn-animated:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .btn-animated.from-top:before {
    left: 0;
    top: -100%; }
  .btn-animated.from-top:hover:before, .btn-animated.from-top.show-icon:before {
    top: 0; }
  .btn-animated.from-top:hover > span, .btn-animated.from-top.show-icon > span {
    -webkit-transform: translateY(300%);
    -ms-transform: translateY(300%);
    transform: translateY(300%); }
  .btn-animated.from-left:before {
    left: -100%;
    top: 0; }
  .btn-animated.from-left:hover:before, .btn-animated.from-left.show-icon:before {
    left: 0; }
  .btn-animated.from-left:hover > span, .btn-animated.from-left.show-icon > span {
    -webkit-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%); }
  .btn-animated.fa:before {
    font-family: FontAwesome; }
  .btn-animated.pg:before {
    font-family: "pages-icon"; }

/*
Tag buttons
--------------------------------------------------
*/
.btn-tag {
  line-height: 17px;
  border-radius: 17px 3px 3px 17px;
  padding: 5px 19px; }
  .btn-tag:hover, .btn-tag.hover {
    border-color: transparent; }
  .btn-tag.btn-tag-light {
    background: #fafdff;
    color: #5b8ca5;
    border: 1px solid #cbe4f0; }
    .btn-tag.btn-tag-light:hover {
      background: #fff; }
  .btn-tag.btn-tag-dark {
    background: #dee1e3;
    color: #455A64; }
    .btn-tag.btn-tag-dark:hover {
      background: #e4e6e8; }
  .btn-tag.btn-tag-rounded {
    border-radius: 17px; }

/*
Misc buttons
--------------------------------------------------
*/
.btn-toolbar .btn {
  padding-left: 14px;
  padding-right: 14px; }

.pager .disabled > button,
.pager .disabled > button:hover,
.pager .disabled > button:focus,
.pager .disabled > span {
  cursor: not-allowed;
  opacity: .5; }

/*------------------------------------------------------------------
[7. Alerts]
*/
.alert {
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  padding: 9px 19px 9px 15px;
  border-radius: 3px;
  font-size: 13px;
  border-width: 0;
  -webkit-transition: all 0.2s linear 0s;
  transition: all 0.2s linear 0s; }
  .alert.bordered {
    border-width: 1px; }
  .alert .link {
    color: #ce8f22;
    font-weight: bold; }
  .alert .alert-heading {
    color: #ce8f22 !important;
    margin-bottom: 5px;
    font-weight: 600; }
  .alert .btn-small {
    position: relative;
    top: -3.5px; }
  .alert .button-set .btn {
    position: relative;
    top: 8px; }
  .alert .close {
    background: url("../img/icons/noti-cross.png") no-repeat scroll 0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    top: 4.5px;
    margin-left: 20px;
    font-size: 0; }
    .alert .close:hover {
      opacity: 1; }

/* Alert : Color Options
------------------------------------
*/
.alert-danger,
.alert-error {
  background-color: #fdd9d7;
  color: #922820;
  border-color: #922820; }
  .alert-danger .close,
  .alert-error .close {
    background-position: -95px -10px !important; }

.alert-warning {
  background-color: #fff7d7;
  color: #988220;
  border-color: #988220; }

.alert-info {
  background-color: #dbe5f3;
  color: #2d4c74;
  border-color: #2d4c74; }
  .alert-info .close {
    background-position: -67px -10px !important; }

.alert-success {
  background-color: #e7f4d9;
  color: #527828;
  border-color: #527828; }
  .alert-success .close {
    background-position: -38px -10px !important; }

.alert-default {
  background-color: #fff;
  color: #455A64;
  border-color: #dee1e3; }
  .alert-default .close {
    background-position: -67px -10px !important; }

/*------------------------------------------------------------------
[8. Notifications]
*/
/* Badges
--------------------------------------------------
*/
.badge {
  text-shadow: none;
  font-family: Roboto, wf_segoe-ui_light, wf_segoe-ui_normal, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #dee1e3;
  font-size: 11px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 4px;
  color: #455A64; }

.badge-success {
  background-color: #88c843;
  color: #fff; }

.badge-warning {
  background-color: #FDD835;
  color: #fff; }

.badge-important {
  background-color: #f44336;
  color: #fff; }

.badge-danger {
  background-color: #f44336;
  color: #fff; }

.badge-info {
  background-color: #616161;
  color: #fff; }

.badge-inverse {
  background-color: #2d4c74;
  color: #fff; }

.badge-white {
  background-color: #fff;
  color: #455A64; }

.badge-disable {
  background-color: #232d32;
  color: #455A64; }

/* Notification popup
--------------------------------------------------
*/
.popover {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  z-index: 790; }

.notification-toggle {
  top: 35px;
  left: -26px;
  padding: 0; }
  .notification-toggle:before {
    border-bottom: 0px !important; }
  .notification-toggle:after {
    border-bottom: 0px !important; }

.notification-panel {
  background-color: #fff;
  border: 1px solid #dee1e3; }
  .notification-panel .notification-body {
    height: auto;
    max-height: 350px;
    position: relative;
    overflow: hidden; }
    .notification-panel .notification-body .notification-item {
      position: relative;
      margin-left: 25px;
      background-color: #fff;
      padding-right: 26px; }
      .notification-panel .notification-body .notification-item.unread .heading {
        opacity: 1; }
      .notification-panel .notification-body .notification-item.unread .option {
        background-color: #dbe5f3; }
      .notification-panel .notification-body .notification-item.unread .option .mark {
        color: #6f98cd; }
      .notification-panel .notification-body .notification-item .heading {
        line-height: 43px;
        margin-right: 5px;
        background: #fff;
        border-bottom: 1px solid #dee1e3;
        position: relative;
        float: left;
        width: 100%;
        font-size: 14px;
        opacity: 0.7; }
        .notification-panel .notification-body .notification-item .heading:after {
          content: '';
          position: absolute;
          width: 5px;
          height: 100%;
          background-color: #fff;
          right: 0;
          top: 1px; }
        .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper {
          cursor: pointer; }
          .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper i {
            -webkit-transition: all 0.12s linear;
            transition: all 0.12s linear; }
        .notification-panel .notification-body .notification-item .heading .time {
          font-size: 11px;
          margin-right: 8px; }
        .notification-panel .notification-body .notification-item .heading.open {
          display: block; }
          .notification-panel .notification-body .notification-item .heading.open .more-details {
            display: block; }
          .notification-panel .notification-body .notification-item .heading.open .thumbnail-wrapper i {
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            transform: rotate(-90deg); }
        .notification-panel .notification-body .notification-item .heading .thumbnail-wrapper.d24 {
          line-height: 20px; }
      .notification-panel .notification-body .notification-item .more-details {
        display: none;
        background-color: #fff;
        width: 100%;
        height: 100%;
        clear: both;
        position: relative; }
        .notification-panel .notification-body .notification-item .more-details .more-details-inner {
          margin-left: 10px;
          padding-left: 28px;
          padding-top: 15px;
          margin-bottom: 20px;
          position: relative;
          border-left: 1px solid #dee1e3; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner:after {
            width: 14px;
            height: 14px;
            position: absolute;
            content: '';
            background-color: #fff;
            left: -7px;
            top: 25px;
            border: 1px solid #dee1e3;
            border-radius: 99px;
            -webkit-border-radius: 99px;
            -moz-border-radius: 99px; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner:before {
            color: #b5bdc1;
            position: absolute;
            bottom: 0;
            left: -5px;
            content: ' \25CF';
            font-size: 13px;
            line-height: 5px;
            background-color: #fff;
            height: 9px; }
          .notification-panel .notification-body .notification-item .more-details .more-details-inner .hint-text {
            opacity: .44; }
      .notification-panel .notification-body .notification-item .option {
        font-size: 10px;
        position: absolute;
        height: 44px;
        width: 26px;
        right: 0;
        text-align: center;
        vertical-align: middle;
        line-height: 44px;
        background-color: #eef0f1;
        height: 100%; }
        .notification-panel .notification-body .notification-item .option .mark {
          background-color: transparent;
          color: #b5bdc1; }
          .notification-panel .notification-body .notification-item .option .mark:before {
            content: ' \25CF';
            font-size: 12px; }
      .notification-panel .notification-body .notification-item:last-child .heading {
        border-bottom: 0px; }
  .notification-panel .notification-footer {
    padding: 10px;
    display: block;
    border-top: 1px solid #dee1e3; }
    .notification-panel .notification-footer a {
      color: #455A64;
      opacity: .54; }
      .notification-panel .notification-footer a:hover {
        opacity: .74; }

/* Simple alerts
--------------------------------------------------
*/
.alerts-container {
  position: fixed;
  width: 350px;
  right: 20px;
  z-index: 999;
  top: 80px; }
  .alerts-container[data-placement$='-left'] {
    left: 100px;
    right: auto; }
  .alerts-container[data-placement$='-right'] {
    right: 20px;
    left: auto; }
  .alerts-container[data-placement^='top-'] {
    top: 80px;
    bottom: auto; }
  .alerts-container[data-placement^='bottom-'] {
    top: auto;
    bottom: 20px; }
  .alerts-container[data-placement='bottom-right'] .alert:last-child, .alerts-container[data-placement='bottom-left'] .alert:last-child {
    margin-bottom: 0; }
  .alerts-container .alert {
    position: relative; }
    .alerts-container .alert .close {
      position: absolute;
      right: 9px;
      top: 15px; }

/* Pages Notifications plugin
--------------------------------------------------
*/
body.menu-pin > .pgn-wrapper[data-position$='-left'],
body.menu-pin > .pgn-wrapper[data-position="top"],
body.menu-pin > .pgn-wrapper[data-position="bottom"] {
  left: 250px; }

.pgn-wrapper {
  position: fixed;
  z-index: 1000; }

.pgn-wrapper[data-position$='-left'] {
  left: 30px; }

.pgn-wrapper[data-position$='-right'] {
  right: 20px; }

.pgn-wrapper[data-position^='top-'] {
  top: 20px; }

.pgn-wrapper[data-position^='bottom-'] {
  bottom: 20px; }

.pgn-wrapper[data-position='top'] {
  top: 0;
  left: 0;
  right: 0; }

.pgn-wrapper[data-position='bottom'] {
  bottom: 0;
  left: 0;
  right: 0; }

.pgn {
  position: relative;
  margin: 10px; }

.pgn .alert {
  margin: 0; }

/* Simple 
------------------------------------
*/
.pgn-simple .alert {
  padding-top: 13px;
  padding-bottom: 13px;
  max-width: 500px;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  max-height: 250px;
  overflow: hidden; }

/* Bar 
------------------------------------
*/
.pgn-bar {
  overflow: hidden;
  margin: 0; }

.pgn-bar .alert {
  border-radius: 0;
  padding-top: 13px;
  padding-bottom: 13px;
  max-height: 91px; }

.pgn-wrapper[data-position='top'] .pgn-bar .alert {
  animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromTop 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: top left;
  -webkit-transform-origin: top left; }

.pgn-wrapper[data-position='bottom'] .pgn-bar .alert {
  animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: slideInFromBottom 0.5s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  transform-origin: bottom left;
  -webkit-transform-origin: bottom left; }

.pgn-bar .alert span {
  opacity: 0;
  animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards;
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.05, 0.74, 0.27, 0.99) forwards; }

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes slideInFromTop {
  0% {
    -webkit-transform: translateY(-100%); }
  100% {
    -webkit-transform: translateY(0); } }

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@-webkit-keyframes slideInFromBottom {
  0% {
    -webkit-transform: translateY(100%); }
  100% {
    -webkit-transform: translateY(0); } }

/* Circle 
------------------------------------
*/
.pgn-circle .alert {
  border-radius: 300px;
  animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  -webkit-animation: fadeInCircle 0.3s ease forwards, resizeCircle 0.3s 0.4s cubic-bezier(0.25, 0.25, 0.4, 1.6) forwards;
  height: 60px;
  overflow: hidden;
  padding: 6px 55px 6px 6px;
  -webkit-transform: translateZ(0);
  position: relative; }

.pgn-wrapper[data-position$='-right'] .pgn-circle .alert {
  float: right; }

.pgn-wrapper[data-position$='-left'] .pgn-circle .alert {
  float: left; }

.pgn-circle .alert > div > div.pgn-thumbnail > div {
  border-radius: 50%;
  overflow: hidden;
  width: 48px;
  height: 48px; }

.pgn-circle .alert > div > div.pgn-thumbnail > div > img {
  width: 100%;
  height: 100%; }

.pgn-circle .alert > div > div.pgn-message > div {
  opacity: 0;
  height: 47px;
  padding-left: 9px;
  animation: fadeIn .3s .5s ease forwards;
  -webkit-animation: fadeIn .3s .5s ease forwards;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word; }

.pgn-circle .alert > div > div.pgn-message > div p:only-child {
  padding: 12px 0; }

.pgn-circle .alert .close {
  margin-top: -12px;
  position: absolute;
  right: 18px;
  top: 50%;
  opacity: 0;
  animation: fadeIn .3s .5s ease forwards;
  -webkit-animation: fadeIn .3s .5s ease forwards; }

.pgn-circle .alert p {
  margin-bottom: 0; }

.pgn-circle .alert > div {
  display: table;
  height: 100%; }

.pgn-circle .alert > div > div {
  display: table-cell;
  vertical-align: middle; }

@keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px; }
  100% {
    opacity: 1;
    width: 60px; } }

@-webkit-keyframes fadeInCircle {
  0% {
    opacity: 0;
    width: 60px; }
  100% {
    opacity: 1;
    width: 60px; } }

@keyframes resizeCircle {
  0% {
    width: 60px; }
  100% {
    width: 300px; } }

@-webkit-keyframes resizeCircle {
  0% {
    width: 60px; }
  100% {
    width: 300px; } }

/* Flip 
------------------------------------
*/
.pgn-wrapper[data-position^='top-'] .pgn-flip {
  top: -30px; }

.pgn-wrapper[data-position^='bottom-'] .pgn-flip {
  bottom: -30px; }

.pgn-wrapper[data-position^='bottom-'] .pgn-flip .alert {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%; }

.pgn-flip .alert {
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  box-shadow: 0 6px 4px -3px rgba(0, 0, 0, 0.2);
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  border-radius: 0;
  padding: 25px 35px;
  max-width: 500px;
  max-height: 250px;
  overflow: hidden; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out; }
  100% {
    -webkit-transform: perspective(400px); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Pages Notification customizations */
body > .pgn-wrapper[data-position="top"] {
  top: 60px;
  left: 70px; }

body > .pgn-wrapper[data-position="bottom"] {
  left: 70px; }

body > .pgn-wrapper[data-position$='-left'] {
  left: 90px;
  right: auto; }

body > .pgn-wrapper[data-position^='top-'] {
  top: 80px;
  bottom: auto; }

.pgn-circle .alert .close {
  margin-top: -4px; }

body > .pgn-wrapper {
  z-index: 900; }

@media (max-width: 979px) {
  body > .pgn-wrapper[data-position="top"] {
    left: 0; }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0; } }

@media (max-width: 767px) {
  body > .pgn-wrapper[data-position$='-left'],
  body > .pgn-wrapper[data-position$='-right'] {
    left: 10px !important;
    right: 10px !important; }
  body > .pgn-wrapper[data-position$='-right'] .alert,
  body > .pgn-wrapper[data-position$='-left'] .alert {
    max-width: 100%;
    width: 100%; } }

/* Notifications demo
--------------------------------------------------
*/
.notification-positions {
  border-radius: 4px;
  border: 3px dashed #dee1e3;
  height: 370px;
  position: relative; }
  .notification-positions > div > .position:not(:only-child) {
    max-width: 50%; }
  .notification-positions .position {
    padding: 15px; }
    .notification-positions .position img {
      border: 3px solid transparent; }
    .notification-positions .position:hover {
      cursor: pointer; }
    .notification-positions .position.active img {
      border-color: #0090d9; }
    .notification-positions .position.pull-top, .notification-positions .position.pull-bottom {
      left: 0;
      right: 0; }
    .notification-positions .position img {
      width: 100%; }

/*------------------------------------------------------------------
[9. Progress Indicators]
*/
.progress {
  height: 4px;
  background-color: rgba(69, 90, 100, 0.2);
  background-image: none;
  box-shadow: none;
  border-radius: 0; }
  .progress.transparent {
    background-color: rgba(0, 0, 0, 0.28); }
  .progress.progress-small {
    height: 3px; }

/* Circular Progress : indeterminate color
------------------------------------
*/
.progress-bar-indeterminate {
   /*background: url("../img/progress/progress-bar-master.svg") no-repeat top left;*/
    width: 100%;
    height: 100%;
    background-size: cover;
}
    .progress-bar-indeterminate.progress-bar-primary {
        background-image: url("../img/progress/progress-bar-primary.svg");
        background-color: transparent;
    }
    .progress-bar-indeterminate.progress-bar-complete {
        background-image: url("../img/progress/progress-bar-complete.svg");
        background-color: transparent;
    }
  .progress-bar-indeterminate.progress-bar-success {
    background-image: url("../img/progress/progress-bar-success.svg");
    background-color: transparent; }
  .progress-bar-indeterminate.progress-bar-info {
    background-image: url("../img/progress/progress-bar-info.svg");
    background-color: transparent; }
  .progress-bar-indeterminate.progress-bar-warning {
    background-image: url("../img/progress/progress-bar-warning.svg");
    background-color: transparent; }
  .progress-bar-indeterminate.progress-bar-danger {
    background-image: url("../img/progress/progress-bar-danger.svg");
    background-color: transparent; }
  .progress-bar-indeterminate.progress-bar-master {
    background-image: url("../img/progress/progress-bar-master.svg");
    background-color: transparent; }

/* Progress Bar
------------------------------------
*/
.progress-bar {
  box-shadow: none;
  border-radius: 0;
  background-color: #455A64;
  background-image: none;
  -webkit-transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); }

/* Progress Bar : Color Options
------------------------------------
*/
.progress-bar-primary {
  background-color: #2464b4;
  background-image: none; }

.progress-bar-complete {
  background-color: #4b7ec1;
  background-image: none; }

.progress-bar-success {
  background-color: #88c843;
  background-image: none; }

.progress-bar-info {
  background-color: #616161;
  background-image: none; }

.progress-bar-warning {
  background-color: #FDD835;
  background-image: none; }

.progress-bar-danger {
  background-color: #f44336;
  background-image: none; }

.progress-bar-white {
  background-color: #fff;
  background-image: none; }

.progress-bar-black {
  background-color: #000;
  background-image: none; }

.progress-info .bar, .progress .bar-info {
  background-color: #3a3a3a;
  background-image: none; }

.progress-warning .bar, .progress .bar-warning {
  background-color: #988220;
  background-image: none; }

.progress-danger .bar, .progress .bar-danger {
  background-color: #922820;
  background-image: none; }

.progress-white .bar, .progress .bar-white {
  background-color: #fff;
  background-image: none; }

.progress-success.progress-striped .bar, .progress-striped .bar-success {
  background-color: #88c843; }

.progress-info.progress-striped .bar, .progress-striped .bar-info {
  background-color: #616161; }

/* Circular Progress : indeterminate 
------------------------------------
*/
/*
.progress-circle-indeterminate {
  background: url("/pages/img/progress/progress-circle-master.svg") no-repeat top left;
  width: 50px;
  height: 50px;
  background-size: 100% auto;
  margin: 0 auto; }
  .progress-circle-indeterminate.progress-circle-warning {
    background-image: url("/pages/img/progress/progress-circle-warning.svg"); }
  .progress-circle-indeterminate.progress-circle-danger {
    background-image: url("/pages/img/progress/progress-circle-danger.svg"); }
  .progress-circle-indeterminate.progress-circle-info {
    background-image: url("/pages/img/progress/progress-circle-info.svg"); }
  .progress-circle-indeterminate.progress-circle-primary {
    background-image: url("/pages/img/progress/progress-circle-primary.svg"); }
  .progress-circle-indeterminate.progress-circle-success {
    background-image: url("/pages/img/progress/progress-circle-success.svg"); }
  .progress-circle-indeterminate.progress-circle-complete {
    background-image: url("/pages/img/progress/progress-circle-complete.svg"); }
*/
/* Circular Progress 
------

.progress-circle {
  display: block;
  height: 45px;
  margin: 0 auto;
  position: relative;
  width: 45px;
  -webkit-backface-visibility: hidden; }
  .progress-circle.progress-circle-warning .pie .half-circle {
    border-color: #FDD835; }
  .progress-circle.progress-circle-danger .pie .half-circle {
    border-color: #f44336; }
  .progress-circle.progress-circle-info .pie .half-circle {
    border-color: #616161; }
  .progress-circle.progress-circle-primary .pie .half-circle {
    border-color: #2464b4; }
  .progress-circle.progress-circle-success .pie .half-circle {
    border-color: #88c843; }
  .progress-circle.progress-circle-complete .pie .half-circle {
    border-color: #4b7ec1; }
  .progress-circle.progress-circle-thick .pie .half-circle, .progress-circle.progress-circle-thick .shadow {
    border-width: 5px; }
  .progress-circle .pie {
    clip: rect(0, 45px, 45px, 22.5px);
    height: 45px;
    position: absolute;
    width: 45px; }
    .progress-circle .pie .half-circle {
      border: 3px solid #455A64;
      border-radius: 50%;
      clip: rect(0, 22.5px, 45px, 0);
      height: 45px;
      position: absolute;
      width: 45px; }
  .progress-circle .shadow {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 100%;
    width: 100%; }

/*------------------------------------------------------------------
[10. Modals]
*/
.modal .close:focus {
  outline: 0; }

.modal .modal-dialog {
  transition: all .2s !important; }

.modal .modal-content {
  border: 1px solid #f2f6f7;
  border-radius: 3px;
  box-shadow: none; }

.modal .modal-header {
  text-align: center;
  border-bottom: 0;
  padding: 25px 25px 0 25px; }
  .modal .modal-header p {
    color: #8b91a0; }

.modal .modal-body {
  box-shadow: none;
  padding: 25px;
  padding-top: 0;
  white-space: normal; }

.modal .modal-footer {
  border-top: none;
  box-shadow: none;
  margin-top: 0;
  padding: 25px;
  padding-top: 0; }

.modal .drop-shadow {
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important; }

.modal.fade {
  opacity: 1 !important; }
  .modal.fade.stick-up .modal-dialog {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -ms-transform: translate(0, -100%);
    margin-top: -5px; }
  .modal.fade.stick-up.in .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate(0, 0); }
  .modal.fade.slide-up {
    height: 100%;
    top: auto; }
    .modal.fade.slide-up.in .modal-dialog {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -ms-transform: translate(0, 0); }
    .modal.fade.slide-up.disable-scroll {
      overflow: hidden; }
    .modal.fade.slide-up .modal-dialog {
      display: table;
      height: 100%;
      -webkit-transform: translate3d(0, 75%, 0);
      transform: translate3d(0, 75%, 0);
      -ms-transform: translate(0, 75%); }
    .modal.fade.slide-up .modal-content-wrapper {
      display: table-cell;
      vertical-align: middle; }
  .modal.fade.center-scale .modal-dialog {
    display: table;
    height: 100%; }
  .modal.fade.center-scale .modal-content {
    display: table-cell;
    vertical-align: middle;
    border: 0;
    background: transparent; }
    .modal.fade.center-scale .modal-content .modal-header {
      border-bottom: 0px; }
  .modal.fade.center-scale.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .modal.fade.center-scale .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(0.6);
    -ms-transform: scale(0.6);
    transform: scale(0.6); }
  .modal.fade.fill-in {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    .modal.fade.fill-in > .close {
      margin-top: 20px;
      margin-right: 20px;
      opacity: 0.6; }
      .modal.fade.fill-in > .close > i {
        font-size: 16px; }
    .modal.fade.fill-in .modal-dialog {
      display: table;
      height: 100%; }
    .modal.fade.fill-in .modal-content {
      display: table-cell;
      vertical-align: middle;
      border: 0;
      background: transparent; }
      .modal.fade.fill-in .modal-content .modal-header {
        border-bottom: 0px; }
    .modal.fade.fill-in.in {
      background-color: rgba(255, 255, 255, 0.85); }
      .modal.fade.fill-in.in .modal-dialog {
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
    .modal.fade.fill-in .modal-dialog {
      opacity: 0;
      -webkit-transform: scale(0.6);
      -ms-transform: scale(0.6);
      transform: scale(0.6); }
  .modal.fade.slide-right .close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    z-index: 10; }
  .modal.fade.slide-right.in .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -ms-transform: translate(0, 0); }
  .modal.fade.slide-right .modal-dialog {
    position: absolute;
    right: 0;
    margin: 0;
    height: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -ms-transform: translate(100%, 0); }
    .modal.fade.slide-right .modal-dialog .modal-content-wrapper {
      height: 100%; }
    .modal.fade.slide-right .modal-dialog .modal-content {
      height: 100%; }
  .modal.fade.slide-right .modal-content {
    border-radius: 0px; }

.fill-in-modal .modal-backdrop {
  background-color: transparent; }

.modal-backdrop {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }
  .modal-backdrop.in {
    opacity: 0.30; }

/* Responsive Handlers: Modals
------------------------------------
*/
@media (max-width: 768px) {
  .modal.fill-in .modal-dialog {
    width: calc(100% - 20px); }
  .modal.slide-up .modal-dialog {
    width: calc(100% - 20px); } }

@media (min-width: 768px) {
  .modal.stick-up .modal-dialog {
    margin: -5px auto; }
  .modal.slide-up .modal-dialog {
    margin: 0 auto; }
  .modal.fill-in .modal-dialog {
    margin: 0 auto; }
  .modal .modal-content {
    box-shadow: none; } }

/*------------------------------------------------------------------
[11. Tabs & Accordians]
*/
/* Tabs
------------------------------------
*/
.nav-tabs > li {
  padding-left: 0;
  padding-right: 0; }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border-color: #dee1e3;
    color: #455A64;
    background-color: #dee1e3; }
  .nav-tabs > li > a {
    border-radius: 0;
    padding: 15px 20px;
    margin-right: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    color: rgba(69, 90, 100, 0.7);
    font-size: 12px;
    min-width: 70px;
    text-transform: uppercase;
    border-color: transparent; }
    .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
      background: transparent;
      border-color: transparent;
      color: #455A64; }
    .nav-tabs > li > a .tab-icon {
      margin-right: 6px; }

.nav-tabs ~ .tab-content {
  overflow: hidden;
  padding: 15px; }
  .nav-tabs ~ .tab-content > .tab-pane.slide-left, .nav-tabs ~ .tab-content > .tab-pane.slide-right {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding, .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
      opacity: 0 !important; }
    .nav-tabs ~ .tab-content > .tab-pane.slide-left.active, .nav-tabs ~ .tab-content > .tab-pane.slide-right.active {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -ms-transform: translate(0, 0);
      opacity: 1; }
  .nav-tabs ~ .tab-content > .tab-pane.slide-left.sliding {
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
    -ms-transform: translate(10%, 0); }
  .nav-tabs ~ .tab-content > .tab-pane.slide-right.sliding {
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
    -ms-transform: translate(-10%, 0); }

.nav-tabs.nav-tabs-left:after, .nav-tabs.nav-tabs-right:after {
  border-bottom: 0px; }

.nav-tabs.nav-tabs-left > li, .nav-tabs.nav-tabs-right > li {
  float: none; }

.nav-tabs.nav-tabs-left {
  float: left; }
  .nav-tabs.nav-tabs-left ~ .tab-content {
    border-left: 1px solid rgba(0, 0, 0, 0.1); }

.nav-tabs.nav-tabs-right {
  float: right; }
  .nav-tabs.nav-tabs-right ~ .tab-content {
    border-right: 1px solid rgba(0, 0, 0, 0.1); }

/* Tabs : Simple 
------------------------------------
*/
.nav-tabs-simple {
  border-bottom: 0px; }
  .nav-tabs-simple:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    bottom: 3px;
    z-index: 120; }
  .nav-tabs-simple > li {
    margin-bottom: 0; }
    .nav-tabs-simple > li:after {
      -webkit-transition: all 0.1s linear 0s;
      transition: all 0.1s linear 0s;
      -webkit-backface-visibility: hidden;
      width: 100%;
      display: block;
      background-color: #f44336;
      height: 0px;
      content: '';
      z-index: 125; }
      .nav-tabs-simple > li.nav-tabs-primary.nav-tabs-simple > li:after {
        background-color: #2464b4; }
      .nav-tabs-simple > li.nav-tabs-success.nav-tabs-simple > li:after {
        background-color: #88c843; }
      .nav-tabs-simple > li.nav-tabs-complete.nav-tabs-simple > li:after {
        background-color: #4b7ec1; }
      .nav-tabs-simple > li.nav-tabs-danger.nav-tabs-simple > li:after {
        background-color: #f44336; }
      .nav-tabs-simple > li.nav-tabs-warning.nav-tabs-simple > li:after {
        background-color: #FDD835; }
      .nav-tabs-simple > li.nav-tabs-info.nav-tabs-simple > li:after {
        background-color: #616161; }
    .nav-tabs-simple > li.active a, .nav-tabs-simple > li.active a:hover, .nav-tabs-simple > li.active a:focus {
      background-color: transparent;
      border-color: transparent; }
    .nav-tabs-simple > li.active:after, .nav-tabs-simple > li:hover:after {
      height: 3px; }
  .nav-tabs-simple.nav-tabs-left:after, .nav-tabs-simple.nav-tabs-right:after {
    border-bottom: 0px; }
  .nav-tabs-simple.nav-tabs-left > li:after, .nav-tabs-simple.nav-tabs-right > li:after {
    width: 0px;
    height: 100%;
    top: 0;
    bottom: 0;
    position: absolute; }
  .nav-tabs-simple.nav-tabs-left > li.active:after, .nav-tabs-simple.nav-tabs-left > li:hover:after, .nav-tabs-simple.nav-tabs-right > li.active:after, .nav-tabs-simple.nav-tabs-right > li:hover:after {
    width: 3px; }
  .nav-tabs-simple.nav-tabs-left > li:after {
    right: 0; }
  .nav-tabs-simple.nav-tabs-right > li:after {
    left: 0; }

/* Tabs : Line Triangles
------------------------------------
*/
.nav-tabs-linetriangle {
  border-bottom: 0; }
  .nav-tabs-linetriangle:after {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;
    bottom: 0; }
  .nav-tabs-linetriangle > li.active > a {
    background: transparent;
    box-shadow: none;
    border-color: transparent; }
    .nav-tabs-linetriangle > li.active > a:hover, .nav-tabs-linetriangle > li.active > a:focus, .nav-tabs-linetriangle > li.active > a:active {
      border-color: transparent;
      background-color: transparent; }
    .nav-tabs-linetriangle > li.active > a:after, .nav-tabs-linetriangle > li.active > a:before {
      border: medium solid transparent;
      content: "";
      height: 0;
      left: 50%;
      pointer-events: none;
      position: absolute;
      width: 0;
      z-index: 1;
      top: 100%; }
    .nav-tabs-linetriangle > li.active > a:after {
      border-top-color: #eef0f1;
      border-width: 10px;
      margin-left: -10px; }
    .nav-tabs-linetriangle > li.active > a:before {
      border-top-color: rgba(0, 0, 0, 0.2);
      border-width: 11px;
      margin-left: -11px; }
  .nav-tabs-linetriangle > li > a span {
    font-size: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .nav-tabs-linetriangle > li > a,
  .nav-tabs-linetriangle > li > a:hover,
  .nav-tabs-linetriangle > li > a:focus {
    overflow: visible;
    -webkit-transition: color 0.2s ease 0s;
    transition: color 0.2s ease 0s; }
  .nav-tabs-linetriangle ~ .tab-content {
    background: #fff; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs:after {
    border-bottom: none; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li.active > a:before {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-left: 11px solid rgba(0, 0, 0, 0.2); }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li.active > a:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #eef0f1;
    margin-top: 1px;
    margin-right: 2px; }
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li.active > a:after,
  .nav-tabs-linetriangle.nav-tabs-left.nav-tabs > li.active > a:before {
    top: auto;
    right: -23px;
    left: auto; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs:after {
    border-bottom: none; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li.active > a:before {
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid rgba(0, 0, 0, 0.2); }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li.active > a:after {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #eef0f1;
    margin-top: 1px;
    margin-left: -9px; }
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li.active > a:after,
  .nav-tabs-linetriangle.nav-tabs-right.nav-tabs > li.active > a:before {
    top: auto;
    left: -12px;
    right: auto; }

@media screen and (max-width: 58em) {
  .nav-tabs-linetriangle {
    font-size: 0.6em; } }

/* Tabs : Fill-up 
------------------------------------
*/
.nav-tabs-fillup {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000; }
  .nav-tabs-fillup > li {
    overflow: hidden; }
    .nav-tabs-fillup > li > a {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: color 0.3s ease 0s;
      transition: color 0.3s ease 0s;
      background: transparent; }
      .nav-tabs-fillup > li > a:after {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        background: none repeat scroll 0 0 #88c843;
        border: 1px solid #88c843;
        content: "";
        height: calc(100% + 1px);
        left: 0;
        position: absolute;
        top: 0;
        -webkit-transform: translate3d(0, 100%, 0px);
        transform: translate3d(0, 100%, 0px);
        -webkit-transition: -webkit-transform 0.3s ease 0s;
        transition: transform 0.3s ease 0s;
        width: 100%;
        z-index: -1; }
        .nav-tabs-fillup > li > a.nav-tabs-primary.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #2464b4;
          border: 1px solid #2464b4; }
        .nav-tabs-fillup > li > a.nav-tabs-success.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #88c843;
          border: 1px solid #88c843; }
        .nav-tabs-fillup > li > a.nav-tabs-complete.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #4b7ec1;
          border: 1px solid #4b7ec1; }
        .nav-tabs-fillup > li > a.nav-tabs-warning.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #FDD835;
          border: 1px solid #FDD835; }
        .nav-tabs-fillup > li > a.nav-tabs-danger.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #f44336;
          border: 1px solid #f44336; }
        .nav-tabs-fillup > li > a.nav-tabs-info.nav-tabs-fillup > li > a:after {
          background: none repeat scroll 0 0 #616161;
          border: 1px solid #616161; }
      .nav-tabs-fillup > li > a span {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(0px, 5px, 0px);
        transform: translate3d(0px, 5px, 0px);
        -webkit-transition: -webkit-transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s;
        display: block;
        font-weight: 700;
        line-height: 1.5; }
    .nav-tabs-fillup > li.active {
      z-index: 100; }
      .nav-tabs-fillup > li.active > a, .nav-tabs-fillup > li.active > a:hover, .nav-tabs-fillup > li.active > a:focus {
        border-color: transparent;
        background: transparent;
        color: #fff; }
      .nav-tabs-fillup > li.active > a:after {
        -webkit-transform: translate3d(0px, 0px, 0px);
        transform: translate3d(0px, 0px, 0px);
        -ms-transform: translate(0px, 0px); }
      .nav-tabs-fillup > li.active > a span {
        -webkit-transform: translate3d(0px, -5px, 0px);
        transform: translate3d(0px, -5px, 0px);
        -ms-transform: translate(0px, -5px); }
      .nav-tabs-fillup > li.active .icon:before {
        -webkit-transform: translate3d(0px, -5px, 0px);
        transform: translate3d(0px, -5px, 0px);
        -ms-transform: translate(0px, -5px); }
  .nav-tabs-fillup .icon:before {
    -webkit-transform: translate3d(0px, 5px, 0px);
    transform: translate3d(0px, 5px, 0px);
    -ms-transform: translate(0px, 5px);
    -webkit-transition: transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s;
    display: block;
    margin: 0; }
  .nav-tabs-fillup ~ .tab-content {
    background: #fff; }
  .nav-tabs-fillup.nav-tabs-left, .nav-tabs-fillup.nav-tabs-right {
    border-bottom: none; }
    .nav-tabs-fillup.nav-tabs-left:after, .nav-tabs-fillup.nav-tabs-right:after {
      border-bottom: none; }
    .nav-tabs-fillup.nav-tabs-left > li > a:after, .nav-tabs-fillup.nav-tabs-right > li > a:after {
      width: calc(100% + 1px);
      height: 100%; }
    .nav-tabs-fillup.nav-tabs-left > li.active a:after, .nav-tabs-fillup.nav-tabs-right > li.active a:after {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
      -ms-transform: translate(0px, 0px); }
  .nav-tabs-fillup.nav-tabs-left > li > a:after {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -ms-transform: translate(100%, 0); }
  .nav-tabs-fillup.nav-tabs-left > li > a span {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }
  .nav-tabs-fillup.nav-tabs-left > li.active a span {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-left > li.active .icon:before {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li > a:after {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -ms-transform: translate(-100%, 0);
    left: -1px; }
  .nav-tabs-fillup.nav-tabs-right > li > a span {
    -webkit-transform: translate3d(-5px, 0, 0px);
    transform: translate3d(-5px, 0, 0px);
    -ms-transform: translate(-5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li.active a span {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }
  .nav-tabs-fillup.nav-tabs-right > li.active .icon:before {
    -webkit-transform: translate3d(5px, 0, 0px);
    transform: translate3d(5px, 0, 0px);
    -ms-transform: translate(5px, 0); }

.nav-tabs-header {
  overflow: scroll;
  width: 100%; }
  .nav-tabs-header .nav-tabs {
    width: auto;
    white-space: nowrap; }
    .nav-tabs-header .nav-tabs > li {
      display: inline-block;
      float: inherit; }
  .nav-tabs-header.nav-tabs-linetriangle {
    height: 61px;
    overflow-y: hidden; }
    .nav-tabs-header.nav-tabs-linetriangle:after {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      width: 100%;
      position: relative;
      bottom: 3px;
      z-index: 120;
      content: "";
      clear: both; }
    .nav-tabs-header.nav-tabs-linetriangle ~ .tab-content {
      position: relative;
      top: -12px; }

.nav-tabs-header ~ .tab-content {
  overflow: hidden;
  padding: 15px; }

.nav-tab-dropdown .cs-select {
  width: 100% !important;
  max-width: 100%; }

/* Panel Groups
------------------------------------
*/
.panel-group {
  margin-bottom: 18px; }
  .panel-group .panel-heading {
    padding: 13px 18px 10px 22px; }
    .panel-group .panel-heading.collapsed {
      background-color: #fff; }
    .panel-group .panel-heading + .panel-collapse .panel-body {
      border: 0; }
    .panel-group .panel-heading .panel-title {
      width: 100%; }
      .panel-group .panel-heading .panel-title > a {
        color: #455A64;
        font-size: 13px;
        font-weight: normal;
        display: block;
        opacity: 1; }
        .panel-group .panel-heading .panel-title > a:hover {
          color: #455A64 !important; }
          .panel-group .panel-heading .panel-title > a:hover:after {
            color: #455A64 !important; }
        .panel-group .panel-heading .panel-title > a:after {
          font-family: 'FontAwesome';
          content: "\f056";
          position: absolute;
          right: 13px;
          top: 36%;
          color: #455A64; }
        .panel-group .panel-heading .panel-title > a.collapsed {
          color: 1px solid rgba(69, 90, 100, 0.7);
          opacity: 1; }
          .panel-group .panel-heading .panel-title > a.collapsed:after {
            content: "\f055";
            color: rgba(69, 90, 100, 0.7); }
  .panel-group .panel + .panel {
    margin-top: 2px; }
  .panel-group .panel .panel-body {
    height: auto; }

.nav-pills > li > a {
  border-radius: 0;
  color: #455A64; }

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #455A64;
  background-color: #dee1e3; }

@media (max-width: 767px) {
  .nav.nav-tabs.nav-stack-sm li {
    float: none; }
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:after,
  .nav.nav-tabs.nav-stack-sm.nav-tabs-linetriangle > li.active > a:before {
    display: none; } }

/*------------------------------------------------------------------
[12. Sliders]
*/
/* Ion Range Slider
https://github.com/IonDen/ion.rangeSlider
--------------------------------------------------
*/
.irs-line-mid, .irs-line-left, .irs-line-right, .irs-bar, .irs-bar-edge, .irs-slider {
  background-image: none; }

.irs-bar {
  background: #f44336; }

.irs-wrapper .irs-line {
  background-color: #dee1e3; }

.irs-wrapper .irs-line-mid, .irs-wrapper .irs-line-left, .irs-wrapper .irs-line-right, .irs-wrapper .irs-diapason, .irs-wrapper .irs-slider {
  background: none; }

.irs-wrapper .irs-diapason {
  background-color: #f44336;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.irs-wrapper .irs-from, .irs-wrapper .irs-to, .irs-wrapper .irs-single {
  background: #f44336; }

.irs-wrapper .irs-slider.from:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #f44336; }

.irs-wrapper .irs-slider.to:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #f44336; }

.irs-wrapper .irs-slider.single:before {
  top: -2%;
  left: 37%;
  width: 16%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  background-color: #f44336; }

.irs-wrapper .irs-from:after, .irs-wrapper .irs-to:after, .irs-wrapper .irs-single:after {
  border-top-color: #f44336; }

.irs-wrapper.success .irs-diapason {
  background-color: #88c843; }

.irs-wrapper.success .irs-from, .irs-wrapper.success .irs-to, .irs-wrapper.success .irs-single {
  background: #88c843; }

.irs-wrapper.success .irs-slider.from:before {
  background-color: #88c843; }

.irs-wrapper.success .irs-slider.to:before {
  background-color: #88c843; }

.irs-wrapper.success .irs-slider.single:before {
  background-color: #88c843; }

.irs-wrapper.success .irs-from:after, .irs-wrapper.success .irs-to:after, .irs-wrapper.success .irs-single:after {
  border-top-color: #88c843; }

.irs-wrapper.success .irs-bar {
  background-color: #88c843; }

.irs-wrapper.primary .irs-diapason {
  background-color: #2464b4; }

.irs-wrapper.primary .irs-from, .irs-wrapper.primary .irs-to, .irs-wrapper.primary .irs-single {
  background: #2464b4; }

.irs-wrapper.primary .irs-slider.from:before {
  background-color: #2464b4; }

.irs-wrapper.primary .irs-slider.to:before {
  background-color: #2464b4; }

.irs-wrapper.primary .irs-slider.single:before {
  background-color: #2464b4; }

.irs-wrapper.primary .irs-from:after, .irs-wrapper.primary .irs-to:after, .irs-wrapper.primary .irs-single:after {
  border-top-color: #2464b4; }

.irs-wrapper.primary .irs-bar {
  background-color: #2464b4; }

.irs-wrapper.warning .irs-diapason {
  background-color: #FDD835; }

.irs-wrapper.warning .irs-from, .irs-wrapper.warning .irs-to, .irs-wrapper.warning .irs-single {
  background: #FDD835; }

.irs-wrapper.warning .irs-slider.from:before {
  background-color: #FDD835; }

.irs-wrapper.warning .irs-slider.to:before {
  background-color: #FDD835; }

.irs-wrapper.warning .irs-slider.single:before {
  background-color: #FDD835; }

.irs-wrapper.warning .irs-from:after, .irs-wrapper.warning .irs-to:after, .irs-wrapper.warning .irs-single:after {
  border-top-color: #FDD835; }

.irs-wrapper.warning .irs-bar {
  background-color: #FDD835; }

.irs-wrapper.complete .irs-diapason {
  background-color: #4b7ec1; }

.irs-wrapper.complete .irs-from, .irs-wrapper.complete .irs-to, .irs-wrapper.complete .irs-single {
  background: #4b7ec1; }

.irs-wrapper.complete .irs-slider.from:before {
  background-color: #4b7ec1; }

.irs-wrapper.complete .irs-slider.to:before {
  background-color: #4b7ec1; }

.irs-wrapper.complete .irs-slider.single:before {
  background-color: #4b7ec1; }

.irs-wrapper.complete .irs-from:after, .irs-wrapper.complete .irs-to:after, .irs-wrapper.complete .irs-single:after {
  border-top-color: #4b7ec1; }

.irs-wrapper.complete .irs-bar {
  background-color: #4b7ec1; }

.irs-wrapper.danger .irs-diapason {
  background-color: #f44336; }

.irs-wrapper.danger .irs-from, .irs-wrapper.danger .irs-to, .irs-wrapper.danger .irs-single {
  background: #f44336; }

.irs-wrapper.danger .irs-slider.from:before {
  background-color: #f44336; }

.irs-wrapper.danger .irs-slider.to:before {
  background-color: #f44336; }

.irs-wrapper.danger .irs-slider.single:before {
  background-color: #f44336; }

.irs-wrapper.danger .irs-from:after, .irs-wrapper.danger .irs-to:after, .irs-wrapper.danger .irs-single:after {
  border-top-color: #f44336; }

.irs-wrapper.danger .irs-bar {
  background-color: #f44336; }

/* noUiSlider
http://refreshless.com/nouislider/
--------------------------------------------------
*/
.noUi-target {
  border-radius: 0px;
  border: 0;
  box-shadow: none; }
  .noUi-target.bg-complete .noUi-connect {
    background-color: #4b7ec1; }
  .noUi-target.bg-success .noUi-connect {
    background-color: #88c843; }
  .noUi-target.bg-warning .noUi-connect {
    background-color: #FDD835; }
  .noUi-target.bg-danger .noUi-connect {
    background-color: #f44336; }
  .noUi-target.bg-info .noUi-connect {
    background-color: #616161; }
  .noUi-target.bg-primary .noUi-connect {
    background-color: #2464b4; }
  .noUi-target.noUi-connect {
    box-shadow: none; }

.noUi-handle {
  border-radius: 999px;
  box-shadow: none; }
  .noUi-handle:before, .noUi-handle:after {
    display: none; }

.noUi-horizontal {
  height: 4px; }
  .noUi-horizontal .noUi-handle {
    width: 18px;
    height: 18px;
    left: -15px;
    border: 1px solid #d4d9db;
    top: -7px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .noUi-horizontal .noUi-handle:hover {
      border: 1px solid #b5bdc1; }
    .noUi-horizontal .noUi-handle:active {
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      border: 1px solid #d4d9db; }
    .noUi-horizontal .noUi-handle:focus {
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      border: 1px solid #d4d9db; }

.disable-hover-scale .noUi-handle:active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.disable-hover-scale .noUi-handle:focus {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.vertical-slider {
  height: 150px; }

.noUi-vertical {
  width: 4px; }
  .noUi-vertical .noUi-handle {
    width: 18px;
    height: 18px;
    border: 1px solid #d4d9db;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease; }
    .noUi-vertical .noUi-handle:hover {
      border: 1px solid #b5bdc1; }
    .noUi-vertical .noUi-handle:active {
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      border: 1px solid #d4d9db; }
    .noUi-vertical .noUi-handle:focus {
      -webkit-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
      border: 1px solid #d4d9db; }

.noUi-base {
  padding: 5px 0; }

.noUi-origin {
  border-radius: 0px;
  bottom: 5px; }

.noUi-connect {
  box-shadow: none; }

.noUi-background {
  background: #e9ebec;
  box-shadow: none; }

/*------------------------------------------------------------------
[13. Treeview]
*/
span.dynatree-active a {
  color: #232d32 !important;
  background-color: transparent !important; }

span.dynatree-selected a {
  color: #232d32 !important;
  font-style: normal; }

ul.dynatree-container a:focus, span.dynatree-focused a:link {
  background-color: transparent; }

ul.dynatree-container {
  background-color: transparent; }
  ul.dynatree-container a:hover {
    color: #455A64;
    opacity: 0.7;
    background-color: transparent; }

ul.dynatree-container a {
  color: #455A64; }

span.dynatree-empty, span.dynatree-vline, span.dynatree-connector, span.dynatree-expander, span.dynatree-icon, span.dynatree-checkbox, span.dynatree-radio, span.dynatree-drag-helper-img, #dynatree-drop-marker {
  height: 17px;
  position: relative;
  top: 3px; }

/*------------------------------------------------------------------
[14. Nesstables]
*/
.dd-handle {
  border-color: rgba(222, 225, 227, 0.7);
  color: #455A64; }
  .dd-handle:hover {
    background-color: #eef0f1; }

.dark .dd-handle {
  color: #455A64;
  background: #e5e8e9; }
  .dark .dd-handle:hover {
    background-color: #eef0f1; }

.dark .dd-placeholder {
  background-color: #dee1e3; }

.dd3-content {
  background: #fff;
  border-color: rgba(222, 225, 227, 0.7);
  color: #455A64; }
  .dd3-content:hover {
    background-color: #eef0f1; }

.dd3-handle {
  background: transparent;
  text-indent: 9999px; }
  .dd3-handle:before {
    font-family: 'pages-icon';
    color: #455A64;
    content: "\e660";
    font-size: 11px;
    top: 5px; }

.dd-placeholder {
  background: #e5e8e9;
  border-color: rgba(69, 90, 100, 0.35); }

.dd-empty {
  background-image: none;
  background: #e5e8e9;
  border-color: rgba(69, 90, 100, 0.5); }

.dd-item > button {
  font-size: 11px; }
  .dd-item > button:before {
    font-family: 'pages-icon';
    content: "\e63b"; }

.dd-item > button[data-action="collapse"]:before {
  font-family: 'pages-icon';
  content: "\e635"; }

/* Responsive Handlers : Nestables
------------------------------------
*/
@media only screen and (min-width: 700px) {
  .dd {
    width: 100%; } }

/*------------------------------------------------------------------
[15. Form Elements]
*/
.nice-select{
    width:320px;
}

label,
input,
button,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px; }

input[type="radio"],
input[type="checkbox"] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

select[multiple],
select[size] {
  height: auto !important; }

input:focus,
select:focus,
textarea:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

form legend {
  margin: 15px 0px 10px 0px; }

.form-control {
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #232d32;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s; }
  .form-control:focus {
    border-color: rgba(0, 0, 0, 0.1);
    background-color: #e5e8e9;
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .form-control:focus::-moz-placeholder {
      color: inherit;
      opacity: 0.7; }
    .form-control:focus:-ms-input-placeholder {
      color: inherit;
      opacity: 0.7; }
    .form-control:focus::-webkit-input-placeholder {
      color: inherit;
      opacity: 0.7; }
  .form-control::-moz-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control:-ms-input-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.33; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background: #f2f4f4;
    color: rgba(69, 90, 100, 0.23); }

/* Input Sizes
--------------------------------------------------
*/
.input-sm,
.form-horizontal .form-group-sm .form-control {
  font-size: 13px;
  min-height: 32px;
  height: 32px;
  padding: 8px 9px; }

.input-lg,
.form-horizontal .form-group-lg .form-control {
  border-radius: 3px;
  font-size: 18px;
  height: 45px;
  padding: 11px 13px; }

.input-xlg {
  height: 51px;
  font-size: 18px;
  line-height: 22px; }

/* Checkboxes and Radio buttons 
--------------------------------------------------
*/
.radio,
.checkbox {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0px; }
  .radio label,
  .checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px !important;
    margin-right: 15px;
    font-size: 13px; }
    .radio label:before,
    .checkbox label:before {
      content: "";
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-right: 10px;
      position: absolute;
      left: 0px;
      background-color: #fff;
      border: 1px solid #c7ced1; }

.radio label {
  margin-bottom: 6px; }
  .radio label:before {
    bottom: 2.5px;
    border-radius: 99px;
    -webkit-transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33);
    transition: border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33); }

.radio input[type=radio]:checked + label:before {
  border-width: 5px; }

.radio input[type="radio"]:focus + label {
  color: #232d32; }
  .radio input[type="radio"]:focus + label:before {
    background-color: #dee1e3; }

.radio input[type=radio] {
  opacity: 0;
  width: 0;
  height: 0; }

.radio input[type=radio][disabled] + label {
  opacity: 0.65; }

.radio.radio-success input[type=radio]:checked + label:before {
  border-color: #88c843; }

.radio.radio-primary input[type=radio]:checked + label:before {
  border-color: #2464b4; }

.radio.radio-info input[type=radio]:checked + label:before {
  border-color: #616161; }

.radio.radio-warning input[type=radio]:checked + label:before {
  border-color: #FDD835; }

.radio.radio-danger input[type=radio]:checked + label:before {
  border-color: #f44336; }

.radio.radio-complete input[type=radio]:checked + label:before {
  border-color: #4b7ec1; }

.checkbox input[type=radio][disabled] + label:after {
  background-color: #dee1e3; }

.checkbox label {
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap; }
  .checkbox label:before {
    top: 1.4px;
    border-radius: 3px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s; }
  .checkbox label::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 3.2px;
    top: 0px;
    font-size: 11px;
    transition: border 0.2s linear 0s, color 0.2s linear 0s; }
  .checkbox label:after {
    border-radius: 3px; }

.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }

.checkbox.checkbox-circle label:after {
  border-radius: 99px; }

.checkbox.checkbox-circle label:before {
  border-radius: 99px; }

.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px; }

.checkbox input[type=checkbox]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\F00C";
  color: #fff; }

.checkbox input[type="checkbox"]:focus + label {
  color: #232d32; }
  .checkbox input[type="checkbox"]:focus + label:before {
    background-color: #dee1e3; }

.checkbox input[type=checkbox][disabled] + label {
  opacity: 0.65; }
  .checkbox input[type=checkbox][disabled] + label:before {
    background-color: #eceff3; }

.checkbox.right label {
  margin-right: 35px;
  padding-left: 0 !important; }
  .checkbox.right label:before {
    right: -35px;
    left: auto; }

.checkbox.right input[type=checkbox]:checked + label {
  position: relative; }
  .checkbox.right input[type=checkbox]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\F00C";
    position: absolute;
    right: -27px;
    left: auto; }

.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #88c843; }

.checkbox.check-primary input[type=checkbox]:checked + label:before {
  border-color: #2464b4; }

.checkbox.check-complete input[type=checkbox]:checked + label:before {
  border-color: #4b7ec1; }

.checkbox.check-warning input[type=checkbox]:checked + label:before {
  border-color: #FDD835; }

.checkbox.check-danger input[type=checkbox]:checked + label:before {
  border-color: #f44336; }

.checkbox.check-info input[type=checkbox]:checked + label:before {
  border-color: #616161; }

.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #fff; }

.input-group.transparent .input-group-addon {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15); }


.toggleBatchSync {
  position: relative; 
  padding-right: 0 !important;

}

/* Form layouts
--------------------------------------------------
*/
@media only screen and (min-width: 768px) {
  form .row {
    margin-left: 0;
    margin-right: 0; }
  form .row [class*='col-']:not(:first-child), form .row [class*='col-']:not(:last-child) {
    padding-right: 3;
    padding-left: 0; }
  form .row [class*='col-']:first-child {
    padding-left: 0; }
}

@media (min-width: 768px) and (max-width: 991px) {
  form .row [class*="col-md-"]:not(:first-child),
  form .row [class*="col-md-"]:not(:last-child) {
    padding-right: 0;
    padding-left: 0; } }

/* Form layouts  : Horizontal
--------------------------------------------------
*/
.form-horizontal .form-group {
  border-bottom: 1px solid #dee1e3;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 0; }
  .form-horizontal .form-group:last-child {
    border-bottom: none; }
  .form-horizontal .form-group:hover .control-label {
    opacity: .6; }
  .form-horizontal .form-group.focused .control-label {
    opacity: 1; }
  .form-horizontal .form-group .control-label {
    text-align: left;
    opacity: .42;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s; }

/* Form layouts  : Attached
--------------------------------------------------
*/
.form-group-attached .form-group.form-group-default {
  border-radius: 0;
  margin-bottom: 0; }

.form-group-attached > div {
  margin: 0; }
  .form-group-attached > div:first-child.row > [class*='col-']:first-child .form-group-default {
    border-top-left-radius: 2px; }
  .form-group-attached > div:first-child.row > [class*='col-']:last-child .form-group-default {
    border-top-right-radius: 2px; }
  .form-group-attached > div:first-child.form-group-default {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .form-group-attached > div:last-child.row > [class*='col-']:first-child .form-group-default {
    border-bottom-left-radius: 2px; }
  .form-group-attached > div:last-child.row > [class*='col-']:last-child .form-group-default {
    border-bottom-right-radius: 2px; }
  .form-group-attached > div:last-child.form-group-default {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
  .form-group-attached > div.row > [class*='col-'] {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .form-group-attached > div.row > [class*='col-']:not(:only-child):not(:last-child) > .form-group-default {
    border-right-color: transparent; }
  .form-group-attached > div:not(:last-child) .form-group-default, .form-group-attached > div:not(:last-child).form-group-default {
    border-bottom-color: transparent; }

/* Form layouts  : Responsive Handlers
--------------------------------------------------
*/
@media (max-width: 767px) {
  .form-group-attached .form-group-default {
    border-right-color: rgba(0, 0, 0, 0.15) !important; } }

@media only screen and (min-width: 768px) {
  .form-group-attached > div.row > [class*='col-'] .form-group {
    height: 100%;
    width: 100%; } }


/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 2.4em;
  height: 1.3em;
  padding-top: 1em;
  margin-bottom: -5px;
}


.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {

  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: .165em;
  top:0.15em;
  bottom: 0.15em;

  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1em);
  -ms-transform: translateX(1em);
  transform: translateX(1em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1em;
}

.slider.round:before {
  border-radius: 50%;
}


/* Form Groups 
--------------------------------------------------
*/

.form-container {
  position: fixed;
  overflow-y: auto; /* enable vertical scrolling */
}
.form-group {
  margin-bottom: 10px; }
  .form-group label:not(.error) {
    font-family: 'Montserrat'; }
  .form-group label .help {
    margin-left: 8px; }
  .form-group .help {
    font-size: 12px;
    color: rgba(69, 90, 100, 0.55); }

.form-group-default {
  background-color: #fff;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  overflow: hidden;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease; }
  .form-group-default.required:after {
    color: #f44336;
    content: "*";
    font-family: arial;
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 9px; }
  .form-group-default.disabled {
    background: #f2f4f4;
    color: rgba(69, 90, 100, 0.23); }
    .form-group-default.disabled input {
      opacity: .6; }
    .form-group-default.disabled.focused {
      background: #f2f4f4; }
      .form-group-default.disabled.focused label {
        opacity: 1; }
  .form-group-default.focused {
    border-color: rgba(0, 0, 0, 0.1) !important;
    background-color: #e5e8e9; }
    .form-group-default.focused label {
      opacity: .4;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden; }
  .form-group-default.has-error {
    background-color: rgba(244, 67, 54, 0.1); }
  .form-group-default.has-success .form-control-feedback, .form-group-default.has-error .form-control-feedback {
    display: none !important; }
  .form-group-default.has-success .form-control,
  .form-group-default.has-success .form-control:focus,
  .form-group-default.has-error .form-control,
  .form-group-default.has-error .form-control:focus {
    border: none;
    box-shadow: none; }
  .form-group-default.input-group {
    padding: 0; }
    .form-group-default.input-group > label {
      margin-top: 6px;
      padding-left: 12px; }
    .form-group-default.input-group > .form-control {
      margin-top: -2px;
      margin-bottom: 3px;
      padding-left: 12px; }
    .form-group-default.input-group .input-group-addon {
      height: calc(54px - 4px);
      min-width: calc(54px - 4px);
      border-radius: 0;
      border: none; }
    .form-group-default.input-group.focused .input-group-addon {
      border-color: rgba(0, 0, 0, 0.1); }
  .form-group-default .form-control {
    border: none;
    height: 25px;
    min-height: 25px;
    padding: 0;
    margin-top: -4px;
    background: none; }
    .form-group-default .form-control.error {
      color: #232d32; }
    .form-group-default .form-control:focus {
      background: none; }
  .form-group-default textarea.form-control {
    padding-top: 5px; }
  .form-group-default label {
    margin: 0;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease; }
    .form-group-default label.label-lg {
      font-size: 13px;
      left: 13px;
      top: 9px; }
    .form-group-default label.label-sm {
      font-size: 11px;
      left: 11px;
      top: 6px; }
    .form-group-default label.highlight {
      opacity: 1; }
    .form-group-default label.fade {
      opacity: .5;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden; }
  .form-group-default > .input-lg {
    height: 29px;
    min-height: 29px;
    padding-left: 1px; }
  .form-group-default > .input-sm {
    min-height: 18px;
    height: 18px; }
  .form-group-default.form-group-default-select {
    overflow: visible; }
    .form-group-default.form-group-default-select .ui-select-container.ui-select-bootstrap.dropdown .ui-select-match {
      padding-top: 6px;
      height: 23px; }
  .form-group-default.form-group-default-select2 {
    padding: 0; }
    .form-group-default.form-group-default-select2 > label {
      position: absolute;
      z-index: 10;
      padding: 7px 12px 0 12px; }
      .form-group-default.form-group-default-select2 > label.label-lg {
        left: 0;
        top: 0; }
      .form-group-default.form-group-default-select2 > label.label-sm {
        left: 0;
        top: 0; }
    .form-group-default.form-group-default-select2 .select2-container .select2-choice {
      padding-top: 20px;
      height: 52px; }
      .form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-arrow b:before {
        top: 20px; }
      .form-group-default.form-group-default-select2 .select2-container .select2-choice .select2-chosen {
        padding-left: 3px;
        padding-top: 1px; }
    .form-group-default.form-group-default-select2 .select2-container .select2-choices {
      padding-top: 20px;
      height: 52px;
      border: 0px; }
    .form-group-default.form-group-default-select2 > .input-lg {
      height: auto;
      padding: 0; }
      .form-group-default.form-group-default-select2 > .input-lg .select2-choice {
        padding-top: 20px;
        height: 56px; }
    .form-group-default.form-group-default-select2 > .input-sm {
      height: auto;
      padding: 0; }
      .form-group-default.form-group-default-select2 > .input-sm .select2-choice {
        padding-top: 20px;
        height: 46px; }
  .form-group-default.form-group-default-selectFx {
    padding: 0; }
    .form-group-default.form-group-default-selectFx > label {
      position: absolute;
      z-index: 10;
      padding: 7px 12px 0 12px; }
      .form-group-default.form-group-default-selectFx > label.label-lg {
        left: 0;
        top: 0; }
      .form-group-default.form-group-default-selectFx > label.label-sm {
        left: 0;
        top: 0; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .cs-placeholder {
      padding-top: 28px;
      height: 52px;
      padding-left: 12px; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select {
      height: auto; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select > span:after,
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select .cs-selected span:after {
        top: 39px; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-lg .cs-placeholder {
        height: 60px; }
      .form-group-default.form-group-default-selectFx .cs-wrapper .cs-select.input-sm .cs-placeholder {
        height: 50px; }
    .form-group-default.form-group-default-selectFx .cs-wrapper .dropdown-placeholder {
      vertical-align: top; }

/* Form validation
--------------------------------------------------
*/
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #6ea236; }

.has-success .form-control {
  border-color: #6ea236;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-success .form-control:focus {
    border-color: #547c29;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-success .input-group-addon {
  background: #e5e8e9;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(69, 90, 100, 0.47); }

.has-success .form-control-feedback {
  color: #6ea236; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #cdaf2b; }

.has-warning .form-control {
  border-color: #cdaf2b;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-warning .form-control:focus {
    border-color: #a38b22;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-warning .input-group-addon {
  background: #e5e8e9;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(69, 90, 100, 0.47); }

.has-warning .form-control-feedback {
  color: #cdaf2b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #f44336; }

.has-error .form-control {
  border-color: #f44336;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .has-error .form-control:focus {
    border-color: #ea1c0d;
    -webkit-box-shadow: none;
    box-shadow: none; }

.has-error .input-group-addon {
  background: #e5e8e9;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(69, 90, 100, 0.47); }

.has-error .form-control-feedback {
  color: #f44336; }

.error {
  font-size: 12px;
  color: #f44336;
  display: block; }

/* Addon inputs
--------------------------------------------------
*/
.input-group-addon {
  background: #e5e8e9;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(69, 90, 100, 0.47);
  font-size: 14px;
  padding: 6px 9px;
  display: table-cell;
  border-radius: 24px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s, background 0.2s linear 0s; }
  .input-group-addon i {
    position: relative;
    top: 1px; }
  .input-group-addon.primary {
    background-color: #2464b4;
    border: 1px solid #2464b4;
    color: #fff;
    background-color: #2464b4;
    border: 1px solid #2464b4;
    color: #fff; }
    .input-group-addon.primary .arrow {
      color: #2464b4; }
  .input-group-addon.success {
    background-color: #0090d9;
    color: #fff; }
    .input-group-addon.success .arrow {
      color: #0090d9; }
  .input-group-addon.info {
    background-color: #1f3853;
    color: #fff; }
    .input-group-addon.info .arrow {
      color: #1f3853; }
  .input-group-addon.warning {
    background-color: #fbb05e;
    color: #fff;
    background-color: #fbb05e;
    color: #fff; }
    .input-group-addon.warning .arrow {
      color: #fbb05e; }
  .input-group-addon.danger {
    background-color: #f35958;
    color: #fff; }
    .input-group-addon.danger .arrow {
      color: #f35958; }
  .input-group-addon .arrow {
    position: relative;
    right: -6px;
    color: #D1DADE;
    z-index: 100; }
    .input-group-addon .arrow:before {
      font-family: 'FontAwesome';
      content: "\f0da";
      font-size: 23px;
      position: absolute;
      left: 17px;
      top: -2px; }
  .input-group-addon:last-child .arrow:before {
    font-family: 'FontAwesome';
    content: "\f0d9";
    font-size: 23px;
    position: absolute;
    left: -23px;
    top: -2px; }
  .input-group-addon:last-child input {
    border-left: 0px; }

/* Plugins
--------------------------------------------------
Datepicker
https://github.com/eternicode/bootstrap-datepicker
*/
.datepicker {
  padding: 16px 25px;
  border-radius: 2px;
  font-size: 12px; }
  .datepicker:after {
    border-bottom-color: #eef0f1; }
  .datepicker thead tr .datepicker-switch {
    color: #6f7b8a;
    font-size: 13px; }
  .datepicker thead tr .next,
  .datepicker thead tr .prev {
    color: #2464b4;
    content: '';
    font-size: 0px; }
    .datepicker thead tr .next:before,
    .datepicker thead tr .prev:before {
      color: #2464b4;
      font-family: 'FontAwesome';
      font-size: 10px; }
  .datepicker thead tr .prev:before {
    content: "\f053"; }
  .datepicker thead tr .next:before {
    content: "\f054"; }
  .datepicker thead tr .dow {
    font-family: 'Montserrat';
    color: #2464b4;
    text-transform: uppercase;
    font-size: 11px; }
  .datepicker thead tr th {
    width: 31px;
    height: 29px; }
  .datepicker tbody tr .odd {
    color: #d0d3d8; }
  .datepicker table tr td {
    width: 31px;
    height: 29px; }
    .datepicker table tr td.old, .datepicker table tr td.new {
      color: #dee1e3; }
    .datepicker table tr td.day:hover {
      background: #e5e8e9; }
    .datepicker table tr td.active {
      background-color: #2464b4 !important; }
      .datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
        background-image: none;
        text-shadow: none;
        font-weight: 600; }
    .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
      background-color: #e5e8e9;
      background-image: none;
      color: #fff; }
    .datepicker table tr td span {
      border-radius: 4px;
      width: 42px;
      height: 42px;
      line-height: 42px; }
      .datepicker table tr td span.active {
        background-color: #2464b4 !important; }
      .datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
        background-image: none;
        border: none;
        text-shadow: none; }
  .datepicker.dropdown-menu {
    border-color: #dee1e3;
    color: #455A64; }
  .datepicker.datepicker-dropdown.datepicker-orient-bottom:before {
    border-color: #dee1e3; }

.datepicker-inline {
  width: auto; }

.input-daterange .input-group-addon {
  text-shadow: none;
  border: 0; }

/* Timepicker 
https://github.com/m3wolf/bootstrap3-timepicker
*/
.bootstrap-timepicker-widget table td a i {
  font-size: 12px; }

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget .bootstrap-timepicker-widget input {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px; }

.bootstrap-timepicker-widget.dropdown-menu {
  background: #fff; }

/* Daterangepicker
http://www.dangrossman.info/
*/
.daterangepicker {
  background: #fff; }
  .daterangepicker .calendar .calendar-date {
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .daterangepicker .calendar .prev, .daterangepicker .calendar .next, .daterangepicker .calendar th {
    color: #2464b4;
    text-transform: uppercase;
    font-size: 11px; }
  .daterangepicker .calendar .month {
    color: #6f7b8a;
    font-size: 13px; }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #2464b4;
    border-color: #2464b4; }
  .daterangepicker td.in-range {
    background: #d3e0f0; }

/* Select2
http://ivaynberg.github.io/select2/
*/
.form-group-default .select2-container .select2-choice,
.select2-container-multi .select2-choices {
  border-color: transparent; }

.select2-container .select2-choice {
  background-image: none;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 3px 9px;
  transition: border 0.2s linear 0s;
  height: 35px; }
  .select2-container .select2-choice .select2-arrow {
    background: transparent;
    border-left: 0px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .select2-container .select2-choice .select2-arrow b:before {
      font-family: 'FontAwesome';
      content: "\f0d7";
      position: relative;
      top: 2px;
      right: 5px;
      font-size: 12px; }

.select2-container.select2-drop-above .select2-choice {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

.select2-search input,
.select2-search-choice-close,
.select2-container .select2-choice abbr,
.select2-container .select2-choice .select2-arrow b {
  background-image: none !important; }

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  background-image: none !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #e5e8e9; }

.select2-dropdown-open .select2-choice .select2-arrow {
  -webkit-transform: scale(scale(1, -1));
  -ms-transform: scale(scale(1, -1));
  transform: scale(scale(1, -1)); }

.select2-drop.select2-drop-above {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: none; }
  .select2-drop.select2-drop-above.select2-drop-active {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: none;
    border-radius: 2px;
    padding-top: 0px; }

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #e5e8e9;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.select2-search {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px; }
  .select2-search input {
    background: #fff !important;
    vertical-align: baseline;
    line-height: 28px;
    border-radius: 2px;
    border: none;
    font-size: 12px;
    border: 1px solid rgba(0, 0, 0, 0.15); }

.select2-results {
  margin: 3px 10px 10px 3px; }
  .select2-results li {
    color: #455A64; }
    .select2-results li.select2-result-with-children > .select2-result-label {
      color: #232d32; }
      .select2-results li.select2-result-with-children > .select2-result-label:first-child {
        padding-top: 8px; }
  .select2-results .select2-highlighted {
    background: #e5e8e9;
    border-radius: 3px; }
    .select2-results .select2-highlighted .select2-result-label {
      color: #455A64; }
  .select2-results .select2-no-results,
  .select2-results .select2-searching,
  .select2-results .select2-selection-limit {
    background: none;
    color: #455A64;
    font-size: 12px;
    padding-left: 8px;
    padding-top: 0px;
    position: relative;
    top: -5px; }

.select2-drop-active {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding-top: 5px;
  z-index: 790; }

.select2-container-multi .select2-choices {
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px; }
  .select2-container-multi .select2-choices .select2-search-choice {
    background-color: #dee1e3;
    background-image: none;
    border: none;
    box-shadow: none;
    color: inherit;
    border-radius: 8px;
    margin: 8px -5px 7px 10px;
    padding: 4px 8px 4px 21px; }

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none; }

.select2-container-multi.select2-container-active .select2-search-choice {
  background-color: #fff !important; }

.select2-container-multi .select2-search-choice-close {
  left: 6px; }

.select2-search-choice-close {
  background: none;
  top: 4px;
  right: 0; }
  .select2-search-choice-close:hover {
    text-decoration: none; }
  .select2-search-choice-close:before {
    font-family: 'FontAwesome';
    content: "\f00d";
    font-size: 12px;
    color: #455A64; }

.select2-drop-multi .select2-results .select2-no-results,
.select2-drop-multi .select2-results .select2-searching,
.select2-drop-multi .select2-results .select2-selection-limit {
  top: 0px; }

.select2.form-control {
  padding: 0;
  box-shadow: none;
  border: 0; }

.select2-drop-mask {
  z-index: 700; }

.ui-select-bootstrap .ui-select-choices-row.active > a {
  background: #e5e8e9;
  border-radius: 3px;
  color: #455A64; }

.ui-select-bootstrap > .ui-select-choices {
  background-color: #fff; }

.ui-select-choices-group-label {
  color: #232d32;
  font-weight: bold; }

.modal-open .select2-drop-active {
  z-index: 1051; }

.modal-open .datepicker.dropdown-menu {
  z-index: 1051 !important; }

.modal-open .select2-drop-mask {
  z-index: 1050; }

.modal-open .cs-skin-slide.cs-active {
  z-index: 1050; }

.dropdown-placeholder {
  display: inline-block;
  vertical-align: middle; }

.dropdown-mask {
  bottom: 0;
  display: none;
  left: 0;
  outline: 0 none;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 600; }

/* Bootstrap Tags input
https://github.com/timschlechter/bootstrap-tagsinput
*/
.form-group-default .bootstrap-tagsinput {
  border: 0px;
  padding-left: 0; }

.bootstrap-tagsinput {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-bottom: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s; }
  .bootstrap-tagsinput.active-element {
    background-color: #dee1e3; }
  .bootstrap-tagsinput input {
    border: none;
    margin-bottom: 0px;
    min-height: 25px;
    min-width: 10em !important; }
  .bootstrap-tagsinput .tag {
    vertical-align: middle;
    padding: 6px 9px;
    padding-right: 6px;
    border-radius: 3px;
    line-height: 30px; }
  .bootstrap-tagsinput .tag[data-role="remove"] {
    margin-left: 4px; }
  .bootstrap-tagsinput .tag[data-role="remove"]:hover:active,
  .bootstrap-tagsinput .tag [data-role="remove"]:hover {
    box-shadow: none; }
  .bootstrap-tagsinput .tag [data-role="remove"]:after {
    font-family: 'pages-icon';
    content: "\e60a";
    padding: 0; }

/* Bootstrap3 wysihtml5
https://github.com/Waxolunist/bootstrap3-wysihtml5-bower
*/
.wysiwyg5-wrapper {
  position: relative; }
  .wysiwyg5-wrapper .wysihtml5-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /*height: 50px;*/
    background: #e5e8e9;
    border-top: 1px solid #dee1e3; }
    .wysiwyg5-wrapper .wysihtml5-toolbar .btn {
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
      border-color: transparent;
      border-right-color: #dee1e3;
      color: #A5A5A5;
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      padding: 0 5px;
      border-width: 1px;
      border-radius: 0 !important;
      box-shadow: none !important; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn:hover .editor-icon {
        opacity: .8; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn.active .editor-icon {
        opacity: 1; }
      .wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle {
        padding-left: 10px;
        padding-right: 20px; }
        .wysiwyg5-wrapper .wysihtml5-toolbar .btn.dropdown-toggle .current-font {
          opacity: .5;
          font-size: 14px; }
    .wysiwyg5-wrapper .wysihtml5-toolbar > li {
      margin: 0;
      padding: 0; }
  .wysiwyg5-wrapper .wysiwyg {
    width: 100%;
    min-height: 200px;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 50px !important;
    border: 0; }
    .wysiwyg5-wrapper .wysiwyg:focus {
      background-color: #eef0f1;
      outline: 0 !important;
      -webkit-box-shadow: none;
      box-shadow: none; }
  .wysiwyg5-wrapper .expand-wysiwyg {
    bottom: 0;
    color: #A5A5A5;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    position: absolute;
    right: 0; }
  .wysiwyg5-wrapper .wysihtml5-sandbox {
    border: none !important;
    padding: 16px 16px 50px !important;
    width: 100% !important; }
    .wysiwyg5-wrapper .wysihtml5-sandbox.expanded {
      height: 100% !important; }

.toggle-wysiwyg {
  position: absolute;
  right: 15px;
  top: 0; }
  .toggle-wysiwyg li {
    display: inline-block;
    font-weight: 600; }

.editor-icon {
  background-image: url("../img/editor_tray.png");
  display: inline-block;
  height: 40px;
  margin-top: 5px;
  opacity: 0.4;
  vertical-align: top;
  width: 40px; }
  .editor-icon-headline {
    background-position: 0 0;
    width: 31px; }
  .editor-icon-bold {
    background-position: -40px 0; }
  .editor-icon-italic {
    background-position: -80px 0; }
  .editor-icon-underline {
    background-position: -120px 0; }
  .editor-icon-link {
    background-position: -160px 0; }
  .editor-icon-quote {
    background-position: -200px 0; }
  .editor-icon-ul {
    background-position: -240px 0; }
  .editor-icon-ol {
    background-position: -280px 0; }
  .editor-icon-outdent {
    background-position: -320px 0; }
  .editor-icon-indent {
    background-position: -360px 0; }
  .editor-icon-image {
    background-position: -400px 0; }
  .editor-icon-html {
    background-position: -440px 0; }

/* Summernote
https://github.com/HackerWins/summernote
*/
.summernote-wrapper .note-editor {
  border-color: #dee1e3; }
  .summernote-wrapper .note-editor .note-toolbar {
    padding: 0;
    background-color: #e5e8e9;
    border-bottom: none; }
    .summernote-wrapper .note-editor .note-toolbar .btn-group {
      margin: 0 -1px 0 0; }
      .summernote-wrapper .note-editor .note-toolbar .btn-group .btn {
        font-size: 12px;
        font-weight: 600;
        height: 50px;
        min-width: 47px;
        line-height: 50px;
        padding: 0 5px;
        border-radius: 0;
        background-color: #e5e8e9;
        border-color: transparent;
        border-right-color: #dee1e3;
        border-bottom-color: #dee1e3;
        color: #455A64; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn.active, .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:active {
          background-color: #dee1e3; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn.dropdown-toggle {
          min-width: 61px; }
        .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:last-child), .summernote-wrapper .note-editor .note-toolbar .btn-group .btn:not(:only-child) {
          margin-right: 1px; }
  .summernote-wrapper .note-editor .note-statusbar {
    background-color: transparent; }
    .summernote-wrapper .note-editor .note-statusbar .note-resizebar {
      border-top-color: transparent; }
      .summernote-wrapper .note-editor .note-statusbar .note-resizebar .note-icon-bar {
        border-top: 1px solid #dee1e3; }

.summernote-wrapper .note-popover .popover .popover-content .dropdown-menu li a i, .summernote-wrapper .note-toolbar .dropdown-menu li a i {
  color: #2464b4; }

input, input:focus {
  -webkit-transition: none !important; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important; }

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #e5e8e9 inset !important; }

input.error:-webkit-autofill,
input.error:-webkit-autofill:focus,
.has-error input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #F9E9E9 inset !important; }

/* Pages SelectFx */
/* Default custom select styles */
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: #fff;
  width: 100%;
  max-width: 500px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */ }

.cs-select select {
  display: none; }

.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* Placeholder and selected option */
.cs-select > span {
  padding-right: 3em; }

.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.cs-select > span::after {
  content: '\25BE';
  right: 1em; }

.cs-select .cs-selected span::after {
  content: '\2713';
  margin-left: 1em; }

.cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg); }

/* Options */
.cs-select .cs-options {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;
  visibility: hidden; }

.cs-select.cs-active .cs-options {
  visibility: visible; }

.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%; }

.cs-select ul span {
  padding: 1em; }

.cs-select ul li.cs-focus span {
  background-color: #ddd; }

/* Optgroup and optgroup label */
.cs-select li.cs-optgroup ul {
  padding-left: 1em; }

.cs-select li.cs-optgroup > span {
  cursor: default; }

div.cs-skin-slide {
  color: #fff;
  /*font-size: 1.5em;*/
  width: 300px; }

@media screen and (max-width: 30em) {
  div.cs-skin-slide {
    font-size: 1em;
    width: 250px; } }

div.cs-skin-slide::before {
  content: '';
  background: #282b30;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s; }

.cs-skin-slide.cs-active::before {
  -webkit-transform: scale3d(1.1, 3.5, 1);
  transform: scale3d(1.1, 3.5, 1); }

.cs-skin-slide > span {
  height: 80px;
  line-height: 32px;
  -webkit-transition: text-indent 0.3s, opacity 0.3s;
  transition: text-indent 0.3s, opacity 0.3s; }

@media screen and (max-width: 30em) {
  .cs-skin-slide > span {
    height: 60px;
    line-height: 28px; } }

.cs-skin-slide.cs-active > span {
  text-indent: -290px;
  opacity: 0; }

.cs-skin-slide.cs-active > span::after {
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.cs-skin-slide .cs-options {
  background: transparent;
  width: 70%;
  height: 400%;
  padding: 1.9em 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0); }

@media screen and (max-width: 30em) {
  .cs-skin-slide .cs-options {
    padding-top: 3em; } }

.cs-skin-slide .cs-options li {
  opacity: 0;
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s; }

.cs-skin-slide.cs-active .cs-options li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.cs-skin-slide.cs-active .cs-options li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(3) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(4) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(5) {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s; }

/* more options need more delay declaration */
.cs-skin-slide .cs-options li span {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 65%;
  padding: 0.8em 1em 0.8em 2.5em; }

.cs-skin-slide .cs-options li span:hover,
.cs-skin-slide .cs-options li.cs-focus span,
.cs-skin-slide .cs-options li.cs-selected span {
  color: #eb7e7f;
  background: transparent; }

.cs-skin-slide .cs-selected span::after {
  content: ''; }

/* Pages Select  overriding */
.form-group-default .cs-skin-slide > span {
  padding: 0 30px 0 0;
  height: 22px;
  line-height: 21px; }

.form-group-default .cs-wrapper {
  width: 100%; }

.cs-wrapper {
  display: inline-block; }

.form-control.cs-select:not(.cs-active) {
  width: 100% !important; }

.cs-select {
  background-color: transparent; }
  .cs-select span {
    text-overflow: initial; }
  .cs-select .cs-placeholder {
    width: 100%; }

div.cs-skin-slide {
  width: auto;
  font-family: Arial,sans-serif;
  color: #5e5e5e; }
  div.cs-skin-slide:before {
    background-color: transparent; }
  div.cs-skin-slide.cs-transparent {
    background: none; }
    div.cs-skin-slide.cs-transparent .cs-backdrop {
      border-color: transparent;
      background: none; }
    div.cs-skin-slide.cs-transparent.cs-active .cs-backdrop {
      background: #eef0f1; }
  div.cs-skin-slide > span {
    height: 35px;
    padding: 6px 33px 6px 17px;
    line-height: 23px;
    z-index: 1; }
  div.cs-skin-slide.cs-active {
    z-index: 790; }
    div.cs-skin-slide.cs-active:before {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1); }
    div.cs-skin-slide.cs-active .cs-backdrop {
      border: transparent;
      background: #eef0f1;
      box-shadow: -1px 0 1px #ccc, 1px 0 1px #ccc; }
  div.cs-skin-slide > span:after,
  div.cs-skin-slide.cs-active > span:after {
    content: "\f0d7";
    font-family: FontAwesome;
    color: #5e5e5e; }
  div.cs-skin-slide .cs-options {
    height: auto;
    padding: 9px 0;
    width: auto;
    padding: 10px;
    max-height: 350px;
    transform: translate3d(1, 1, 1);
    overflow: hidden;
    z-index: 1; }
    div.cs-skin-slide .cs-options ul {
      width: 100%;
      display: table; }
      div.cs-skin-slide .cs-options ul li {
        display: table-row; }
        div.cs-skin-slide .cs-options ul li span {
          display: table-cell;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: normal;
          padding: 5px 0;
          text-transform: none;
          max-height: 350px;
          overflow-y: auto; }
        div.cs-skin-slide .cs-options ul li span:hover,
        div.cs-skin-slide .cs-options ul li.cs-focus span,
        div.cs-skin-slide .cs-options ul li.cs-selected span {
          color: #232d32; }

.cs-backdrop {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.cs-skin-slide.cs-active .cs-options li:nth-child(6) {
  transition-delay: 0.3s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(7) {
  transition-delay: 0.35s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(8) {
  transition-delay: 0.4s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(9) {
  transition-delay: 0.45s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(10) {
  transition-delay: 0.5s; }

.cs-skin-slide.cs-active .cs-options li:nth-child(11) {
  transition-delay: 0.55s; }

/* Dropzone for Angular */
.dropzone[dropzone="dropzone"] input[type="file"] {
  visibility: hidden; }

/* end overrides */
.twitter-typeahead {
  width: 100%; }

.tt-menu {
  width: 100%;
  padding: 3px 10px 3px 3px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-top: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15); }

.tt-suggestion {
  color: #455A64;
  padding: 3px 7px 4px;
  padding-left: 26px;
  line-height: 24px; }
  .tt-suggestion:hover {
    cursor: pointer;
    background: #e5e8e9; }
  .tt-suggestion.tt-cursor {
    background: #e5e8e9; }
  .tt-suggestion p {
    margin: 0; }

.form-group-default.typehead {
  overflow: inherit; }

.form-group-default .tt-menu {
  width: calc(100% + 24px);
  margin: 4px -12px; }

/* Demo specifc classes */
.demo-form-wysiwyg {
  height: 250px; }

.row .control-label {
  margin-bottom: 0px;
  padding-top: 7px; }

/*------------------------------------------------------------------
[16. Tables and Datatables]
*/
/* Generic Tables 
------------------------------------
*/
.table {
  margin-top: 5px; }
  .table thead tr th {
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 13px;
    padding-top: 14px;
    padding-bottom: 14px;
    vertical-align: middle;
    border-bottom: 1px solid rgba(222, 225, 227, 0.7);
    color: rgba(35, 45, 50, 0.35); }
    .table thead tr th[class*='sorting_'] {
      color: #232d32; }
    .table thead tr th:first-child {
      padding-left: 18px !important; }
    .table thead tr th .btn {
      margin-top: -20px;
      margin-bottom: -20px; }
  .table tbody tr td {
    background: #fff;
    border-bottom: 1px solid rgba(222, 225, 227, 0.7);
    border-top: 0px;
    padding: 20px;
    font-size: 13.5px; }
    .table tbody tr td .btn-tag {
      background: rgba(35, 45, 50, 0.07);
      display: inline-block;
      margin: 5px;
      border-radius: 4px;
      padding: 5px;
      color: #62605a !important; }
      .table tbody tr td .btn-tag:hover {
        background: rgba(35, 45, 50, 0.15); }
    .table tbody tr td[class*='sorting_'] {
      color: #000; }
  .table tbody tr.selected td {
    background: #fff7d7; }
  .table.table-hover tbody tr:hover td {
    background: #dbe5f3 !important; }
  .table.table-hover tbody tr.selected:hover td {
    background: #fff7d7 !important; }
  .table.table-striped tbody tr td {
    background: #eef0f1 !important; }
  .table.table-striped tbody tr:nth-child(2n+1) td {
    background: #fff !important; }
  .table.table-borderless tbody tr td {
    border-top: 0; }
  .table.table-condensed {
    table-layout: fixed; }
    .table.table-condensed thead tr th {
      padding-left: 20px;
      padding-right: 20px; }
    .table.table-condensed tbody tr td {
      padding-top: 12px;
      padding-bottom: 12px; }
    .table.table-condensed thead tr th, .table.table-condensed tbody tr td, .table.table-condensed tbody tr td * {
      white-space: nowrap;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis; }
    .table.table-condensed.table-detailed > tbody > tr.shown > td {
      background: #fff7d7; }
      .table.table-condensed.table-detailed > tbody > tr.shown > td:first-child:before {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg); }
    .table.table-condensed.table-detailed > tbody > tr.shown + tr > td {
      background: rgba(238, 240, 241, 0.4);
      padding: 0px 40px; }
      .table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline {
        background: transparent; }
        .table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline tr, .table.table-condensed.table-detailed > tbody > tr.shown + tr > td .table-inline td {
          background: transparent;
          font-weight: 600; }
    .table.table-condensed.table-detailed > tbody > tr.row-details > td:first-child:before {
      content: ''; }
    .table.table-condensed.table-detailed > tbody > tr > td:hover {
      cursor: pointer; }
    .table.table-condensed.table-detailed > tbody > tr > td:first-child:before {
      content: "\f054";
      display: inline-block;
      margin-right: 8px;
      font-family: 'FontAwesome';
      -webkit-transition: all 0.12s linear;
      transition: all 0.12s linear; }
    .table.table-condensed.table-detailed .table-inline td {
      border: none;
      text-align: left; }
  .table.table-borderless > tbody > tr > td {
    border-bottom: 0px; }

.fht-table {
  margin-bottom: 0 !important; }

/* Data-tables 
------------------------------------
*/
.table.dataTable.no-footer {
  border: none; }

.dataTables_scroll:hover .dataTables_scrollBody:before {
  content: "";
  top: 0;
  height: 0; }

.dataTables_scrollBody {
  overflow-y: auto;
  border: none !important; }
  .dataTables_scrollBody:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    background: transparent; }

.dataTables_wrapper .dataTables_paginate {
  float: right; }

.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: .5; }

.dataTables_wrapper .dataTables_paginate ul > li > a {
  padding: 5px 10px;
  color: #455A64;
  opacity: .35;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease; }
  .dataTables_wrapper .dataTables_paginate ul > li > a:hover {
    opacity: .65; }

.dataTables_wrapper .dataTables_paginate ul > li.next > a,
.dataTables_wrapper .dataTables_paginate ul > li.prev > a {
  opacity: 1; }

.dataTables_wrapper .dataTables_paginate ul > li.disabled a {
  opacity: .35; }
  .dataTables_wrapper .dataTables_paginate ul > li.disabled a:hover {
    opacity: .35; }

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  margin-top: 25px !important; }

.dataTables_paginate.paging_bootstrap.pagination {
  padding-top: 0;
  padding-right: 20px; }

.dataTables_wrapper .dataTables_info {
  clear: none;
  font-size: 12px;
  padding: 0 33px;
  color: #455A64; }

.dataTables_wrapper .dataTables_paginate ul > li {
  display: inline-block;
  padding-left: 0;
  font-size: 11px; }

.dataTables_scrollHeadInner {
  padding-right: 0 !important; }

.export-options-container {
  position: relative; }

.dataTables_wrapper .dataTables_paginate ul > li.active > a {
  font-weight: bold;
  color: #455A64;
  opacity: 1; }

.export-options-container a {
  color: inherit;
  opacity: 1; }

.exportOptions .DTTT.btn-group a {
  display: block !important; }

table.dataTable thead .sorting_asc:after {
  background-image: url("../img/icons/sort_asc.png"); }

table.dataTable thead .sorting_desc:after {
  background-image: url("../img/icons/sort_desc.png"); }

table.dataTable thead .sorting:after {
  background-image: url("../img/icons/sort_both.png"); }

table.dataTable thead .sorting_asc_disabled:after {
  background-image: url("../img/icons/sort_asc_disabled.png"); }

table.dataTable thead .sorting_desc_disabled:after {
  background-image: url("../img/icons/sort_desc_disabled.png"); }

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px;
  content: "";
  width: 19px;
  height: 19px;
  background-position: center center;
  opacity: 1; }

/* Responsive Handlers : Tables */
@media (max-width: 991px) {
  .dataTables_wrapper .dataTables_info {
    float: left; }
  .dataTables_paginate.paging_bootstrap.pagination {
    float: right; } }

@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_info,
  .dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: left;
    clear: both;
    display: block; } }

/* Demo classes */
.demo-table-search thead th:nth-child(1) {
  width: 20%; }

.demo-table-search thead th:nth-child(2) {
  width: 22%; }

.demo-table-search thead th:nth-child(3) {
  width: 24%; }

.demo-table-search thead th:nth-child(4) {
  width: 15%; }

.demo-table-search thead th:nth-child(5) {
  width: 19%; }

.demo-table-dynamic thead th:nth-child(1) {
  width: 25%; }

.demo-table-dynamic thead th:nth-child(2) {
  width: 30%; }

.demo-table-dynamic thead th:nth-child(3) {
  width: 20%; }

.demo-table-dynamic thead th:nth-child(4) {
  width: 25%; }

.table tbody tr td.b-b-0 {
  border-bottom: 0px; }

/*------------------------------------------------------------------
[17. Charts]
*/
.line-chart .nvd3 line.nv-guideline {
  /* Vertical bar on hover in interactive chart */
  stroke-width: 30px;
  stroke-opacity: .04;
  stroke: #000; }

.line-chart .nvd3 .nv-groups path.nv-area {
  /*  filled area */
  fill-opacity: .1; }

.line-chart .nvd3 .nv-groups path.nv-line {
  /*  Line */
  stroke-opacity: .3; }

.line-chart .nvd3 .nv-axis line {
  /*  grid lines */
  stroke-opacity: .5; }

.line-chart[data-x-grid="false"] .nv-x .tick line {
  display: none; }

.line-chart[data-y-grid="false"] .nv-y .tick line {
  display: none; }

.line-chart .domain {
  /* domain */
  opacity: 0; }

.line-chart[data-points="true"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  /* Toggle points */
  fill-opacity: 1;
  stroke-opacity: .5; }

.line-chart .nvtooltip table td.legend-color-guide div {
  border-radius: 100px; }

.line-chart thead .nv-pointer-events-none strong {
  color: #90a7b2; }

.line-chart .nv-pointer-events-none {
  font-size: 12px; }
  .line-chart .nv-pointer-events-none .value.nv-pointer-events-none {
    font-family: 'Montserrat';
    font-weight: normal;
    font-size: 11px;
    color: #90a7b2; }

.line-chart .nvtooltip table {
  margin: 12px 10px 14px 15px; }

.line-chart .nvtooltip.xy-tooltip.nv-pointer-events-none {
  border-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1); }

.line-chart[data-stroke-width="1"] .nvd3 .nv-groups path.nv-line {
  /* Line widths */
  stroke-width: 1px; }

.line-chart[data-stroke-width="1"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  /* Line widths */
  stroke-width: 2px; }

.line-chart[data-stroke-width="2"] .nvd3 .nv-groups path.nv-line {
  stroke-width: 2px; }

.line-chart[data-stroke-width="2"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke-width: 3px; }

.line-chart[data-stroke-width="3"] .nvd3 .nv-groups path.nv-line {
  stroke-width: 3px; }

.line-chart[data-stroke-width="3"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke-width: 4px; }

.line-chart .tick text, .line-chart .nvd3 .nv-axis .nv-axisMaxMin text {
  fill: #455a64;
  font-family: "Montserrat";
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal; }

.line-chart .nvd3.nv-scatter .nv-groups .nv-point.hover,
.line-chart .nvd3 .nv-groups .nv-point.hover {
  fill: inherit !important;
  stroke: inherit !important; }

/* Line widths
------------------------------------
*/
.line-chart[data-line-color="master"] .nvd3 line.nv-guideline, .line-chart[data-line-color="master"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="master"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #455A64; }

.line-chart[data-line-color="success"] .nvd3 line.nv-guideline, .line-chart[data-line-color="success"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="success"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #88c843; }

.line-chart[data-line-color="primary"] .nvd3 line.nv-guideline, .line-chart[data-line-color="primary"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="primary"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #2464b4; }

.line-chart[data-line-color="info"] .nvd3 line.nv-guideline, .line-chart[data-line-color="info"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="info"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #616161; }

.line-chart[data-line-color="complete"] .nvd3 line.nv-guideline, .line-chart[data-line-color="complete"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="complete"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #4b7ec1; }

.line-chart[data-line-color="warning"] .nvd3 line.nv-guideline, .line-chart[data-line-color="warning"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="warning"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #FDD835; }

.line-chart[data-line-color="danger"] .nvd3 line.nv-guideline, .line-chart[data-line-color="danger"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="danger"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #f44336; }

.line-chart[data-line-color="white"] .nvd3 line.nv-guideline, .line-chart[data-line-color="white"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="white"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #fff; }

.line-chart[data-line-color="black"] .nvd3 line.nv-guideline, .line-chart[data-line-color="black"]
.nvd3 .nv-groups path.nv-line, .line-chart[data-line-color="black"]
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  stroke: #000; }

/* Area Fill colors
------------------------------------
*/
.line-chart[data-area-color="master"] .nvd3 .nv-groups path.nv-area {
  fill: #455A64; }

.line-chart[data-area-color="success"] .nvd3 .nv-groups path.nv-area {
  fill: #88c843; }

.line-chart[data-area-color="info"] .nvd3 .nv-groups path.nv-area {
  fill: #616161; }

.line-chart[data-area-color="complete"] .nvd3 .nv-groups path.nv-area {
  fill: #4b7ec1; }

.line-chart[data-area-color="primary"] .nvd3 .nv-groups path.nv-area {
  fill: #2464b4; }

.line-chart[data-area-color="warning"] .nvd3 .nv-groups path.nv-area {
  fill: #FDD835; }

.line-chart[data-area-color="danger"] .nvd3 .nv-groups path.nv-area {
  fill: #f44336; }

.line-chart[data-area-color="white"] .nvd3 .nv-groups path.nv-area {
  fill: #fff; }

.line-chart[data-area-color="black"] .nvd3 .nv-groups path.nv-area {
  fill: #000; }

/* Point fill colors
------------------------------------
*/
.line-chart[data-point-color="master"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #455A64; }

.line-chart[data-point-color="success"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #88c843; }

.line-chart[data-point-color="info"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #616161; }

.line-chart[data-point-color="complete"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #4b7ec1; }

.line-chart[data-point-color="primary"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #2464b4; }

.line-chart[data-point-color="warning"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #FDD835; }

.line-chart[data-point-color="danger"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #f44336; }

.line-chart[data-point-color="white"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill: #fff; }

#nvd3-line svg,
#nvd3-line2 svg,
#nvd3-area svg {
  height: 500px; }

/* Rickshaw Charts
------------------------------------
*/
.rickshaw-chart.rickshaw_graph .detail {
  padding-left: 15px;
  transform: translateX(-15px);
  width: 30px;
  background: rgba(0, 0, 0, 0.04);
  right: -15px; }
  .rickshaw-chart.rickshaw_graph .detail .item, .rickshaw-chart.rickshaw_graph .detail .x_label {
    transform: translateX(15px); }
  .rickshaw-chart.rickshaw_graph .detail:after {
    content: "";
    width: 1px;
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    display: block; }

.rickshaw-chart .y_grid .tick.major line {
  stroke-dasharray: 3px, 5px;
  opacity: .7; }

.rickshaw-chart.rickshaw_graph .detail .x_label {
  display: none; }

.rickshaw-chart.rickshaw_graph .detail .item {
  line-height: 1.4;
  padding: 0.5em; }

.rickshaw-chart.rickshaw_graph .detail_swatch {
  float: right;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0; }

.rickshaw-chart.rickshaw_graph .detail .date {
  font-size: 11px;
  color: #a0a0a0;
  opacity: .5; }

#tab-rickshaw-realtime #rickshaw-realtime_y_axis {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  width: 40px;
  left: 0;
  z-index: 1; }
  #tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .y_ticks path, #tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .x_ticks_d3 path {
    fill: none;
    stroke: none; }
  #tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .y_ticks text, #tab-rickshaw-realtime #rickshaw-realtime_y_axis .rickshaw_graph .x_ticks_d3 text {
    opacity: 0.35;
    font-family: 'Montserrat';
    font-size: 11px; }

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .dot {
  border-radius: 50px; }

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .x_label {
  display: none; }

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .item {
  line-height: 1.4;
  padding: 0.5em; }

#tab-rickshaw-bars #rickshaw-stacked-bars .detail_swatch {
  float: right;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 4px 0 0; }

#tab-rickshaw-bars #rickshaw-stacked-bars.rickshaw_graph .detail .date {
  font-size: 11px;
  color: #a0a0a0;
  opacity: .5; }

#tab-rickshaw-slider #rickshaw-slider {
  height: 500px; }

#tab-rickshaw-slider .rickshaw_graph .x_grid_d3 .tick {
  stroke-opacity: 0; }

#tab-rickshaw-slider .rickshaw_graph .y_ticks path, #tab-rickshaw-slider .rickshaw_graph .x_ticks_d3 path {
  stroke: none; }

#tab-rickshaw-slider .rickshaw_graph .y_ticks text, #tab-rickshaw-slider .rickshaw_graph .x_ticks_d3 text {
  font-family: 'Montserrat';
  font-size: 11px; }

#tab-rickshaw-slider .rickshaw_range_slider_preview .frame {
  opacity: 0; }

#tab-rickshaw-slider .rickshaw_range_slider_preview .left_handle, #tab-rickshaw-slider .rickshaw_range_slider_preview .right_handle {
  fill: #000;
  fill-opacity: 0.1 !important; }

#tab-rickshaw-slider .slider {
  position: absolute;
  top: 0;
  height: 93px;
  overflow: hidden; }

#tab-rickshaw-slider .chart {
  position: absolute;
  bottom: 40px;
  top: 150px;
  left: 33px;
  right: 0;
  width: auto; }
  #tab-rickshaw-slider .chart .x_tick.plain .title {
    font-family: 'Montserrat';
    font-size: 11px; }

#tab-rickshaw-slider .y_axis {
  bottom: 0;
  position: absolute;
  top: 150px;
  width: 40px;
  left: -6px; }

#tab-rickshaw-slider .rickshaw_graph .x_tick {
  border-color: transparent; }
  #tab-rickshaw-slider .rickshaw_graph .x_tick .title {
    bottom: -24px;
    left: -15px; }

/*------------------------------------------------------------------
[18. List]
*/
.list-view-wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  -webkit-overflow-scrolling: touch; }

.list-view-container {
  position: relative; }
  .list-view-container .list-quickscroll ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 120;
    padding-top: 10px;
    padding-bottom: 10px; }
    .list-view-container .list-quickscroll ul li a {
      font-size: 0.7em;
      vertical-align: baseline; }

.list-view-group-container {
  margin: 0;
  min-height: 1px;
  overflow: hidden;
  padding: 26px 0 0 0;
  position: relative; }
  .list-view-group-container:last-child ul {
    border: 0; }

.list-view-group-header, .list-view-fake-header {
  background: #eef0f1;
  color: #232830;
  font: normal 11px/14px 'Montserrat',Arial;
  text-transform: uppercase;
  margin: 0;
  padding: 6px 0 5px 15px;
  position: absolute;
  border-top: 1px solid rgba(230, 230, 230, 0.7);
  border-bottom: 1px solid rgba(230, 230, 230, 0.7);
  z-index: 10; }

.list-view-group-header {
  bottom: auto;
  min-height: 1px;
  top: 0;
  width: 100%;
  border-top: 0; }

.list-view-fake-header {
  width: 100%;
  z-index: 100;
  font-size: 11px !important;
  line-height: 14px !important; }

.list-view-fake-header.list-view-hidden {
  visibility: hidden; }

.list-view-group-container.list-view-animated .list-view-group-header {
  bottom: 0;
  top: auto; }

input.list-view-search {
  font-size: 15px;
  color: #232830; }

.no-top-border .list-view-fake-header {
  border-top: 0; }

.list-view ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07); }

.list-view li {
  padding-left: 15px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  display: table; }
  .list-view li:hover {
    background-color: #dbe5f3; }
  .list-view li.active {
    background-color: #fff7d7; }
  .list-view li p {
    margin: 0;
    line-height: 19px; }
  .list-view li > a {
    margin-right: 15px;
    display: table;
    width: 100%; }

.list-view.boreded li > a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07); }

.list-view.boreded li:last-child > a {
  border-bottom: 0; }

.list-view.boreded li + li {
  border-top: 0; }

[data-ios="true"] .list-view-group-header,
[data-ios="true"] .list-view-fake-header {
  width: 288px;
  /*scrollbars aren't visible in iOS devices, so make the headers wider */ }

.list-group-item {
  border: 1px solid #e5e8e9; }
  .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: #a0d369;
    border-color: #a0d369; }

.list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px; }

/*------------------------------------------------------------------
[19. Social App]
*/
.social-wrapper,
.social {
  height: 100%;
  width: 100%; }

/* Cover 
------------------------------------
*/
.social-wrapper .social .jumbotron {
  height: 60vh; }

.social-wrapper .social .cover-photo {
  position: relative;
  margin: 0 auto;
  overflow-x: hidden; }
  .social-wrapper .social .cover-photo:before {
    background-image: url("../img/linear_gradient.png");
    background-repeat: repeat-x;
    bottom: 0;
    content: " ";
    height: 270px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1; }
  .social-wrapper .social .cover-photo .pull-bottom {
    z-index: 2; }

.cover-img-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.cover-img-container .cover-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  min-width: 100%;
  min-height: 100%; }

/* Feed 
------------------------------------
*/
.social-user-profile {
  width: 52px; }
  .social-user-profile > .thumbnail-wrapper {
    float: none; }

.social-wrapper .social .feed {
  overflow-y: hidden;
  width: 100%; }
  .social-wrapper .social .feed > .day {
    white-space: normal;
    display: block;
    width: 100%;
    margin: 0 auto; }
    .social-wrapper .social .feed > .day:after {
      feed: '';
      display: block;
      clear: both; }
    .social-wrapper .social .feed > .day:hover > .timeline:after {
      background: #4b7ec1; }

/* Cards 
------------------------------------
*/
.card {
  padding-bottom: 0;
  margin-bottom: 0;
  background: #FFF;
  float: left;
  position: relative;
  border-radius: 4px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-bottom: 20px;
  width: 300px;
  border: 1px solid #dee1e3; }
  .card .circle {
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    border-radius: 50%;
    border: 2px solid #e5e8e9;
    width: 9px;
    height: 9px;
    background: #818181;
    z-index: 1; }
    .card .circle:hover {
      cursor: pointer; }
  .card.full-width {
    width: 100% !important; }
  .card.status {
    background: #dbe5f3;
    border-radius: 4px;
    padding: 15px 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid transparent; }
    .card.status:hover, .card.status.hover {
      border-color: #dfdfdf !important; }
    .card.status h5 {
      font-size: 12px;
      margin: 0; }
      .card.status h5 .time {
        color: #fff; }
    .card.status h2 {
      color: #232d32;
      font-size: 25px;
      margin: 0;
      font-weight: normal; }
    .card.status .reactions {
      color: #e5e8e9;
      opacity: .45;
      font-size: 12px;
      padding: 0;
      margin: 5px 0 0;
      padding: 0; }
      .card.status .reactions li {
        display: inline-block;
        list-style: none;
        padding: 0; }
    .card.status .circle {
      width: 9px;
      height: 9px;
      border: none;
      background: #4b7ec1; }
  .card.share .card-header {
    padding: 15px 15px 12px;
    border-bottom: 1px solid #dbe5f3; }
    .card.share .card-header:hover {
      background: #dbe5f3;
      cursor: pointer;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px; }
      .card.share .card-header:hover h6 {
        opacity: .6; }
    .card.share .card-header .user-pic {
      float: left;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px; }
      .card.share .card-header .user-pic > img {
        width: 30px;
        height: 30px; }
    .card.share .card-header h5 {
      font-weight: bold;
      font-size: 14px;
      margin: 0;
      line-height: 22.7px;
      font-family: arial;
      letter-spacing: -0.006em;
      margin-top: -3px; }
    .card.share .card-header h6 {
      font-size: 12px;
      margin: 0;
      opacity: .45;
      font-family: arial;
      opacity: .45; }
  .card.share .card-description {
    padding: 12px 16px 11px;
    font-size: 14px;
    line-height: 21px;
    margin: 0; }
    .card.share .card-description p {
      margin-bottom: 4px; }
    .card.share .card-description .via {
      opacity: .45;
      display: block;
      font-size: 12px;
      font-family: arial; }
  .card.share .card-content {
    position: relative; }
    .card.share .card-content .buttons {
      left: 17px;
      padding: 0;
      position: absolute;
      top: 10px; }
      .card.share .card-content .buttons li {
        display: inline-block;
        list-style: none;
        margin-right: 10px; }
        .card.share .card-content .buttons li a {
          color: #fff; }
  .card.share .card-feed {
    overflow: hidden;
    position: relative;
    max-height: 400px; }
    .card.share .card-feed:hover .buttons {
      opacity: 1; }
    .card.share .card-feed .buttons {
      position: absolute;
      top: 5px;
      right: 0;
      opacity: 0; }
      .card.share .card-feed .buttons li {
        display: inline-block;
        list-style: none; }
        .card.share .card-feed .buttons li a {
          color: #fff;
          opacity: 0.8;
          padding: 5px; }
    .card.share .card-feed > * {
      max-width: 100%; }
  .card.share .card-footer {
    padding: 14px 16px 11px;
    font-size: 12px !important; }
    .card.share .card-footer:hover {
      background: #dbe5f3;
      cursor: pointer; }
      .card.share .card-footer:hover .reactions, .card.share .card-footer:hover .time {
        opacity: .8; }
    .card.share .card-footer .time {
      float: left;
      opacity: .45;
      font-family: arial;
      margin-top: 1px; }
    .card.share .card-footer .reactions {
      float: right;
      margin: 0;
      padding: 0;
      opacity: .45; }
      .card.share .card-footer .reactions li {
        display: inline-block;
        list-style: none; }
        .card.share .card-footer .reactions li a {
          color: inherit; }
  .card.share .card-description, .card.share .card-feed, .card.share .card-footer {
    border-color: transparent;
    border-style: solid; }
  .card.share .card-description {
    border-width: 1px; }
  .card.share .card-feed {
    border-width: 0 1px;
    margin-top: -2px; }
  .card.share .card-footer {
    border-width: 0 1px 1px 1px; }
  .card.share.share-other .card-description {
    padding-bottom: 0; }
  .card.share.share-other .card-footer {
    padding-top: 0;
    border-radius: 0; }
    .card.share.share-other .card-footer:hover {
      background: #fff; }
  .card.share.share-other .card-header {
    border-top: 1px solid #dbe5f3; }
  .card.share.share-other .circle {
    background: #FDD835; }
  .card.col1 {
    width: 300px; }
  .card.col2 {
    width: 620px; }
  .card.col3 {
    width: 920px; }
  .card img {
    width: 100%; }

/* Step Form : Status */
.simform {
  margin-left: 0;
  padding: 0;
  position: static;
  margin-bottom: 20px; }
  .simform .error-message {
    padding-top: 29px !important;
    padding-left: 22px; }
  .simform .final-message, .simform .error-message {
    font-size: 15px;
    opacity: 0.5;
    display: none;
    margin-top: 19px;
    position: static;
    text-align: left;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  .simform ol:before {
    background: transparent; }
  .simform .questions li {
    overflow: hidden; }
    .simform .questions li.current {
      position: relative; }
      .simform .questions li.current input {
        font-size: 14px;
        padding: 0 !important;
        margin: 0 !important; }
    .simform .questions li > span {
      width: 100%; }
      .simform .questions li > span label {
        font-size: 12px;
        opacity: .55;
        font-weight: 300;
        -webkit-transition: opacity 0.2s ease;
        transition: opacity 0.2s ease; }
  .simform .questions input {
    background: transparent !important;
    height: 30px; }
  .simform .questions .current input,
  .simform .no-js .questions input {
    background: transparent;
    border: none; }
  .simform .controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .simform .controls .next, .simform .controls .number {
      display: none !important; }

/* Responsive Handlers: Social App
------------------------------------
*/
@media (min-width: 991px) and (max-width: 1070px) {
  .day .card:first-child .col-md-4:first-child {
    width: 100%;
    margin-bottom: 20px; }
  .day .card:first-child .col-md-4:nth-child(2), .day .card:first-child .col-md-4:nth-child(3) {
    width: 50%; } }

@media (max-width: 667px) {
  .social-wrapper .social .feed > .day > .card {
    width: 100% !important; } }

/*------------------------------------------------------------------
[20. Email App]
*/
/* Email Components 
--------------------------------------------------
*/
.compose-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: #fff;
  border-top: 1px solid #e5e8e9;
  z-index: 101; }
  .compose-wrapper .compose-email {
    font-size: 24px; }

.email-composer {
  margin-left: 250px;
  position: relative;
  overflow: auto;
  height: 100%;
  background: #fff; }
  .email-composer .email-toolbar-wrapper .wysihtml5-toolbar {
    background: #e5e8e9;
    border-bottom: 1px solid #dee1e3;
    position: relative;
    border-top: none; }
  .email-composer .email-body-wrapper {
    border-bottom: 1px solid #dee1e3;
    margin-bottom: 20px; }
  .email-composer .bootstrap-tagsinput {
    margin: 0;
    padding: 0; }
  .email-composer > .row {
    margin-left: -30px;
    margin-right: -30px; }

.split-view .split-details .email-content-wrapper {
  background: #fff;
  height: 100%;
  width: auto;
  overflow: auto; }
  .split-view .split-details .email-content-wrapper .actions-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 799; }
  .split-view .split-details .email-content-wrapper .email-content {
    margin: 0 auto;
    width: 600px;
    display: block;
    padding-top: 62px;
    padding-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px; }
    .split-view .split-details .email-content-wrapper .email-content .email-content-header .sender .name {
      font-size: 15px;
      color: #616161; }
    .split-view .split-details .email-content-wrapper .email-content .email-content-header .sender .datetime {
      color: #455A64;
      opacity: .45;
      font-family: arial; }
    .split-view .split-details .email-content-wrapper .email-content .email-content-header .subject {
      font-family: arial;
      color: #616161;
      font-size: 15.2px;
      line-height: 17px; }
    .split-view .split-details .email-content-wrapper .email-content .email-content-header .fromto .btn-xs {
      border-radius: 13px; }
    .split-view .split-details .email-content-wrapper .email-content .email-content-body p {
      line-height: 23px;
      color: #455A64;
      letter-spacing: 0.001em; }
    .split-view .split-details .email-content-wrapper .email-content .email-reply {
      min-height: 200px; }
    .split-view .split-details .email-content-wrapper .email-content .editor-wrapper {
      border: 1px solid rgba(0, 0, 0, 0.05); }
      .split-view .split-details .email-content-wrapper .email-content .editor-wrapper .wysihtml5-sandbox {
        max-height: 150px;
        min-height: 130px !important;
        width: 100% !important; }

/* Email App Top Header 
--------------------------------------------------
*/
.header-wrapper-email {
  font-size: 14.92px; }
  .header-wrapper-email .dropdown > .btn {
    color: #4b7ec1; }
  .header-wrapper-email .btn:hover {
    text-decoration: none; }

/* Responsive Handler : Email App 
--------------------------------------------------
*/
@media (max-width: 1024px) {
  .email-composer {
    margin-left: 0; } }

/*------------------------------------------------------------------
[21. Calendar App]
*/
body.overflow-hidden {
  overflow: hidden; }

/*
Calendar
--------------------------------------------------
Header / .calendar-header
Years / .years .year
Months / .months
Weeks / .weeks-wrapper .week
Grid / .calendar-container
Events / .event-container
*/
.calendar {
  height: 100%; }
  .calendar .drager {
    overflow: hidden; }
    .calendar .drager .scroll-element {
      display: none; }
  .calendar.month {
    padding-left: 5px; }
    .calendar.month .options .date {
      margin-bottom: 22px; }
    .calendar.month .week-dragger {
      display: none; }
    .calendar.month .calendar-container {
      border-top: 1px solid #dee1e3;
      padding-top: 0;
      height: calc(100% - 169px); }
      .calendar.month .calendar-container .view .tble {
        width: 100%; }
        .calendar.month .calendar-container .view .tble .thead .tcell .weekday {
          margin-left: 10px; }
  .calendar .calendar-header {
    height: 43px;
    background-color: #eef0f1;
    line-height: 43px;
    padding-left: 60px; }
    .calendar .calendar-header > .drager {
      width: calc(100% - 141px);
      border-right: 1px solid rgba(0, 0, 0, 0.33);
      line-height: 35px; }
    .calendar .calendar-header .years {
      width: auto;
      list-style: none;
      white-space: nowrap;
      margin-top: 5px; }
      .calendar .calendar-header .years .year {
        display: inline-block;
        width: 69px;
        text-align: center;
        white-space: nowrap;
        font-family: "Segoe UI","Helvetica Neue", Helvetica, Arial, sans-serif; }
        .calendar .calendar-header .years .year > a {
          color: rgba(0, 0, 0, 0.5);
          position: relative; }
          .calendar .calendar-header .years .year > a.active {
            color: #000; }
          .calendar .calendar-header .years .year > a.has-event:before {
            position: absolute;
            content: '\25CF';
            width: 100%;
            font-size: 8px;
            line-height: 6px;
            text-align: center;
            color: rgba(0, 0, 0, 0.44); }
  .calendar .options {
    padding-left: 60px;
    margin-top: 15px; }
    .calendar .options .months {
      width: auto;
      white-space: nowrap;
      height: 43px;
      line-height: 43px; }
      .calendar .options .months .month {
        min-width: 30px;
        max-width: 100px;
        padding: 0 10px;
        display: inline-block; }
        .calendar .options .months .month > a {
          position: relative;
          color: rgba(0, 0, 0, 0.28); }
          .calendar .options .months .month > a:hover {
            color: rgba(0, 0, 0, 0.33); }
          .calendar .options .months .month > a.active {
            color: black; }
          .calendar .options .months .month > a.has-event:before {
            position: absolute;
            content: '\25CF';
            top: -6px;
            width: 100%;
            font-size: 8px;
            line-height: 6px;
            text-align: center;
            color: rgba(0, 0, 0, 0.33); }
    .calendar .options .date {
      margin-bottom: 20px; }
  .calendar .week-dragger {
    border-bottom: 1px solid #e6e6e6;
    margin-left: -20px; }
  .calendar .weeks-wrapper {
    width: auto;
    white-space: nowrap;
    padding-left: 10px;
    padding-bottom: 12px;
    margin-bottom: 5px;
    margin-left: 20px; }
    .calendar .weeks-wrapper .week {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      padding-right: 30px; }
      .calendar .weeks-wrapper .week:first-child {
        padding-left: 4px; }
      .calendar .weeks-wrapper .week:last-child {
        padding-left: 0px; }
      .calendar .weeks-wrapper .week:before {
        content: '';
        position: absolute;
        right: -20px;
        bottom: 6px;
        height: 20px;
        width: 20px;
        border-left: 1px dotted rgba(0, 0, 0, 0.3); }
      .calendar .weeks-wrapper .week.active .day-wrapper .week-date .day > a {
        color: rgba(0, 0, 0, 0.6); }
      .calendar .weeks-wrapper .week .day-wrapper {
        display: inline-block; }
        .calendar .weeks-wrapper .week .day-wrapper .week-date {
          text-align: center;
          width: 21px;
          height: 21px;
          margin: 6px;
          border-radius: 99px; }
          .calendar .weeks-wrapper .week .day-wrapper .week-date.current-date {
            background-color: #dee1e3; }
          .calendar .weeks-wrapper .week .day-wrapper .week-date.active {
            background-color: #88c843; }
            .calendar .weeks-wrapper .week .day-wrapper .week-date.active .day > a {
              font-weight: bold;
              opacity: 1;
              color: #fff; }
          .calendar .weeks-wrapper .week .day-wrapper .week-date .day > a {
            letter-spacing: -0.01em; }
        .calendar .weeks-wrapper .week .day-wrapper .week-day {
          text-align: center; }
        .calendar .weeks-wrapper .week .day-wrapper .day {
          display: inline-block;
          text-align: center;
          position: relative;
          z-index: 10;
          padding: 1px 0;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.3); }
          .calendar .weeks-wrapper .week .day-wrapper .day > a {
            position: relative;
            width: 100%;
            display: block;
            text-align: center;
            color: rgba(0, 0, 0, 0.3);
            opacity: 0.7;
            font-weight: 600; }
            .calendar .weeks-wrapper .week .day-wrapper .day > a.has-event:before {
              position: absolute;
              content: '\25CF';
              top: -10px;
              width: 100%;
              font-size: 8px;
              line-height: 6px;
              text-align: center;
              color: #88c843; }
          .calendar .weeks-wrapper .week .day-wrapper .day.week-header {
            text-transform: uppercase;
            text-align: center;
            font-family: 'Montserrat';
            font-size: 10px; }
  .calendar .calendar-container {
    position: relative;
    height: calc(100% - 200px);
    padding-top: 10px; }
    .calendar .calendar-container .view {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .calendar .calendar-container .view.month-view .tble .trow .tcell {
        position: relative;
        height: 20%;
        clear: both; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner .holder {
          position: absolute;
          top: 37px;
          left: 0;
          right: 0;
          bottom: 0; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell.active {
          background: #fff; }
          .calendar .calendar-container .view.month-view .tble .trow .tcell.active .cell-inner {
            background-color: #fef8e7; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell.not {
          background-color: #eef0f1; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell.drop-target .cell-inner {
          background-color: #eef0f1; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner:only-child, .calendar .calendar-container .view.month-view .tble .trow .tcell .cell-inner {
          height: 100%;
          border-bottom: 1px solid #dee1e3;
          position: relative; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell .month-date {
          position: relative;
          text-align: center;
          margin-top: 10px;
          margin-right: 6px;
          width: 25px;
          height: 25px;
          padding-top: 2px;
          margin: 6px;
          float: right;
          display: block;
          z-index: 1;
          border-radius: 99px; }
          .calendar .calendar-container .view.month-view .tble .trow .tcell .month-date.current-date {
            background-color: #dee1e3; }
          .calendar .calendar-container .view.month-view .tble .trow .tcell .month-date.active {
            background-color: #88c843;
            font-weight: bold;
            opacity: 1;
            color: #fff; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container {
          height: 30px;
          position: relative !important;
          min-height: 30px !important; }
          .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container .event-inner {
            padding: 8px; }
      .calendar .calendar-container .view.month-view .ghost-element {
        height: 30px;
        position: relative;
        width: 100%; }
      .calendar .calendar-container .view.month-view .grid .tble {
        height: 100%;
        width: 100%; }
      .calendar .calendar-container .view.month-view .event-container .event-title {
        font-size: 12px; }
      .calendar .calendar-container .view.day-view .tble {
        white-space: nowrap; }
        .calendar .calendar-container .view.day-view .tble .thead {
          white-space: nowrap;
          overflow: hidden;
          display: inline-block; }
        .calendar .calendar-container .view.day-view .tble .tcell {
          display: none;
          max-width: 100%;
          width: 100%; }
          .calendar .calendar-container .view.day-view .tble .tcell.active {
            display: inline-block; }
        .calendar .calendar-container .view.day-view .tble .trow {
          display: block;
          height: 80px; }
          .calendar .calendar-container .view.day-view .tble .trow .tcell.active {
            background-color: #fff; }
      .calendar .calendar-container .view .tble {
        display: inline-table;
        width: calc(100% - 50px);
        vertical-align: top;
        position: relative; }
        .calendar .calendar-container .view .tble .thead {
          display: table-row;
          width: 100%;
          background: #fff; }
          .calendar .calendar-container .view .tble .thead .tcell {
            padding: 10px;
            height: 40px;
            position: relative; }
            .calendar .calendar-container .view .tble .thead .tcell .weekday {
              font-size: 12px;
              display: inline-block;
              color: rgba(0, 0, 0, 0.51); }
            .calendar .calendar-container .view .tble .thead .tcell .weekdate {
              font-size: 14px;
              display: inline-block;
              margin-right: 10px;
              color: rgba(0, 0, 0, 0.77); }
            .calendar .calendar-container .view .tble .thead .tcell.active .weekdate, .calendar .calendar-container .view .tble .thead .tcell.active .weekday {
              color: black; }
            .calendar .calendar-container .view .tble .thead .tcell:before {
              content: '';
              border-bottom: 1px solid #dee1e3;
              width: calc(100% - 18px);
              position: absolute;
              bottom: 0; }
            .calendar .calendar-container .view .tble .thead .tcell .event-bubble {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-left: 5px;
              float: right;
              border-radius: 30px; }
        .calendar .calendar-container .view .tble .tcell {
          display: table-cell;
          height: 80px;
          max-width: 14.2857%;
          width: 14.2857%; }
        .calendar .calendar-container .view .tble .trow {
          display: table-row; }
          .calendar .calendar-container .view .tble .trow .tcell {
            background: #fff;
            padding: 0 10px; }
            .calendar .calendar-container .view .tble .trow .tcell .cell-inner {
              height: 40px;
              position: relative; }
              .calendar .calendar-container .view .tble .trow .tcell .cell-inner:first-child {
                border-bottom: 1px dotted #dee1e3; }
              .calendar .calendar-container .view .tble .trow .tcell .cell-inner:last-child {
                border-bottom: 1px solid #dee1e3; }
              .calendar .calendar-container .view .tble .trow .tcell .cell-inner:only-child {
                height: 40px;
                border-bottom: 0; }
            .calendar .calendar-container .view .tble .trow .tcell.active {
              background-color: #fef8e7; }
              .calendar .calendar-container .view .tble .trow .tcell.active > * {
                border-color: rgba(0, 0, 0, 0.1) !important; }
            .calendar .calendar-container .view .tble .trow .tcell.disable {
              background-color: #eef0f1; }
        .calendar .calendar-container .view .tble .loading {
          left: 10px; }
    .calendar .calendar-container .loading {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 100; }
    .calendar .calendar-container .pgn-wrapper {
      position: absolute; }
    .calendar .calendar-container .even-holder {
      position: absolute;
      display: table;
      width: calc(100% - 50px);
      height: calc(100% - 25px); }
      .calendar .calendar-container .even-holder .event-placeholder {
        height: 100%;
        width: 14.2857%;
        display: table-cell; }
    .calendar .calendar-container .event-container {
      width: 100%;
      height: 40px;
      position: absolute !important;
      z-index: 10;
      overflow: hidden;
      margin-bottom: 5px;
      min-height: 40px !important;
      transition: width .16s ease; }
      .calendar .calendar-container .event-container .event-inner {
        padding: 15px; }
        .calendar .calendar-container .event-container .event-inner:before {
          position: absolute;
          width: 8px;
          height: 8px;
          content: '';
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 999px;
          right: 14px;
          top: 11px; }
      .calendar .calendar-container .event-container .time-wrap {
        line-height: 12px;
        text-align: left;
        color: rgba(0, 0, 0, 0.77);
        overflow: hidden;
        text-overflow: ellipsis; }
      .calendar .calendar-container .event-container .event-start-time, .calendar .calendar-container .event-container .event-end-time {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.44); }
      .calendar .calendar-container .event-container .event-title {
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .calendar .calendar-container .event-container .resizable-handle {
        position: absolute;
        opacity: 1;
        z-index: 3;
        left: 0;
        right: 0;
        bottom: 0;
        height: 8px;
        overflow: hidden;
        line-height: 8px;
        font-size: 11px;
        font-family: monospace;
        text-align: center;
        cursor: s-resize; }
        .calendar .calendar-container .event-container .resizable-handle:after {
          content: "=";
          color: rgba(0, 0, 0, 0.44); }
      .calendar .calendar-container .event-container.dragging {
        z-index: 100; }
    .calendar .calendar-container .grid {
      display: block;
      overflow: scroll;
      height: calc(100% - 40px);
      -webkit-overflow-scrolling: touch; }
      .calendar .calendar-container .grid.slot-60 .tble .trow .tcell .cell-inner {
        height: 80px;
        border-bottom: 1px solid #dee1e3; }
      .calendar .calendar-container .grid.slot-15 .tble .trow .tcell {
        height: 25px; }
        .calendar .calendar-container .grid.slot-15 .tble .trow .tcell .cell-inner {
          height: 25px;
          border-bottom: 1px dotted #dee1e3; }
          .calendar .calendar-container .grid.slot-15 .tble .trow .tcell .cell-inner:last-child {
            border-bottom: 1px solid #dee1e3; }
      .calendar .calendar-container .grid.slot-15 .time-slot-wrapper .time-slot {
        height: 100px; }
    .calendar .calendar-container .allday-cell {
      height: 40px;
      display: inline-block;
      width: 50px;
      float: left; }
  .calendar .time-slot-wrapper {
    display: inline-block;
    width: 50px;
    float: left;
    height: 100%; }
    .calendar .time-slot-wrapper .time-slot {
      display: block;
      height: 80px;
      width: 100%;
      background: #fff; }
      .calendar .time-slot-wrapper .time-slot:first-child span {
        display: none; }
      .calendar .time-slot-wrapper .time-slot > span {
        float: right;
        font-size: 12px;
        position: relative;
        top: -15%;
        color: rgba(0, 0, 0, 0.46);
        font-weight: bold;
        font-size: 12px;
        right: 5px; }

.calendar-event {
  width: 330px;
  right: -330px; }
  .calendar-event .scrollable {
    height: 100%; }

.date-selector {
  cursor: pointer; }

/*
Responsive Util
*/
@media (max-width: 991px) {
  .calendar {
    background-color: #fff; }
    .calendar .calendar-header {
      display: none; }
    .calendar .options {
      margin-top: 0;
      padding-left: 10px; }
      .calendar .options .months {
        line-height: 21px;
        padding-top: 14px; }
      .calendar .options .date {
        margin-bottom: 10px; }
    .calendar .calendar-container {
      height: 100%; }
      .calendar .calendar-container .view.week-view .tble {
        white-space: nowrap; }
        .calendar .calendar-container .view.week-view .tble .thead {
          white-space: nowrap;
          overflow: hidden;
          display: inline-block; }
        .calendar .calendar-container .view.week-view .tble .tcell {
          display: none;
          max-width: 100%;
          width: 100%; }
          .calendar .calendar-container .view.week-view .tble .tcell.active {
            display: inline-block;
            background-color: #fff; }
        .calendar .calendar-container .view.week-view .tble .trow {
          display: block;
          height: 80px; }
      .calendar .calendar-container .view.month-view .allday-cell {
        display: none; }
      .calendar .calendar-container .view.month-view .grid .tble {
        padding-left: 0; }
      .calendar .calendar-container .view.month-view .tble .thead .tcell {
        padding: 3px;
        padding-top: 10px; }
      .calendar .calendar-container .view.month-view .tble .trow .tcell {
        padding: 0 6px; }
      .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container {
        border-radius: 99px;
        -webkit-border-radius: 99px;
        -moz-border-radius: 99px;
        position: absolute !important;
        height: 16px !important;
        width: 16px !important;
        min-height: 16px !important;
        margin: 0 auto;
        left: 0;
        right: 0; }
        .calendar .calendar-container .view.month-view .tble .trow .tcell .event-container .event-inner {
          display: none; }
      .calendar .calendar-container.month {
        height: calc(100% - 101px); }
    .calendar .weeks-wrapper {
      margin-bottom: 0; }
    .calendar.month {
      padding-left: 0; }
      .calendar.month .calendar-container {
        height: calc(100% - 108px); }
  .calendar-event {
    width: 240px;
    right: -240px; }
  .months-drager {
    margin-left: 27px;
    width: calc(100% - 80px);
    border-right: 1px solid #dee1e3;
    padding-top: 0; } }

@media (max-width: 640px) {
  .calendar.month .calendar-container .view .tble .thead .tcell {
    text-align: center; }
    .calendar.month .calendar-container .view .tble .thead .tcell:before {
      width: 100%; }
    .calendar.month .calendar-container .view .tble .thead .tcell .weekday {
      width: 11px;
      overflow: hidden;
      letter-spacing: 4px;
      font-weight: bold; } }

/*------------------------------------------------------------------
[22. Login]
*/
.login-wrapper {
    height: 100%;
    background-color: #778799;
}
  .login-wrapper > * {
    height: 100%; }
  .login-wrapper .bg-pic {
    position: absolute;
    width: 100%;
    overflow: hidden; }
    .login-wrapper .bg-pic > img {
      height: 100%;
      opacity: 0.6; }
  .login-wrapper .login-container {
    width: 496px;
    display: block;
    position: relative;
    float: right; }
  .login-wrapper .bg-caption {
    width: 600px; }

.register-container {
  width: 550px;
  margin: auto;
  height: 100%; }

/* Responsive handlers : Login
------------------------------------
*/
@media (max-width: 768px) {
  .login-wrapper .login-container {
    width: 100%; }
  .register-container {
    width: 100%;
    padding: 15px; } }

@media only screen and (max-width: 321px) {
  .login-wrapper .login-container {
    width: 100%; } }

/*------------------------------------------------------------------
[23. Lock Screen]
*/
.lock-container {
  margin-left: auto;
  margin-right: auto;
  width: 600px; }

.lock-screen-wrapper .credentials {
  margin-top: -84px;
  position: absolute;
  top: 50%; }
  .lock-screen-wrapper .credentials .thumbnail-wrapper {
    width: 53px;
    height: 53px; }
  .lock-screen-wrapper .credentials .logged {
    opacity: .21;
    margin-top: -5px !important; }
  .lock-screen-wrapper .credentials .name {
    opacity: .69;
    margin-top: -5px !important;
    font-size: 36px;
    height: 45px;
    overflow: hidden; }

.lock-screen-wrapper .terms-wrapper > div {
  display: table; }

.lock-screen-wrapper .terms-wrapper .terms {
  display: table-cell;
  vertical-align: middle; }

.lock-screen-wrapper .terms-wrapper .logo-terms {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  text-align: center;
  position: relative; }
  .lock-screen-wrapper .terms-wrapper .logo-terms .brand {
    left: 50%;
    margin-left: -21px;
    margin-top: -5px;
    position: absolute;
    top: 50%; }

/* Responsive Handlers : Lockscreen 
------------------------------------
*/
@media (max-width: 767px) {
  .lock-container {
    width: 80%; }
  .lock-screen-wrapper .credentials form {
    margin-top: 15px; } }

@media (max-width: 480px) {
  .lock-screen-wrapper .credentials {
    margin: 0;
    position: static;
    margin-top: 100px;
    width: 100%;
    float: left;
    clear: both; }
    .lock-screen-wrapper .credentials > div {
      text-align: center; }
    .lock-screen-wrapper .credentials .thumbnail-wrapper {
      float: none;
      margin: 0 auto; } }

/*------------------------------------------------------------------
[24. Timeline]
*/
/*
  Adapted from Vertical Timeline by Sebastiano Guerriero
  http://codyhouse.co/gem/vertical-timeline/
*/
/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.timeline-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto; }
  .timeline-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both; }
  .timeline-container.top-circle:before {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 99px;
    -webkit-border-radius: 99px;
    -moz-border-radius: 99px;
    background-color: #fff;
    z-index: 10; }

/* -------------------------------- 

Main components 

-------------------------------- */
.timeline {
  position: relative;
  padding: 3em 0 3em 0;
  margin-top: 0;
  margin-bottom: 2em; }
  .timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 2px;
    background: #fff; }

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline {
    margin-bottom: 3em; }
    .timeline-container:not(.left) .timeline::before {
      left: 50%;
      margin-left: -2px; } }

.timeline-block {
  position: relative;
  margin: 2em 0; }
  .timeline-block:after {
    content: "";
    display: table;
    clear: both; }
  .timeline-block:first-child {
    margin-top: 0; }
  .timeline-block:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-block {
    margin: 4em 0; }
    .timeline-container:not(.left) .timeline .timeline-block:first-child {
      margin-top: 0; }
    .timeline-container:not(.left) .timeline .timeline-block:last-child {
      margin-bottom: 0; } }

.timeline-point {
  position: absolute;
  top: 12px;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  background: #a7afb4;
  border: 2px solid #fff; }
  .timeline-point.small {
    height: 12px;
    margin-left: 13px;
    margin-top: 14px;
    width: 12px; }
  .timeline-point i {
    color: #fff;
    font-size: 14px;
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    position: absolute;
    top: 50%; }
  .timeline-point.primary {
    background-color: #2464b4; }
  .timeline-point.complete {
    background-color: #4b7ec1; }
  .timeline-point.success {
    background-color: #88c843; }
  .timeline-point.info {
    background-color: #616161; }
  .timeline-point.danger {
    background-color: #f44336; }
  .timeline-point.warning {
    background-color: #FDD835; }

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-point {
    left: 50%;
    margin-left: -21px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; }
    .timeline-container:not(.left) .timeline .timeline-point.is-hidden {
      visibility: hidden; }
    .timeline-container:not(.left) .timeline .timeline-point.small {
      margin-left: -7px; }
    .timeline-container:not(.left) .timeline .timeline-point.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-1 0.6s;
      -moz-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s; } }

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1); } }

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5); }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2); }
  100% {
    -moz-transform: scale(1); } }

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.timeline-content {
  position: relative;
  margin-left: 60px; }
  .timeline-content:after {
    content: "";
    display: table;
    clear: both; }
  .timeline-content .card {
    margin-bottom: 0; }
  .timeline-content .event-date {
    display: inline-block;
    float: left;
    padding: 8em 0;
    opacity: .7;
    clear: both; }

@media (max-width: 480px) {
  .timeline-container {
    width: 94%; } }

@media only screen and (min-width: 1170px) {
  .timeline-container:not(.left) .timeline .timeline-content {
    margin-left: 0;
    width: 46%; }
    .timeline-container:not(.left) .timeline .timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white; }
    .timeline-container:not(.left) .timeline .timeline-content.is-hidden {
      visibility: hidden; }
    .timeline-container:not(.left) .timeline .timeline-content.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2 0.6s;
      -moz-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s; }
    .timeline-container:not(.left) .timeline .timeline-content .event-date {
      position: absolute;
      width: 100%;
      left: 118%;
      top: -2px;
      font-size: 16px;
      font-size: 1rem; }
      .timeline-container:not(.left) .timeline .timeline-content .event-date small {
        margin-top: 13px;
        display: block; }
      .timeline-container:not(.left) .timeline .timeline-content .event-date h6 + small {
        margin-top: 0; }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(odd) .timeline-content .card {
    float: right; }
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content {
    float: right; }
    .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: white; }
    .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content .event-date {
      left: auto;
      right: 118%;
      text-align: right; } }

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .timeline-container:not(.left) .timeline .timeline-block:nth-child(even) .timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s; } }

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  100% {
    -webkit-transform: translateX(0); } }

@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px); }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px); }
  100% {
    -moz-transform: translateX(0); } }

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0); } }

@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px); }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px); }
  100% {
    -moz-transform: translateX(0); } }

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

.timeline-container.center .timeline {
  margin-top: 3em;
  margin-bottom: 3em; }
  .timeline-container.center .timeline::before {
    left: 50%;
    margin-left: -2px; }
  .timeline-container.center .timeline .timeline-point {
    left: 50%;
    margin-left: -21px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; }
    .timeline-container.center .timeline .timeline-point.is-hidden {
      visibility: hidden; }
    .timeline-container.center .timeline .timeline-point.small {
      margin-left: -7px; }
    .timeline-container.center .timeline .timeline-point.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-1 0.6s;
      -moz-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s; }
  .timeline-container.center .timeline .timeline-content {
    margin-left: 0;
    width: 46%; }
    .timeline-container.center .timeline .timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: white; }
    .timeline-container.center .timeline .timeline-content.is-hidden {
      visibility: hidden; }
    .timeline-container.center .timeline .timeline-content.bounce-in {
      visibility: visible;
      -webkit-animation: cd-bounce-2 0.6s;
      -moz-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s; }
    .timeline-container.center .timeline .timeline-content .event-date {
      position: absolute;
      width: 100%;
      left: 118%;
      top: -2px;
      font-size: 16px;
      font-size: 1rem; }
      .timeline-container.center .timeline .timeline-content .event-date small {
        margin-top: 13px;
        display: block; }
      .timeline-container.center .timeline .timeline-content .event-date h6 + small {
        margin-top: 0; }
  .timeline-container.center .timeline .timeline-block:nth-child(odd) .timeline-content .card {
    float: right; }
  .timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content {
    float: right; }
    .timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: white; }
    .timeline-container.center .timeline .timeline-block:nth-child(even) .timeline-content .event-date {
      left: auto;
      right: 118%;
      text-align: right; }

@media only screen and (min-width: 1170px) {
  .timeline-container.left {
    width: 60%;
    margin-left: 100px; } }

/*------------------------------------------------------------------
[25. Gallery]
*/
.gallery {
  margin: 70px auto 0 auto; }

.gallery-item {
  overflow: hidden;
  cursor: default;
  background-color: #000;
  margin-bottom: 10px;
  position: relative;
  width: 280px;
  height: 240px; }
  .gallery-item:hover {
    cursor: pointer; }
  .gallery-item[data-width="1"] {
    width: 280px; }
  .gallery-item[data-width="2"] {
    width: 570px; }
  .gallery-item[data-height="1"] {
    height: 240px; }
  .gallery-item[data-height="2"] {
    height: 490px; }
  .gallery-item > img {
    opacity: 1;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s; }
  .gallery-item > .live-tile img {
    opacity: 1;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s; }
  .gallery-item .rating {
    margin-top: -5px;
    color: rgba(255, 255, 255, 0.3); }
    .gallery-item .rating > .rated {
      color: white; }
  .gallery-item .item-info {
    -webkit-transform: translate3d(0, 40%, 0);
    transform: translate3d(0, 40%, 0);
    -webkit-transition: -webkit-transform 0.35s, color 0.35s;
    transition: transform 0.35s, color 0.35s; }
    .gallery-item .item-info.more-content {
      -webkit-transform: translate3d(0, 32%, 0);
      transform: translate3d(0, 32%, 0); }
  .gallery-item:hover .item-info {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .gallery-item:hover > img {
    opacity: 0.6; }
  .gallery-item:hover > .live-tile img {
    opacity: 0.6; }
  .gallery-item:active .item-info {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .gallery-item:active > img {
    opacity: 0.6; }

.item-details {
  z-index: 1050; }
  .item-details .dialog__content {
    width: 845px;
    max-width: 845px;
    padding: 0;
    text-align: left;
    border: 1px solid rgba(0, 0, 0, 0.8); }
    .item-details .dialog__content .dialog__overview {
      height: 516px;
      position: relative; }
      .item-details .dialog__content .dialog__overview .buy-now {
        position: absolute;
        bottom: 20px;
        right: 35px; }
      .item-details .dialog__content .dialog__overview .item-slideshow .slide {
        width: 516px;
        height: 516px;
        display: block;
        overflow: hidden; }
    .item-details .dialog__content .dialog__footer {
      height: 75px; }
      .item-details .dialog__content .dialog__footer .price {
        margin: 0;
        padding: 0;
        line-height: 75px; }
      .item-details .dialog__content .dialog__footer .separator {
        position: relative; }
      .item-details .dialog__content .dialog__footer .separator:after {
        content: "";
        width: 1px;
        background: rgba(255, 255, 255, 0.1);
        position: absolute;
        height: 27px;
        right: 0;
        top: 24px; }
      .item-details .dialog__content .dialog__footer .recommended li {
        overflow: hidden; }
        .item-details .dialog__content .dialog__footer .recommended li a {
          width: 56px;
          height: 56px;
          display: block; }
        .item-details .dialog__content .dialog__footer .recommended li img {
          width: 100%; }
    .item-details .dialog__content .close {
      top: 15px;
      right: 15px;
      z-index: 100; }
  .item-details .owl-nav {
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    color: #fff;
    opacity: .7;
    font-size: 18px;
    padding: 0 20px; }
    .item-details .owl-nav .owl-prev {
      float: left; }
    .item-details .owl-nav .owl-next {
      float: right; }
  .item-details .owl-dots {
    bottom: 23px;
    position: absolute;
    right: 23px; }
    .item-details .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.3);
      margin-left: 6px; }
      .item-details .owl-dots .owl-dot.active {
        background: #fff; }

.gallery-filters {
  position: absolute;
  left: 0;
  right: 0;
  height: 70px;
  top: -70px; }

@media (max-width: 920px) {
  .gallery-item.first {
    display: none; } }

@media (max-width: 767px) {
  .item-details .dialog__content {
    height: 90%;
    overflow-y: auto;
    width: 400px;
    max-width: 400px; }
    .item-details .dialog__content .container-fluid {
      height: 100%;
      padding-left: 30px;
      padding-right: 30px; }
    .item-details .dialog__content .dialog__overview {
      height: 100%;
      margin-right: -30px;
      margin-left: -30px; }
  .item-details .item-slideshow-wrapper {
    height: 515px !important; }
  .item-details .item-description {
    height: auto !important; }
    .item-details .item-description .buy-now {
      position: static !important;
      float: right;
      margin-bottom: 20px; }
  .item-details .item-slideshow .owl-stage-outer, .item-details .item-slideshow .owl-stage {
    height: 100%; }
  .item-details .item-slideshow .slide {
    width: 100% !important; } }

@media (max-width: 420px) {
  .gallery {
    margin-top: 80px; }
  .gallery-filters {
    top: -90px; }
  .item-details .dialog__content {
    width: 100%;
    max-width: 100%; } }

@media (max-width: 610px) {
  .gallery-item, .gallery {
    width: 100% !important; } }

@media (min-width: 768px) {
  .item-details .dialog__content .container-fluid > .row {
    margin-left: -30px;
    margin-right: -30px; } }

/*------------------------------------------------------------------
[26. Vector Map : Mapplic Plugin] 
*/
.mapplic-container {
  width: 100%;
  background-color: transparent; }
  .mapplic-container .mapplic-fullscreen-button {
    left: auto;
    right: 154px;
    bottom: 0;
    top: auto; }
  .mapplic-container .mapplic-clear-button {
    visibility: hidden; }

.mapplic-tooltip:before {
  content: "Location";
  font-size: 12px;
  margin: 0;
  line-height: normal;
  opacity: .7;
  color: #455A64; }

.mapplic-tooltip-close {
  opacity: .5;
  background: none; }
  .mapplic-tooltip-close:after {
    content: "\e60a";
    font-family: 'pages-icon';
    font-size: 12px;
    position: relative;
    top: -2px;
    color: #455A64;
    opacity: .7; }

.mapplic-tooltip-title {
  display: none; }

.mapplic-tooltip-content {
  margin-top: 5px; }

.mapplic-tooltip {
  max-width: 150px;
  padding: 10px 12px;
  border-radius: 4px; }

.mapplic-tooltip-description {
  font-weight: bold;
  color: #455A64; }

.mapplic-tooltip-description strong {
  color: #f44336;
  margin-right: 2px; }

.map-controls {
  position: absolute;
  left: 50px;
  top: 80px;
  z-index: 1; }

.mapplic-pin {
  background-image: url("../../assets/img/maps/marker-master.svg");
  background-size: contain; }
  .mapplic-pin.pulse {
    background-image: url("../../assets/img/maps/pulse-master.svg"); }
    .mapplic-pin.pulse.green {
      background-image: url("../../assets/img/maps/pulse-success.svg"); }
    .mapplic-pin.pulse.blue {
      background-image: url("../../assets/img/maps/pulse-complete.svg"); }
    .mapplic-pin.pulse.purple {
      background-image: url("../../assets/img/maps/pulse-primary.svg"); }
    .mapplic-pin.pulse.yellow {
      background-image: url("../../assets/img/maps/pulse-warning.svg"); }
    .mapplic-pin.pulse.red {
      background-image: url("../../assets/img/maps/pulse-danger.svg"); }
  .mapplic-pin.pulse-alt {
    background-image: url("../../assets/img/maps/pulse-alt-master.svg"); }
    .mapplic-pin.pulse-alt.green {
      background-image: url("../../assets/img/maps/pulse-alt-success.svg"); }
    .mapplic-pin.pulse-alt.blue {
      background-image: url("../../assets/img/maps/pulse-alt-complete.svg"); }
    .mapplic-pin.pulse-alt.purple {
      background-image: url("../../assets/img/maps/pulse-alt-primary.svg"); }
    .mapplic-pin.pulse-alt.yellow {
      background-image: url("../../assets/img/maps/pulse-alt-warning.svg"); }
    .mapplic-pin.pulse-alt.red {
      background-image: url("../../assets/img/maps/pulse-alt-danger.svg"); }
  .mapplic-pin.marker {
    background-image: url("../../assets/img/maps/marker-master.svg"); }
    .mapplic-pin.marker.green {
      background-image: url("../../assets/img/maps/marker-success.svg"); }
    .mapplic-pin.marker.blue {
      background-image: url("../../assets/img/maps/marker-complete.svg"); }
    .mapplic-pin.marker.purple {
      background-image: url("../../assets/img/maps/marker-primary.svg"); }
    .mapplic-pin.marker.yellow {
      background-image: url("../../assets/img/maps/marker-warning.svg"); }
    .mapplic-pin.marker.red {
      background-image: url("../../assets/img/maps/marker-danger.svg"); }
  .mapplic-pin.marker-alt {
    background-image: url("../../assets/img/maps/marker-alt-master.svg"); }
    .mapplic-pin.marker-alt.green {
      background-image: url("../../assets/img/maps/marker-alt-success.svg"); }
    .mapplic-pin.marker-alt.blue {
      background-image: url("../../assets/img/maps/marker-alt-complete.svg"); }
    .mapplic-pin.marker-alt.purple {
      background-image: url("../../assets/img/maps/marker-alt-primary.svg"); }
    .mapplic-pin.marker-alt.yellow {
      background-image: url("../../assets/img/maps/marker-alt-warning.svg"); }
    .mapplic-pin.marker-alt.red {
      background-image: url("../../assets/img/maps/marker-alt-danger.svg"); }

/*------------------------------------------------------------------
[27. Widgets]
*/
.widget {
  position: relative; }
  .widget > div {
    position: relative;
    z-index: 1; }
  .widget:after {
    background-size: cover;
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
    opacity: .69; }

.widget-1:after {
  background-image: url("../../assets/img/dashboard/pages_hero.jpg");
  background-size: cover;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 0;
  opacity: .69; }

.widget-1:before {
  background-image: url("../img/linear_gradient.png");
  background-repeat: repeat-x;
  content: " ";
  left: 0;
  right: 0;
  height: 325px;
  bottom: 0;
  position: absolute;
  z-index: 1; }

.widget-1 > .panel-body .company:nth-child(2) > div {
  display: table;
  margin: 0 auto;
  width: 127px; }

.widget-1 > .panel-body > * {
  z-index: 1; }

.widget-1 > .panel-body > *:not(.pull-bottom) {
  position: relative; }

.widget-1 > .panel-body .pull-bottom {
  padding: 0 49px 56px 42px; }

.widget-1 span.label {
  color: #fff;
  background: rgba(255, 255, 255, 0.3); }

  .widget-2:after {
    background-image: url("../img/linear_gradient.png"); }

.widget-3 a {
  text-decoration: none; }

.widget-3 .pg-map {
  font-size: 30px; }

.widget-3 .widget-3-fav {
  background: rgba(0, 0, 0, 0.07);
  vertical-align: middle;
  padding: 6px 11px;
  display: block; }

.widget-4 .row-sm-height:nth-child(1) {
  height: 30px; }

.widget-4 .row-sm-height:nth-child(2) {
  height: 30px; }

.widget-4-chart {
  height: 100%;
  width: 100%;
  bottom: 0;
  position: absolute;
  right: 0; }
  .widget-4-chart.line-chart .tick text,
  .widget-4-chart .line-chart .nvd3 .nv-axis .nv-axisMaxMin text {
    transform: translate(-10px, -32px); }
  .widget-4-chart .nvtooltip .nv-pointer-events-none thead {
    display: none; }
  .widget-4-chart .nvtooltip .nv-pointer-events-none tbody .nv-pointer-events-none .key {
    display: none; }

.widget-5-chart-container {
  overflow: hidden; }

.widget-5-chart {
  height: auto;
  width: auto;
  bottom: 20px;
  position: absolute;
  right: 20px;
  left: 20px;
  top: 40px; }

.widget-6 {
  background: #7f8d94; }
  .widget-6 .label {
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.67); }

.widget-7 .slide-back .row-sm-height:nth-child(1) {
  height: 60%; }

.widget-7 .slide-back .row-sm-height:nth-child(2) {
  height: 40%; }

.widget-7-chart {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute; }
  .widget-7-chart.line-chart[data-points="true"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
    stroke-opacity: 1; }
  .widget-7-chart .nvd3 circle.nv-point:nth-child(4) {
    fill: #f44336 !important;
    stroke: #f44336 !important; }

.widget-8 {
  height: 145px; }
  .widget-8 .row-xs-height:first-child {
    height: 41px; }

.widget-8-chart {
  height: 100px;
  width: 50%;
  bottom: 0;
  position: absolute;
  right: 0; }
  .widget-8-chart .line-chart[data-points="true"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
    stroke-opacity: 0.3; }
  .widget-8-chart .line-chart .nvd3 .nv-groups path.nv-line {
    stroke-opacity: 0.15; }
  .widget-8-chart .nvtooltip {
    display: none; }

.widget-9 {
  height: 145px; }
  .widget-9 .row-xs-height:first-child {
    height: 26px; }
  .widget-9 .progress {
    background: rgba(0, 0, 0, 0.1); }

.widget-10 {
  height: 145px; }

.widget-11 sup {
  margin-right: -4px; }

.widget-11 .widget-11-table {
  height: 380px; }
  .widget-11 .widget-11-table tr td:first-child {
    width: 40%; }

.widget-11-2 sup {
  margin-right: -4px; }

.widget-11-2 .widget-11-2-table {
  height: 273px; }

.widget-12 .list-inline a {
  padding: 3px 4px;
  border-radius: 3px;
  opacity: .7; }

.widget-12 .list-inline .active a {
  opacity: 1; }

.widget-12 .nvd3-line svg {
  height: 316px; }

.widget-12 .company-stat-boxes .close {
  line-height: 0; }

.widget-12 .widget-12-search {
  margin-top: -3px; }

.widget-14 .row-xs-height:nth-child(1) {
  height: 30px; }

.widget-14 .row-xs-height:nth-child(2) {
  height: 120px; }

.widget-14 .row-xs-height:nth-child(3) {
  height: 297px; }

.widget-14-chart_y_axis {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  bottom: 0;
  width: 35px;
  left: 0;
  z-index: 2; }
  .widget-14-chart_y_axis .rickshaw_graph .y_ticks path,
  .widget-14-chart_y_axis .rickshaw_graph .x_ticks_d3 path {
    fill: none;
    stroke: none; }
  .widget-14-chart_y_axis .rickshaw_graph .y_ticks text,
  .widget-14-chart_y_axis .rickshaw_graph .x_ticks_d3 text {
    opacity: 0.35;
    font-family: 'Montserrat';
    font-size: 11px; }
  .widget-14-chart_y_axis .y_ticks.plain g:first-child {
    opacity: 0 !important; }

.widget-14-chart-legend .ui-sortable li {
  padding-right: 0; }
  .widget-14-chart-legend .ui-sortable li .action {
    display: none; }
  .widget-14-chart-legend .ui-sortable li:last-child {
    margin-top: 7px; }

.widget-15 #widget-15-tab-1 > div {
  height: 170px; }

.widget-15-2 {
  height: 469px; }
  .widget-15-2 #widget-15-2-tab-1 .full-width {
    height: 180px; }

.widget-16-header .pull-left:last-child {
  width: 69%; }

.widget-16-chart {
  height: 100px; }
  .widget-16-chart.line-chart[data-stroke-width="2"] .nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
    stroke-width: 2px; }

.widget-17 {
  height: 467px; }
  .widget-17 .widget-17-weather {
    width: 90%; }

.widget-18-post {
  height: 342px;
  background: url("../../assets/img/social-post-image.png");
  background-position: center center;
  background-size: cover; }

.widget-19-post {
  height: 237px;
  background: #00A79A; }
  .widget-19-post img {
    top: 50%;
    margin-top: -70px; }

.btn-circle-arrow {
  border: 1px solid #fff;
  border-radius: 100px;
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px; }
  .btn-circle-arrow i {
    font-size: 11px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -4px; }

/*** Large screens ***/
@media only screen and (min-width: 1824px) {
  .ar-3-2:before {
    padding-top: calc(55% - 5px) !important; }
  .ar-2-3:before {
    padding-top: calc(135% - 5px) !important; } }

@media (max-width: 991px) {
  .panel {
    height: auto !important; }
  .widget-8,
  .widget-9,
  .widget-10 {
    height: 180px !important; } }

@media (max-width: 480px) {
  .widget-1-wrapper {
    height: 340px; } }

/* WIDGET MANAGER */
#widget-filter {
  font-size: 18px; }
  #widget-filter:focus {
    background: none;
    border: none; }

#widgetDetails .progress-circle-indeterminate {
  position: absolute;
  left: 50%;
  margin-left: -19px;
  top: 50%;
  margin-top: -19px; }

.widgets-container {
  margin: 0 auto; }

#widget-preview {
  display: block;
  margin: 0 auto; }

.widget-item {
  overflow: hidden;
  cursor: default;
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  width: 280px;
  height: 240px; }
  .widget-item:hover {
    cursor: pointer; }
    .widget-item:hover:before {
      opacity: 0; }
    .widget-item:hover:after {
      opacity: .8; }
    .widget-item:hover .item-footer {
      color: #2464b4 !important; }
  .widget-item:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 70%);
    transition: opacity .3s ease;
    -webkit-transition: opacity .3s ease;
    opacity: 1; }
  .widget-item:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    transition: opacity .3s ease;
    -webkit-transition: opacity .3s ease; }
  .widget-item[data-width="1"] {
    width: 280px; }
  .widget-item[data-width="2"] {
    width: 570px; }
  .widget-item[data-height="1"] {
    height: 240px; }
  .widget-item[data-height="2"] {
    height: 490px; }
  .widget-item .item-footer {
    color: #455A64; }
    .widget-item .item-footer p.pull-left {
      text-overflow: ellipsis;
      width: 84%;
      white-space: nowrap;
      overflow: hidden; }
    .widget-item .item-footer .pull-right .fa-stack {
      font-size: 69%; }

@media (max-width: 420px) {
  .widgets-container {
    margin-top: 80px; } }

@media (max-width: 610px) {
  .widget-item, .widgets-container {
    width: 100% !important; } }

/*------------------------------------------------------------------
[28. Misc : Helper Classes]
*/
.custom {
  height: 150px; }

.icon-list .fa-item {
  display: block;
  color: #0e1214;
  line-height: 32px;
  height: 32px;
  padding-left: 10px; }
  .icon-list .fa-item > i {
    width: 32px;
    font-size: 14px;
    display: inline-block;
    text-align: right;
    margin-right: 10px; }

/* Thumbnail for icons and profile pics 
------------------------------------
*/
.thumbnail-wrapper {
  display: inline-block;
  overflow: hidden;
  float: left; }
  .thumbnail-wrapper.circular {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%; }
  .thumbnail-wrapper.bordered {
    border-width: 4px;
    border-style: solid; }
    .thumbnail-wrapper.bordered.d16 {
      width: 20px;
      height: 20px; }
      .thumbnail-wrapper.bordered.d16 > * {
        line-height: 12px; }
    .thumbnail-wrapper.bordered.d24 {
      width: 28px;
      height: 28px; }
      .thumbnail-wrapper.bordered.d24 > * {
        line-height: 24px; }
    .thumbnail-wrapper.bordered.d32 {
      width: 36px;
      height: 36px; }
      .thumbnail-wrapper.bordered.d32 > * {
        line-height: 28px; }
    .thumbnail-wrapper.bordered.d48 {
      width: 52px;
      height: 52px; }
      .thumbnail-wrapper.bordered.d48 > * {
        line-height: 44px; }
  .thumbnail-wrapper.d16 {
    width: 16px;
    height: 16px; }
    .thumbnail-wrapper.d16 > * {
      line-height: 16px; }
  .thumbnail-wrapper.d24 {
    width: 24px;
    height: 24px; }
    .thumbnail-wrapper.d24 > * {
      line-height: 24px; }
  .thumbnail-wrapper.d32 {
    width: 32px;
    height: 32px; }
    .thumbnail-wrapper.d32 > * {
      line-height: 32px; }
  .thumbnail-wrapper.d39 {
    width: 39px;
    height: 39px; }
    .thumbnail-wrapper.d39 > * {
      line-height: 39px; }
  .thumbnail-wrapper.d48 {
    width: 48px;
    height: 48px; }
    .thumbnail-wrapper.d48 > * {
      line-height: 50px; }
  .thumbnail-wrapper > * {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    text-align: center; }

/* Profile dropdown
------------------------------------
*/
.profile-dropdown {
  background: #fff;
  padding: 0; }
  .profile-dropdown:before {
    position: absolute;
    top: -7px;
    right: 15px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: ''; }
  .profile-dropdown:after {
    position: absolute;
    top: -6px;
    right: 16px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: ''; }
  .profile-dropdown li:last-child {
    margin-top: 11px;
    padding: 0; }
    .profile-dropdown li:last-child > a {
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 19px; }
  .profile-dropdown li > a {
    opacity: .5;
    -webkit-transition: opacity ease 0.3s;
    transition: opacity ease 0.3s;
    padding-left: 17px;
    padding-right: 37px;
    min-width: 138px; }
    .profile-dropdown li > a > i {
      margin-right: 5px; }
    .profile-dropdown li > a:hover {
      opacity: 1; }

.profile-dropdown-toggle {
  background: transparent;
  border: none; }

/* Scroll 
------------------------------------
*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

/* Overides 
------------------------------------
*/
.no-padding {
  padding: 0px !important; }

.no-margin {
  margin: 0px !important; }

.no-overflow {
  overflow: hidden !important; }

.auto-overflow {
  overflow: auto; }

.center-margin {
  margin-left: auto;
  margin-right: auto; }

.inherit-size {
  width: inherit;
  height: inherit; }

.inherit-height {
  height: inherit; }

.image-responsive-height {
  width: 100%; }

.image-responsive-width {
  height: 100%; }

.overlayer {
  position: absolute;
  display: block;
  z-index: 21; }
  .overlayer.fullwidth {
    width: 100%; }

.overlayer-wrapper {
  position: relative;
  display: block;
  z-index: 10; }

.overlay-fixed {
  position: fixed !important;
  top: auto !important; }

.top-left {
  position: absolute !important;
  top: 0;
  left: 0; }

.top-right {
  position: absolute !important;
  top: 1px;
  right: 0; }

.bottom-left {
  position: absolute !important;
  bottom: 1px;
  left: 0; }

.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0; }

.pull-bottom {
  position: absolute !important;
  bottom: 0; }

.pull-up {
  position: absolute !important;
  top: 0; }

.pull-center {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: table;
  z-index: 1; }

.cursor {
  cursor: pointer; }

.scroll-x-hidden {
  overflow-x: hidden !important; }

/* Generic Padding Helpers 
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.padding-0 {
  padding: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.padding-5 {
  padding: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.padding-10 {
  padding: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.padding-15 {
  padding: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.padding-20 {
  padding: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.padding-25 {
  padding: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.padding-30 {
  padding: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.padding-35 {
  padding: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.padding-40 {
  padding: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.padding-45 {
  padding: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.padding-50 {
  padding: 50px !important; }

.p-t-55 {
  padding-top: 55px !important; }

.p-r-55 {
  padding-right: 55px !important; }

.p-l-55 {
  padding-left: 55px !important; }

.p-b-55 {
  padding-bottom: 55px !important; }

.padding-55 {
  padding: 55px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.padding-60 {
  padding: 60px !important; }

.p-t-65 {
  padding-top: 65px !important; }

.p-r-65 {
  padding-right: 65px !important; }

.p-l-65 {
  padding-left: 65px !important; }

.p-b-65 {
  padding-bottom: 65px !important; }

.padding-65 {
  padding: 65px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.padding-70 {
  padding: 70px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.padding-75 {
  padding: 75px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.padding-80 {
  padding: 80px !important; }

.p-t-85 {
  padding-top: 85px !important; }

.p-r-85 {
  padding-right: 85px !important; }

.p-l-85 {
  padding-left: 85px !important; }

.p-b-85 {
  padding-bottom: 85px !important; }

.padding-85 {
  padding: 85px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.padding-90 {
  padding: 90px !important; }

.p-t-95 {
  padding-top: 95px !important; }

.p-r-95 {
  padding-right: 95px !important; }

.p-l-95 {
  padding-left: 95px !important; }

.p-b-95 {
  padding-bottom: 95px !important; }

.padding-95 {
  padding: 95px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.padding-100 {
  padding: 100px !important; }

/* Generic Margin Helpers
------------------------------------
 */
.m-t-0 {
  margin-top: 0px; }

.m-r-0 {
  margin-right: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-t-5 {
  margin-top: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-r-20 {
  margin-right: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-t-25 {
  margin-top: 25px; }

.m-r-25 {
  margin-right: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-t-30 {
  margin-top: 30px; }

.m-r-30 {
  margin-right: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-t-35 {
  margin-top: 35px; }

.m-r-35 {
  margin-right: 35px; }

.m-l-35 {
  margin-left: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-t-40 {
  margin-top: 40px; }

.m-r-40 {
  margin-right: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-t-45 {
  margin-top: 45px; }

.m-r-45 {
  margin-right: 45px; }

.m-l-45 {
  margin-left: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-t-50 {
  margin-top: 50px; }

.m-r-50 {
  margin-right: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-t-55 {
  margin-top: 55px; }

.m-r-55 {
  margin-right: 55px; }

.m-l-55 {
  margin-left: 55px; }

.m-b-55 {
  margin-bottom: 55px; }

.m-t-60 {
  margin-top: 60px; }

.m-r-60 {
  margin-right: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-t-65 {
  margin-top: 65px; }

.m-r-65 {
  margin-right: 65px; }

.m-l-65 {
  margin-left: 65px; }

.m-b-65 {
  margin-bottom: 65px; }

.m-t-70 {
  margin-top: 70px; }

.m-r-70 {
  margin-right: 70px; }

.m-l-70 {
  margin-left: 70px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-t-75 {
  margin-top: 75px; }

.m-r-75 {
  margin-right: 75px; }

.m-l-75 {
  margin-left: 75px; }

.m-b-75 {
  margin-bottom: 75px; }

.m-t-80 {
  margin-top: 80px; }

.m-r-80 {
  margin-right: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-t-85 {
  margin-top: 85px; }

.m-r-85 {
  margin-right: 85px; }

.m-l-85 {
  margin-left: 85px; }

.m-b-85 {
  margin-bottom: 85px; }

.m-t-90 {
  margin-top: 90px; }

.m-r-90 {
  margin-right: 90px; }

.m-l-90 {
  margin-left: 90px; }

.m-b-90 {
  margin-bottom: 90px; }

.m-t-95 {
  margin-top: 95px; }

.m-r-95 {
  margin-right: 95px; }

.m-l-95 {
  margin-left: 95px; }

.m-b-95 {
  margin-bottom: 95px; }

.m-t-100 {
  margin-top: 100px; }

.m-r-100 {
  margin-right: 100px; }

.m-l-100 {
  margin-left: 100px; }

.m-b-100 {
  margin-bottom: 100px; }

.full-height {
  height: 100% !important; }

.full-width {
  width: 100%; }

.hide {
  display: none; }

.inline {
  display: inline-block !important; }

.block {
  display: block; }

.b-blank {
  border-color: #000; }

/* Border Helpers 
------------------------------------
*/
.b-a,
.b-r,
.b-l,
.b-t,
.b-b {
  border-style: solid;
  border-width: 0; }

.b-r {
  border-right-width: 1px; }

.b-l {
  border-left-width: 1px; }

.b-t {
  border-top-width: 1px; }

.b-b {
  border-bottom-width: 1px; }

.b-a {
  border-width: 1px; }

.b-dashed {
  border-style: dashed; }

.b-thick {
  border-width: 2px; }

.b-transparent {
  border-color: rgba(0, 0, 0, 0.4); }

.b-transparent-white {
  border-color: rgba(255, 255, 255, 0.3); }

.b-grey {
  border-color: #dee1e3; }

.b-white {
  border-color: #fff; }

.b-primary {
  border-color: #2464b4; }

.b-complete {
  border-color: #2464b4; }

.b-success {
  border-color: #88c843; }

.b-info {
  border-color: #616161; }

.b-danger {
  border-color: #f44336; }

.b-warning {
  border-color: #FDD835; }

/* Border Radius
------------------------------------
*/
.b-rad-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }

.b-rad-md {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.b-rad-lg {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px; }

.no-border {
  border: none !important; }

/** Profile Wrappers **/
.profile-img-wrapper {
  display: inline-block;
  float: left;
  border-radius: 100px 100px 100px 100px;
  display: inline-block;
  height: 35px;
  overflow: hidden;
  width: 35px;
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1; }

.profile-img-wrapper.big {
  height: 68px;
  width: 68px; }

.profile-img-wrapper.with-left-space-custom {
  margin-left: 7px; }

.relative {
  position: relative; }

/* Demo Purposes */
.icon-set-preview {
  transition: opacity 0.1s linear; }

#icon-list {
  transition: all 0.1s ease-in-out; }

.error-number {
  font-family: 'Montserrat';
  font-size: 90px;
  line-height: 90px; }

.error-container-innner {
  margin-left: auto;
  margin-right: auto;
  width: 360px; }

.error-container {
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  width: 38%; }

.visible-xlg {
  display: none; }

.hidden-xlg {
  display: block; }

.sm-gutter .row > [class^="col-"], .sm-gutter .row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px; }

.sm-gutter .row {
  margin-left: -5px;
  margin-right: -5px; }

/* Aspect ratio */
.ar-1-1 .panel,
.ar-2-1 .panel,
.ar-1-2 .panel,
.ar-3-2 .panel,
.ar-2-3 .panel {
  margin: 0; }

.ar-1-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-1-1:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/ }
  .ar-1-1 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-2-1 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-2-1:before {
    content: "";
    display: block;
    padding-top: calc(50% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-2-1 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-1-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-1-2:before {
    content: "";
    display: block;
    padding-top: calc(150% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-1-2 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-3-2 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-3-2:before {
    content: "";
    display: block;
    padding-top: calc(75% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-3-2 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.ar-2-3 {
  position: relative;
  width: 100%;
  /* desired width */
  overflow: hidden; }
  .ar-2-3:before {
    content: "";
    display: block;
    padding-top: calc(125% - 5px);
    /* initial ratio of 1:1*/ }
  .ar-2-3 > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.v-align-bottom {
  vertical-align: bottom !important; }

.v-align-top {
  vertical-align: top !important; }

.v-align-middle {
  vertical-align: middle !important; }

/* vertical alignment styles */
.col-top {
  vertical-align: top !important; }

.col-middle {
  vertical-align: middle !important; }

.col-bottom {
  vertical-align: bottom !important; }

/* columns of same height styles 
------------------------------------
*/
.container-xs-height {
  display: table;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; }

.row-xs-height {
  display: table-row; }

.col-xs-height {
  display: table-cell;
  float: none; }

@media (min-width: 768px) {
  .container-sm-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; }
  .row-sm-height {
    display: table-row; }
  .col-sm-height {
    display: table-cell !important;
    float: none !important; } }

@media (min-width: 992px) {
  .container-md-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; }
  .row-md-height {
    display: table-row; }
  .col-md-height {
    display: table-cell !important;
    float: none !important; } }

@media (min-width: 1200px) {
  .container-lg-height {
    display: table;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; }
  .row-lg-height {
    display: table-row; }
  .col-lg-height {
    display: table-cell !important;
    float: none !important; } }

/*------------------------------------------------------------------
[29. Print]
*/
@media print {
  .header,
  .page-sidebar,
  .quickview-wrapper,
  .overlay {
    display: none; }
  .page-container {
    padding-left: 0; }
  .page-content-wrapper .content {
    border-top: 0; }
    .page-content-wrapper .content .panel {
      border: none; }
      .page-content-wrapper .content .panel .panel-body {
        padding: 0; }
  [class^='padding-'],
  [class*='padding-'],
  .table tbody tr td {
    padding: 10px; } }

/*------------------------------------------------------------------
[30. Demo : Only for demo pursposes]
*/
.builder {
  width: 423px;
  right: -423px; }
  .builder > div {
    height: calc(100% - 12px); }
  .builder a {
    position: relative;
    display: block;
    width: 100%;
    color: #8f9ca2; }
    .builder a > img {
      opacity: 0.33;
      -webkit-transition: opacity 0.1s linear;
      transition: opacity 0.1s linear; }
    .builder a.active {
      color: #455A64; }
      .builder a.active > img {
        opacity: 0.87; }
      .builder a.active:before {
        background-image: url("../../assets/img//demo/tick.svg");
        width: 23px;
        height: 23px;
        content: '';
        position: absolute;
        top: 84px;
        right: -10px;
        z-index: 10; }
    .builder a:hover {
      color: #6a7b83; }
      .builder a:hover > img {
        opacity: 0.7; }
  .builder .builder-footer {
    right: 0;
    left: 0;
    z-index: 10; }
  .builder .theme-selector.active:before {
    top: 54px;
    right: 10px; }
  .builder .nav-tabs {
    background-color: transparent;
    padding: 0; }
    .builder .nav-tabs > li > a {
      min-width: 99px;
      padding: 16px 0; }
  .builder .nav-tabs ~ .tab-content {
    position: relative;
    padding-right: 0px;
    height: 100%;
    top: 0; }
    .builder .nav-tabs ~ .tab-content .tab-pane {
      height: 100%;
      overflow-x: hidden !important; }
      .builder .nav-tabs ~ .tab-content .tab-pane .scrollable {
        padding-top: 27px;
        height: calc(100% - 47px);
        overflow-x: hidden !important; }
  .builder .builder-close {
    position: absolute;
    right: 36px;
    top: 20px;
    padding: 7px;
    width: auto;
    z-index: 10; }
  .builder .builder-toggle {
    position: absolute;
    width: 54px;
    height: 50px;
    top: 20%;
    left: -48px;
    text-align: center;
    line-height: 50px;
    cursor: pointer; }
  .builder:before {
    position: absolute;
    content: '';
    width: 48px;
    height: 50px;
    background-color: #fff;
    top: 20%;
    left: -47px;
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
    border-left: 1px solid rgba(222, 227, 231, 0.56);
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .builder:after {
    position: absolute;
    content: '';
    background-color: #fff;
    width: 5px;
    height: 50px;
    left: 0;
    top: 20%; }
  .builder .btn-toggle-theme > img {
    opacity: 1 !important; }
  .builder .btn-toggle-theme p {
    line-height: 18px; }

.content-builder {
  display: none; }
  .content-builder.active {
    display: block; }
  .content-builder .placeholder {
    position: relative; }
    .content-builder .placeholder:after {
      content: "Content goes here";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -10px;
      right: 0;
      text-align: center;
      font-size: 26px;
      opacity: .16;
      color: #455A64; }

.previewer {
  height: 35px; }

/* Demo Widths For GIF Animations 
------------------------------------
*/
.demo-bg-hinted {
  background-color: #939393; }

.demo-fs-23 {
  font-size: 23px !important; }

.demo-mw-50 {
  max-width: 50px; }

.demo-mw-300 {
  max-width: 300px; }

.demo-mw-350 {
  max-width: 350px; }

.demo-mw-400 {
  max-width: 400px; }

.demo-mw-500 {
  max-width: 500px; }

.demo-mw-600 {
  max-width: 600px; }

.demo-container {
  height: 2000px; }

/* Views page */
.view-iframe-wrapper {
  width: 100%;
  height: 255px;
  background: #fff;
  position: relative; }
  .view-iframe-wrapper iframe {
    width: 100%;
    height: 100%;
    padding: 45px 45px 0 45px; }
    @media (max-width: 1200px) {
      .view-iframe-wrapper iframe {
        padding: 0; } }

.visible-xlg {
  display: none; }

.hidden-xlg {
  display: block; }

/*** Large screens ***/
@media (min-width: 1824px) {
  .visible-xlg {
    display: block !important; }
  .hidden-xlg {
    display: none !important; }
  .quickview-wrapper .tab-content #quickview-notes > .inner {
    width: 570px; }
  .container-fluid.container-fixed-lg {
    width: 1700px;
    margin-right: auto;
    margin-left: auto; }
  .menu-pin .container-fluid.container-fixed-lg {
    width: 1450px; }
  .visible-xlg {
    display: block !important; }
  .hidden-xlg {
    display: none !important; }
  .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11 {
    float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
  .col-xlg-12 {
    float: left;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%; }
  .col-xlg-11 {
    width: 91.6667%; }
  .col-xlg-10 {
    width: 83.3333%; }
  .col-xlg-9 {
    width: 75%; }
  .col-xlg-8 {
    width: 66.6667%; }
  .col-xlg-7 {
    width: 58.3333%; }
  .col-xlg-6 {
    width: 50%; }
  .col-xlg-5 {
    width: 41.6667%; }
  .col-xlg-4 {
    width: 33.3333%; }
  .col-xlg-3 {
    width: 25%; }
  .col-xlg-2 {
    width: 16.6667%; }
  .col-xlg-1 {
    width: 8.33333%; }
  .col-xlg-pull-12 {
    right: 100%; }
  .col-xlg-pull-11 {
    right: 91.6667%; }
  .col-xlg-pull-10 {
    right: 83.3333%; }
  .col-xlg-pull-9 {
    right: 75%; }
  .col-xlg-pull-8 {
    right: 66.6667%; }
  .col-xlg-pull-7 {
    right: 58.3333%; }
  .col-xlg-pull-6 {
    right: 50%; }
  .col-xlg-pull-5 {
    right: 41.6667%; }
  .col-xlg-pull-4 {
    right: 33.3333%; }
  .col-xlg-pull-3 {
    right: 25%; }
  .col-xlg-pull-2 {
    right: 16.6667%; }
  .col-xlg-pull-1 {
    right: 8.33333%; }
  .col-xlg-pull-0 {
    right: 0; }
  .col-xlg-push-12 {
    left: 100%; }
  .col-xlg-push-11 {
    left: 91.6667%; }
  .col-xlg-push-10 {
    left: 83.3333%; }
  .col-xlg-push-9 {
    left: 75%; }
  .col-xlg-push-8 {
    left: 66.6667%; }
  .col-xlg-push-7 {
    left: 58.3333%; }
  .col-xlg-push-6 {
    left: 50%; }
  .col-xlg-push-5 {
    left: 41.6667%; }
  .col-xlg-push-4 {
    left: 33.3333%; }
  .col-xlg-push-3 {
    left: 25%; }
  .col-xlg-push-2 {
    left: 16.6667%; }
  .col-xlg-push-1 {
    left: 8.33333%; }
  .col-xlg-push-0 {
    left: 0; }
  .col-xlg-offset-12 {
    margin-left: 100%; }
  .col-xlg-offset-11 {
    margin-left: 91.6667%; }
  .col-xlg-offset-10 {
    margin-left: 83.3333%; }
  .col-xlg-offset-9 {
    margin-left: 75%; }
  .col-xlg-offset-8 {
    margin-left: 66.6667%; }
  .col-xlg-offset-7 {
    margin-left: 58.3333%; }
  .col-xlg-offset-6 {
    margin-left: 50%; }
  .col-xlg-offset-5 {
    margin-left: 41.6667%; }
  .col-xlg-offset-4 {
    margin-left: 33.3333%; }
  .col-xlg-offset-3 {
    margin-left: 25%; }
  .col-xlg-offset-2 {
    margin-left: 16.6667%; }
  .col-xlg-offset-1 {
    margin-left: 8.33333%; }
  .col-xlg-offset-0 {
    margin-left: 0; } }

/*** Desktops ***/
/*** Medium Size Screen ***/
@media only screen and (max-width: 1400px) {
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
    bottom: 50px; }
  .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-widgets {
    display: none; }
  .footer-widget {
    padding: 11px 21px !important; } }

/*** Desktops & Laptops ***/
@media only screen and (min-width: 980px) {
  body.ie9.menu-pin .page-sidebar {
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important; }
  body.menu-pin {
    overflow-x: hidden; }
    body.menu-pin .header .brand {
      width: 245px; }
    body.menu-pin .page-container {
      padding-left: 0; }
      body.menu-pin .page-container .page-content-wrapper .content {
        padding-left: 250px; }
      body.menu-pin .page-container .page-content-wrapper .footer {
        left: 250px; }
    body.menu-pin [data-toggle-pin="sidebar"] > i:before {
      content: "\f192"; }
    body.menu-pin .page-sidebar {
      transform: translate(210px, 0) !important;
      -webkit-transform: translate(210px, 0) !important;
      -ms-transform: translate(210px, 0) !important;
      width: 250px; }
      body.menu-pin .page-sidebar .sidebar-header .sidebar-header-controls {
        transform: translateX(18px);
        -webkit-transform: translateX(18px); }
      body.menu-pin .page-sidebar .menu-items .icon-thumbnail {
        transform: translate3d(-14px, 0, 0);
        -webkit-transform: -webkit-translate3d(-14px, 0, 0); }
  body.menu-behind .page-sidebar {
    z-index: 799; }
  body.menu-behind .header .brand {
    width: 200px;
    text-align: left;
    padding-left: 20px; }
  body.box-layout {
    background-color: #fff; }
    body.box-layout > .container {
      height: 100%;
      padding: 0;
      background-color: #eef0f1; }
    body.box-layout .header {
      background-color: transparent;
      border: 0;
      padding: 0; }
      body.box-layout .header > .container {
        background-color: #fff;
        border-bottom: 1px solid rgba(230, 230, 230, 0.7);
        padding: 0 20px 0 0; }
    body.box-layout .page-sidebar {
      left: auto;
      transform: none !important;
      -webkit-transform: none !important; }
    body.box-layout .page-container .page-content-wrapper .footer {
      width: auto; }
  .header .brand {
    position: relative; }
  .header .user-info-wrapper .user-details .user-name {
    font-size: 16px; }
  .header .user-info-wrapper .user-details .user-other {
    font-size: 10px; }
  .header .user-info-wrapper .user-pic {
    position: relative;
    top: -6px; }
  .notification-panel {
    width: 400px; } }

/*** General Small Screen Desktops ***/
/*** General tablets and phones ***/
@media (max-width: 991px) {
  .page-container {
    padding-left: 0; }
  body.sidebar-open .page-container {
    -webkit-transform: translate3d(250px, 0, 0);
    transform: translate3d(250px, 0, 0);
    -ms-transform: translate(250px, 0);
    overflow: hidden;
    position: fixed; }
  body.box-layout > .container {
    padding: 0;
    height: 100%; }
  body.box-layout .header > .container {
    padding: 0; }
    body.box-layout .header > .container .pull-right .sm-action-bar {
      right: 0; }
  .header {
    padding: 0 10px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    background: #fff !important; }
    .header .header-inner {
      text-align: center; }
      .header .header-inner .toggle-sidebar {
        display: block;
        position: absolute;
        left: 23px; }
      .header .header-inner .mark-email {
        left: 45px;
        position: absolute;
        top: 23px; }
      .header .header-inner .quickview-link {
        position: absolute;
        right: 0;
        top: 12px; }
    .header .brand {
      width: auto; }
    .header .notification-list, .header .search-link {
      display: none; }
    .header > .pull-left, .header > .pull-right {
      position: relative; }
    .header > .pull-right .sm-action-bar {
      right: 0; }
  .sm-action-bar {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10; }
    .sm-action-bar > a {
      padding: 10px;
      display: inline-block; }
  .pace .pace-activity {
    top: 60px; }
  .page-sidebar {
    width: 250px;
    z-index: auto;
    left: 0 !important;
    -webkit-transform: translate3d(0, 0px, 0px) !important;
    transform: translate3d(0, 0px, 0px) !important; }
    .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
      padding: 0px 30px 0 36px; }
  .page-container {
    padding-left: 0;
    position: relative;
    transition: transform .25s ease;
    -webkit-transition: -webkit-transform .25s ease; }
    .page-container .page-content-wrapper .content {
      overflow-x: hidden;
      height: auto; }
  .icon-thumbnail {
    margin-right: 24px; }
  .page-sidebar {
    display: none;
    z-index: auto; }
    .page-sidebar.visible {
      display: block; }
    .page-sidebar .page-sidebar-inner {
      z-index: 1;
      left: 0 !important;
      width: 260px; }
      .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        top: 40px;
        bottom: 0; }
        .page-sidebar .page-sidebar-inner .sidebar-slide .sidebar-menu ul > li > a > .badge {
          display: inline-block; }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px; }
    .secondary-sidebar .btn-compose {
      display: none; }
  .inner-content {
    margin: 0; }
  .breadcrumb {
    padding-left: 15px;
    padding-right: 15px; }
  .copyright {
    padding-left: 15px;
    padding-right: 15px; }
  body > .pgn-wrapper[data-position="top"] {
    top: 48px;
    left: 0; }
  body > .pgn-wrapper[data-position="bottom"] {
    left: 0; }
  body > .pgn-wrapper[data-position$='-left'] {
    left: 20px;
    right: auto; }
  .sm-table {
    display: table;
    width: 100%; }
  .user-profile-wrapper {
    position: absolute;
    right: 50px;
    top: -9px; }
  /*** Horizontal Menu **/
  .horizontal-menu .bar {
    background-color: #fff;
    display: none; }
    .horizontal-menu .bar .bar-inner {
      display: block;
      max-height: 400px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch; }
      .horizontal-menu .bar .bar-inner > ul > li {
        display: block; }
        .horizontal-menu .bar .bar-inner > ul > li .classic {
          position: relative;
          background-color: transparent;
          top: 0; }
        .horizontal-menu .bar .bar-inner > ul > li .mega, .horizontal-menu .bar .bar-inner > ul > li.horizontal {
          position: relative; }
        .horizontal-menu .bar .bar-inner > ul > li > .horizontal {
          position: relative;
          top: 0;
          border-bottom: 0; }
          .horizontal-menu .bar .bar-inner > ul > li > .horizontal li {
            display: block; } }

/* Landscape view of all tablet devices */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-sidebar .sidebar-menu .menu-items ul {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000; }
  .page-container .page-content-wrapper .content {
    padding-top: 60px; }
  body.box-layout > .container {
    width: 100%; }
  .error-page .error-container {
    width: auto; } }

@media (max-width: 991px) {
  .sm-b-r,
  .sm-b-l,
  .sm-b-t,
  .sm-b-b {
    border-width: 0; }
  .sm-b-r {
    border-right-width: 1px; }
  .sm-b-l {
    border-left-width: 1px; }
  .sm-b-t {
    border-top-width: 1px; }
  .sm-b-b {
    border-bottom-width: 1px; } }

@media (min-width: 1200px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-lg"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; } }

@media (min-width: 992px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-md"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; }
  .horizontal-menu .bar {
    display: table !important; }
    .horizontal-menu .bar + div {
      padding-top: 50px; } }

@media (min-width: 768px) {
  .row-same-height {
    overflow: hidden; }
    .row-same-height > [class*="col-sm"] {
      margin-bottom: -99999px;
      padding-bottom: 99999px; }
  .box-layout .container .jumbotron, .container-fluid .jumbotron {
    padding: 0; } }

/* Portrait view of all tablet devices */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 27px; }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 27px 0 31px; }
  .icon-thumbnail {
    margin-right: 21px; }
  .error-page .error-container {
    width: auto; } }

@media (max-width: 979px) {
  .toggle-secondary-sidebar {
    font-size: 18px;
    position: static;
    display: block; }
    .toggle-secondary-sidebar ~ .brand {
      display: none !important; }
  .secondary-sidebar {
    float: none;
    height: auto;
    left: 50%;
    margin-left: -125px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 9999;
    display: none;
    border-radius: 10px; }
    .secondary-sidebar .btn-compose {
      display: none; }
  .split-view .split-list {
    width: 100%;
    height: auto;
    bottom: 50px;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 1000;
    float: none; }
    .split-view .split-list .item {
      padding: 8px; }
      .split-view .split-list .item .inline {
        margin-left: 5px;
        width: 188px; }
  .split-view .split-details {
    margin-left: 0;
    width: 100%; }
    .split-view .split-details .email-content-wrapper {
      overflow: auto;
      padding: 0;
      height: calc(100% - 50px); }
      .split-view .split-details .email-content-wrapper .email-content {
        width: 90%; }
  .compose-wrapper {
    display: block !important; }
    .compose-wrapper .btn-compose {
      display: block !important; } }

@media (max-width: 767px) {
  .header {
    height: 48px; }
    .header .notification-list, .header .search-link {
      display: none; }
    .header .header-inner {
      height: 48px; }
  .header .user-info-wrapper {
    display: none; }
  .header .search-link {
    height: 19px;
    width: 16px;
    overflow: hidden; }
  .jumbotron, .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  .page-container .page-content-wrapper .content {
    padding-top: 48px;
    padding-bottom: 100px; }
  .page-sidebar .sidebar-header {
    padding: 0 12px; }
  .page-sidebar .sidebar-menu .menu-items > li > a {
    padding-left: 20px; }
  .page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {
    padding: 0px 25px 0 28px; }
  .icon-thumbnail {
    margin-right: 20px; }
  .sm-pull-bottom, .sm-pull-up {
    position: relative !important; }
  .sm-p-t-0 {
    margin-top: 0px !important; }
  .sm-p-r-0 {
    margin-right: 0px !important; }
  .sm-p-l-0 {
    margin-left: 0px !important; }
  .sm-p-b-0 {
    margin-bottom: 0px !important; }
  .sm-p-t-5 {
    margin-top: 5px !important; }
  .sm-p-r-5 {
    margin-right: 5px !important; }
  .sm-p-l-5 {
    margin-left: 5px !important; }
  .sm-p-b-5 {
    margin-bottom: 5px !important; }
  .sm-p-t-10 {
    margin-top: 10px !important; }
  .sm-p-r-10 {
    margin-right: 10px !important; }
  .sm-p-l-10 {
    margin-left: 10px !important; }
  .sm-p-b-10 {
    margin-bottom: 10px !important; }
  .sm-p-t-15 {
    margin-top: 15px !important; }
  .sm-p-r-15 {
    margin-right: 15px !important; }
  .sm-p-l-15 {
    margin-left: 15px !important; }
  .sm-p-b-15 {
    margin-bottom: 15px !important; }
  .sm-p-t-20 {
    margin-top: 20px !important; }
  .sm-p-r-20 {
    margin-right: 20px !important; }
  .sm-p-l-20 {
    margin-left: 20px !important; }
  .sm-p-b-20 {
    margin-bottom: 20px !important; }
  .sm-p-t-25 {
    margin-top: 25px !important; }
  .sm-p-r-25 {
    margin-right: 25px !important; }
  .sm-p-l-25 {
    margin-left: 25px !important; }
  .sm-p-b-25 {
    margin-bottom: 25px !important; }
  .sm-p-t-30 {
    margin-top: 30px !important; }
  .sm-p-r-30 {
    margin-right: 30px !important; }
  .sm-p-l-30 {
    margin-left: 30px !important; }
  .sm-p-b-30 {
    margin-bottom: 30px !important; }
  .sm-p-t-35 {
    margin-top: 35px !important; }
  .sm-p-r-35 {
    margin-right: 35px !important; }
  .sm-p-l-35 {
    margin-left: 35px !important; }
  .sm-p-b-35 {
    margin-bottom: 35px !important; }
  .sm-p-t-40 {
    margin-top: 40px !important; }
  .sm-p-r-40 {
    margin-right: 40px !important; }
  .sm-p-l-40 {
    margin-left: 40px !important; }
  .sm-p-b-40 {
    margin-bottom: 40px !important; }
  .sm-p-t-45 {
    margin-top: 45px !important; }
  .sm-p-r-45 {
    margin-right: 45px !important; }
  .sm-p-l-45 {
    margin-left: 45px !important; }
  .sm-p-b-45 {
    margin-bottom: 45px !important; }
  .sm-p-t-50 {
    margin-top: 50px !important; }
  .sm-p-r-50 {
    margin-right: 50px !important; }
  .sm-p-l-50 {
    margin-left: 50px !important; }
  .sm-p-b-50 {
    margin-bottom: 50px !important; }
  .sm-m-t-0 {
    margin-top: 0px !important; }
  .sm-m-r-0 {
    margin-right: 0px !important; }
  .sm-m-l-0 {
    margin-left: 0px !important; }
  .sm-m-b-0 {
    margin-bottom: 0px !important; }
  .sm-m-t-5 {
    margin-top: 5px !important; }
  .sm-m-r-5 {
    margin-right: 5px !important; }
  .sm-m-l-5 {
    margin-left: 5px !important; }
  .sm-m-b-5 {
    margin-bottom: 5px !important; }
  .sm-m-t-10 {
    margin-top: 10px !important; }
  .sm-m-r-10 {
    margin-right: 10px !important; }
  .sm-m-l-10 {
    margin-left: 10px !important; }
  .sm-m-b-10 {
    margin-bottom: 10px !important; }
  .sm-m-t-15 {
    margin-top: 15px !important; }
  .sm-m-r-15 {
    margin-right: 15px !important; }
  .sm-m-l-15 {
    margin-left: 15px !important; }
  .sm-m-b-15 {
    margin-bottom: 15px !important; }
  .sm-m-t-20 {
    margin-top: 20px !important; }
  .sm-m-r-20 {
    margin-right: 20px !important; }
  .sm-m-l-20 {
    margin-left: 20px !important; }
  .sm-m-b-20 {
    margin-bottom: 20px !important; }
  .sm-m-t-25 {
    margin-top: 25px !important; }
  .sm-m-r-25 {
    margin-right: 25px !important; }
  .sm-m-l-25 {
    margin-left: 25px !important; }
  .sm-m-b-25 {
    margin-bottom: 25px !important; }
  .sm-m-t-30 {
    margin-top: 30px !important; }
  .sm-m-r-30 {
    margin-right: 30px !important; }
  .sm-m-l-30 {
    margin-left: 30px !important; }
  .sm-m-b-30 {
    margin-bottom: 30px !important; }
  .sm-m-t-35 {
    margin-top: 35px !important; }
  .sm-m-r-35 {
    margin-right: 35px !important; }
  .sm-m-l-35 {
    margin-left: 35px !important; }
  .sm-m-b-35 {
    margin-bottom: 35px !important; }
  .sm-m-t-40 {
    margin-top: 40px !important; }
  .sm-m-r-40 {
    margin-right: 40px !important; }
  .sm-m-l-40 {
    margin-left: 40px !important; }
  .sm-m-b-40 {
    margin-bottom: 40px !important; }
  .sm-m-t-45 {
    margin-top: 45px !important; }
  .sm-m-r-45 {
    margin-right: 45px !important; }
  .sm-m-l-45 {
    margin-left: 45px !important; }
  .sm-m-b-45 {
    margin-bottom: 45px !important; }
  .sm-m-t-50 {
    margin-top: 50px !important; }
  .sm-m-r-50 {
    margin-right: 50px !important; }
  .sm-m-l-50 {
    margin-left: 50px !important; }
  .sm-m-b-50 {
    margin-bottom: 50px !important; }
  .sm-no-margin {
    margin: 0px; }
  .sm-no-padding {
    padding: 0px; }
  .sm-text-right {
    text-align: right !important; }
  .sm-text-left {
    text-align: left !important; }
  .sm-text-center {
    text-align: center !important; }
  .sm-pull-right {
    float: right !important; }
  .sm-pull-left {
    float: left !important; }
  .sm-pull-reset {
    float: none !important; }
  .sm-block {
    display: block; }
  .error-container {
    width: auto; }
  .sm-image-responsive-height {
    width: 100%;
    height: auto; } }

  .custom-btn-reports {
    padding-right: 0em !important;
  }

/*** Phones ***/
@media (max-width: 480px) {
  body {
    width: 100%; }
    body .header {
      width: 100%;
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07); }
      body .header .header-inner {
        height: 48px;
        text-align: center; }
        body .header .header-inner .toggle-email-sidebar {
          font-size: 16px;
          top: 12px; }
        body .header .header-inner .toggle-sidebar {
          left: 14px; }
        body .header .header-inner .mark-email {
          left: 35px;
          top: 14px; }
        body .header .header-inner .quickview-link {
          top: 14px; }
      body .header .notification-list, body .header .search-link {
        display: none; }
      body .header .dropdown-submenu {
        top: 12px; }
      body .header .notification-list, body .header .search-link {
        display: none; }
    body #overlay-search {
      font-size: 48px;
      height: 118px;
      line-height: 46px; }
  .page-sidebar .sidebar-header {
    height: 48px;
    line-height: 48px; }
  .panel .panel-heading {
    padding-left: 15px; }
  .panel .panel-body {
    padding: 15px;
    padding-top: 0; }
  .error-page {
    padding: 15px; }
    .error-page .error-container {
      margin-top: 30px;
      width: auto; }
    .error-page .pull-bottom {
      position: relative; }
  .map-controls {
    left: 10px; }
  .register-container {
    height: auto !important; }
  .error-container-innner {
    width: auto; } }

/*** Retina Display Images **/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 200 / 100), only screen and (min-device-pixel-ratio: 2) {
  .icon-set {
    background: url("../img/icons/top_tray_2x.png");
    position: relative;
    background-size: 95px 19px; }
  .editor-icon {
    background-image: url("../img/icons/noti-cross.png");
    background-size: 480px 40px; }
  .alert .close {
    background: url("../img/icons/noti-cross-2x.png") no-repeat scroll 0 0 transparent;
    background-position: -9px -10px;
    width: 10px;
    height: 9px;
    position: relative;
    opacity: 0.8;
    background-size: 114px 29px; } }

/*---------------------
Theme overrides
---------------------*/
@media screen and (max-width: 991px) {
  .header .sm-table {
    width: auto; } }

@media screen and (max-width: 768px) {
  .header .m-t-10 {
    margin-top: 1px; } }

.pace .pace-activity {
  /*background: url("/pages/img/progress/progress-circle-success.svg") no-repeat top left;*/ }

.header-org-select-text {
  position: relative;

  top: -3px; }

.header-org-select {
  position: relative;

  top: -5px; }

#logoutForm {
  padding: 5px 20px; }

/*---------------------
Reporting Pages
---------------------*/
.nvtooltip {
  margin: 0px;
  border-spacing: 0px;
  border-collapse: separate;
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 0px;
  border-color: #eef0f1;
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 11px;
  color: #90a7b2;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 15px; }

.nvtooltip table {
  padding: 0 !important;
  margin: 0 !important; }

.nvtooltip thead, .nvtooltip .nvtooltip_thead {
  font-size: 12px;
  color: #90a7b2 !important;
  font-weight: bold;
  text-transform: uppercase; }

.nvtooltip thead td, .nvtooltip .nvtooltip_td {
  font-size: 11px;
  color: #90a7b2 !important; }

rect.nv-bar.positive:nth-child(4n+1) {
  fill: #a0d369 !important;
  stroke: #a0d369 !important; }

rect.nv-bar.positive:nth-child(4n+2) {
  fill: #88c843 !important;
  stroke: #88c843 !important; }

rect.nv-bar.positive:nth-child(4n+3) {
  fill: #6ea236 !important;
  stroke: #6ea236 !important; }

rect.nv-bar.positive:nth-child(4n+4) {
  fill: #527828 !important;
  stroke: #527828 !important; }

.gift_business_transaction_page .form-group-default-mySelectTran, .mid_transaction_page .form-group-default-mySelectTran {
  padding-bottom: 0px;
  height: 47px !important;
  min-height: 47px !important; }

.gift_business_transaction_page .select2-container.full-width.input-sm, .mid_transaction_page .select2-container.full-width.input-sm {
  padding: 0;
  position: relative;
  top: -15px;
  padding-bottom: 0px; }
  .gift_business_transaction_page .select2-container.full-width.input-sm a, .mid_transaction_page .select2-container.full-width.input-sm a {
    background-color: transparent; }

.gift_business_transaction_page .summary_table th,
.gift_business_transaction_page .summary_table td, .mid_transaction_page .summary_table th,
.mid_transaction_page .summary_table td {
  text-align: right; }
  .gift_business_transaction_page .summary_table th .sorting_asc,
  .gift_business_transaction_page .summary_table th .sorting_1,
  .gift_business_transaction_page .summary_table td .sorting_asc,
  .gift_business_transaction_page .summary_table td .sorting_1, .mid_transaction_page .summary_table th .sorting_asc,
  .mid_transaction_page .summary_table th .sorting_1,
  .mid_transaction_page .summary_table td .sorting_asc,
  .mid_transaction_page .summary_table td .sorting_1 {
    text-align: left; }

.data_row_disabled td,
.data_row_disabled .sorting_1 {
  background-color: #fafafa !important;
  color: #b1b1b1 !important; }

.date-range-summary {
  height: 24px;
  padding-top: 2px; }

.daterangepicker .ranges li {
  color: #2464b4; }

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #2464b4;
  border: 1px solid #2464b4; }

.icon-set {
  background: url("../img/icons/top_tray.png") no-repeat; }

table.dataTable thead .sorting:after {
  background-image: url("../img/icons/sort_both.png"); }

table.dataTable thead .sorting_asc:after {
  background-image: url("../img/icons/sort_asc.png"); }

/*---------------------
[Widgets]
---------------------*/
.widget-1:after {
  background-image: none; }

.widget-1:before {
  background-image: none; }

.logo-image-resize-hud {
    width: 8vw;
    min-width: 138px;
    max-width: 150px;
}

.logo-image-resize-white-hud {
    width: 7vw;
    min-width: 138px;
    max-width: 150px;
}

.logo-image-resize-login {
    width: 10vw;
    min-width: 138px;
    max-width: 350px;
}
.custom-btn {
  background-color: #6c757d; /* gray */
  color: #000000; /* black */
}

.custom-btn:enabled {
  background-color: #000000; /* black */
  color: #ffffff; /* white */
}

.alert-mfa {
  margin-left: 15px;
  margin-right: 15px;
}
.alert-mfa .close {
  top: 4.5px !important;
}